import React from 'react';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';

interface AccountTypeProps {
  setUnconfirmedAccountType: React.Dispatch<
    React.SetStateAction<AccountTypes | undefined>
  >;
}

function AccountTypeForm({ setUnconfirmedAccountType }: AccountTypeProps) {
  return (
    <section className="items-center sm:min-w-[24rem]">
      <h3 className="text-center text-lg font-medium">
        What best describes you?
      </h3>
      <div className="my-10 flex flex-col items-center space-y-8">
        <button
          onClick={() => setUnconfirmedAccountType(AccountTypes.Startup)}
          type="button"
          className="group inline-flex items-center rounded-full bg-blue-600 px-6 py-3.5 font-semibold text-white transition hover:bg-blue-900"
        >
          I&apos;m a Founder
          <svg
            className="-mr-1 ml-2 mt-0.5 stroke-white stroke-2"
            fill="none"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            aria-hidden="true"
          >
            <path
              className="opacity-0 transition group-hover:opacity-100"
              d="M0 5h7"
            ></path>
            <path
              className="transition group-hover:translate-x-[3px]"
              d="M1 1l4 4-4 4"
            ></path>
          </svg>
        </button>
      </div>
    </section>
  );
}

export default AccountTypeForm;
