enum RecordInvestmentFieldNames {
  Amount = 'amount',
  BoardParticipation = 'boardParticipation',
  CarryCharged = 'carryCharged',
  DealRating = 'dealRating',
  HasRightsInformation = 'hasRightsInformation',
  HasRightsMfn = 'HasRightsMfn',
  HasRightsProRata = 'hasRightsProRata',
  InvestorUpdatesFrequency = 'investorUpdatesFrequency',
  IsLead = 'isLead',
  Reasons = 'reasons',
}

export default RecordInvestmentFieldNames;
