import { StageView } from '/../libs/shared-types/src/types/view/StageView';
import {
  Stage,
  StageStatus,
} from '/../libs/shared-types/src/types/model/Stage';
import { getColorByIndex } from '/src/util/colorLookup';
import { cn } from '/src/util/cn';
import React from 'react';

interface SummaryPanelProps {
  stageStatuses: StageStatus[];
  stages: StageView[];
  onSelectStage: (stage: StageView | undefined) => void;
  selectedStage: StageView | undefined;
}

export default function SummaryPanel({
  stageStatuses,
  stages,
  onSelectStage,
  selectedStage,
}: SummaryPanelProps): JSX.Element {
  return (
    <section className="hidden lg:block">
      <ol className="divide-y divide-gray-300 rounded-md bg-white shadow-sm md:flex md:flex-wrap md:divide-y-0">
        {stages.length > 1 &&
          stages.map((stage, index) => (
            <li
              key={stage._id}
              id={stage._id}
              className="relative md:flex md:flex-grow"
            >
              {selectedStage && stage._id === selectedStage?._id ? (
                <button
                  className="flex items-center p-1 text-xs"
                  aria-current="step"
                  type="button"
                  onClick={() => onSelectStage(undefined)}
                  id={stage._id}
                >
                  <div className={cn('rounded-lg bg-gray-100 py-2 pl-2 pr-4')}>
                    <span
                      className={cn(
                        stage.bgColor
                          ? stage.bgColor + ' ' + stage.textColor
                          : getColorByIndex(index, stages.length).allColors,
                        'rounded-full px-2 py-1 text-xs font-medium',
                      )}
                    >
                      {stageStatuses
                        .filter(
                          (x) =>
                            x.name.toUpperCase() === stage.name.toUpperCase(),
                        )
                        .length.toString()}
                    </span>
                    <span
                      className={cn(
                        'ml-2 truncate text-xs font-semibold underline',
                        stage.textColor
                          ? stage.textColor
                          : getColorByIndex(index, stages.length).textColor,
                      )}
                    >
                      {stage.name}
                    </span>
                  </div>
                </button>
              ) : (
                <button
                  onClick={() => onSelectStage(stage)}
                  type="button"
                  className="group flex content-center items-center p-3 text-xs"
                  id={stage._id}
                >
                  <div>
                    <span
                      className={cn(
                        stage.bgColor
                          ? stage.bgColor + ' ' + stage.textColor
                          : getColorByIndex(index, stages.length).allColors,
                        'rounded-full px-2 py-1 text-xs font-medium',
                      )}
                    >
                      {stageStatuses
                        .filter(
                          (x) =>
                            // x.currentActiveBoardStage?.name.toUpperCase() ===
                            x.name.toUpperCase() === stage.name.toUpperCase(),
                        )
                        .length.toString()}
                    </span>
                    <span className="ml-2 truncate text-xs font-semibold text-gray-500 group-hover:text-gray-700">
                      {stage.name}
                    </span>
                  </div>
                </button>
              )}

              {index !== stages.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="absolute right-0 top-0 hidden h-full w-5 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
      </ol>
    </section>
  );
}
