import React from 'react';
import * as yup from 'yup';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import FormField from '/src/interfaces/FormField';
import { Field } from 'formik';
import FormikCheckBox from '/src/components/inputs/FormikCheckBox';
import { BaseUrls } from '/../libs/shared-types/src/constants/BaseUrls';

function FieldWrapper(): JSX.Element {
  const textLabel = (
    <p className="ml-2 block text-xs">
      By signing up using this form, I confirm I have read and agree with
      the&nbsp;
      <a
        href={BaseUrls.PRPODUCTION_TOS}
        className="hyperlink"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>
      ,&nbsp;
      <a
        href={BaseUrls.PRPODUCTION_PRIVACY_POLICY}
        className="hyperlink"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      ,&nbsp;
      <a
        href={BaseUrls.PRPODUCTION_ACCEPTABLE_USE}
        className="hyperlink"
        target="_blank"
        rel="noopener noreferrer"
      >
        Acceptable Use Policy
      </a>
      <br />
      and&nbsp;
      <a
        href={BaseUrls.PRPODUCTION_COOKIES}
        className="hyperlink"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cookies Policy
      </a>
      .
    </p>
  );

  return (
    <div className="my-4 flex max-w-full text-wrap text-sm text-gray-900">
      <Field
        component={FormikCheckBox}
        label={textLabel}
        name={AuthFieldNames.AcceptLegal}
        customStyle="w-6 h-6"
      />
    </div>
  );
}

const AcceptPolicyAgreementsField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [AuthFieldNames.AcceptLegal]: yup
      .boolean()
      .isTrue(
        'Please review and accept our terms and conditions of use in order to proceed',
      )
      .required('Required'),
  }),
};

export default AcceptPolicyAgreementsField;
