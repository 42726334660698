import { KeyModifiers } from '/src/hooks/useKeyPress';
import { cn } from '/src/util/cn';
import React from 'react';

interface OsKeyModifier {
  os: string;
  modifierKey: KeyModifiers | undefined;
  modifierLabel: string;
  modifierTitle: string;
}

export function detectOs(): OsKeyModifier {
  const platform = navigator?.platform || navigator.appVersion || 'unknown';
  if (platform.indexOf('Win') !== -1)
    return {
      os: 'Windows',
      modifierKey: KeyModifiers.Ctrl,
      modifierLabel: 'Ctrl',
      modifierTitle: 'Control',
    };
  if (platform.indexOf('Mac') !== -1)
    return {
      os: 'MacOS',
      modifierKey: KeyModifiers.Meta,
      modifierLabel: '⌘',
      modifierTitle: 'Command',
    };

  if (platform.indexOf('Linux') !== -1)
    return {
      os: 'Linux',
      modifierKey: KeyModifiers.Ctrl,
      modifierLabel: 'Ctrl',
      modifierTitle: 'Control',
    };
  if (platform.indexOf('X11') !== -1)
    return {
      os: 'UNIX',
      modifierKey: KeyModifiers.Ctrl,
      modifierLabel: 'Ctrl',
      modifierTitle: 'Control',
    };
  return {
    os: 'NOT FOUND',
    modifierKey: undefined,
    modifierLabel: '',
    modifierTitle: '',
  };
}

interface KeyShortcutLabelProps {
  shortcutKey: string;
  keyModifier?: OsKeyModifier;
  srLabel: string;
}

function KeyShortcutLabel({
  shortcutKey,
  keyModifier,
  srLabel,
}: KeyShortcutLabelProps): JSX.Element {
  return (
    <span className="hidden rounded-md border border-gray-300 px-1.5 text-xs leading-5 text-gray-400 sm:block">
      <span className="sr-only">Press </span>
      {keyModifier && (
        <>
          <kbd className="mr-1 font-sans">
            <abbr
              title={keyModifier.modifierTitle}
              className="border-none no-underline"
            >
              {keyModifier.modifierLabel}
            </abbr>
          </kbd>
          <span className="sr-only"> and </span>
        </>
      )}
      <kbd className={cn(keyModifier ? '' : 'px-0.5', 'font-sans')}>
        {shortcutKey}
      </kbd>
      <span className="sr-only"> to {srLabel}</span>
    </span>
  );
}

export default KeyShortcutLabel;
