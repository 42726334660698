import React, { useEffect, useState } from 'react';
import { URL_REGEX } from '/src/constants/validation/websiteSchema';
import { cn } from '/src/util/cn';

interface IframeWrapperProps {
  className: string;
  frameHeight: string;
  frameSrc: string;
  frameTitle: string;
}

function IframeWrapper({
  className,
  frameHeight,
  frameSrc,
  frameTitle,
}: IframeWrapperProps): JSX.Element {
  const [isUrlValid, setIsUrlValid] = useState(false);

  useEffect(() => {
    setIsUrlValid(URL_REGEX.test(frameSrc));
  }, [frameSrc]);

  // Check if it is a valid url
  if (!isUrlValid) {
    return <></>;
  }

  return (
    <div className={cn('overflow-hidden', className)}>
      <iframe
        title={frameTitle}
        src={frameSrc}
        className={cn('w-full', frameHeight)}
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default IframeWrapper;
