import React, { useContext, useEffect, useRef, useState } from 'react';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import FormCard from '/src/components/FormCard';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import * as yup from 'yup';
import DebugRender from '/src/components/utility/DebugRender';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { cn } from '/src/util/cn';
import { InvestorMeetingView } from '/../libs/shared-types/src/types/view/InvestorMeetingView';
import { DetailedTask } from '/../libs/shared-types/src/types/model/Task';
import {
  TrashIcon,
  PlusIcon,
  PencilIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { TASKS_PER_MEETING_MAX } from '/../libs/shared-types/src/constants/ObjectArrayLengthRanges';
import { StartupUpdateInvestorMeetingTasks } from '/../libs/shared-types/src/constants/ApiRoutes';
import { TASK_NAME_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import { debounce } from '/src/util/debounce';

const NEW_TASK_ID_PREFIX = 'temp-';

function TaskItem({
  task,
  disabled,
  onDelete,
  onUpdateName,
  setFieldValue,
  submitForm,
}: {
  task: DetailedTask;
  disabled: boolean;
  onDelete: (taskId: string) => void;
  onUpdateName: (taskId: string, newName: string) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<Record<string, boolean>>>;
  submitForm: () => Promise<any>;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(task.name);
  const [isHovered, setIsHovered] = useState(false);

  const handleSave = () => {
    if (editedName.trim()) {
      onUpdateName(task._id, editedName);
      setIsEditing(false);
      submitForm();
    }
  };

  const handleCancel = () => {
    setEditedName(task.name);
    setIsEditing(false);
  };

  return (
    <div
      className="group relative flex items-start"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex h-6 items-center">
        <Field
          id={task._id}
          name={task._id}
          type="checkbox"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.target;
            setFieldValue(task._id, checked, false);
            submitForm();
          }}
          disabled={disabled}
          className="size-5 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
        />
      </div>
      <div className="ml-3 flex-grow text-sm leading-6">
        {isEditing ? (
          <div className="-mr-6 flex items-center space-x-2">
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              className="input"
              maxLength={TASK_NAME_LENGTH_MAX}
            />
            <button
              onClick={handleSave}
              className="p-1 text-green-600 hover:bg-gray-100 hover:text-green-800"
            >
              <CheckIcon className="size-5" />
            </button>
            <button
              onClick={handleCancel}
              className="p-1 text-red-600 hover:bg-gray-100 hover:text-red-800"
            >
              <XMarkIcon className="size-5" />
            </button>
          </div>
        ) : (
          <label
            htmlFor={task._id}
            className="flex items-center text-base font-medium text-gray-900"
          >
            <span className="flex-grow">{task.name}</span>
          </label>
        )}
      </div>
      {!disabled && !isEditing && isHovered && (
        <div className="-mr-6 flex items-center space-x-2">
          <button
            onClick={() => setIsEditing(true)}
            className="p-1 text-gray-600 hover:bg-gray-100 hover:text-gray-800"
          >
            <PencilIcon className="size-[1rem]" />
          </button>
          <button
            onClick={() => {
              onDelete(task._id);
              submitForm();
            }}
            className="p-1 text-red-600 hover:bg-gray-100 hover:text-red-800"
          >
            <TrashIcon className="size-[1rem]" />
          </button>
        </div>
      )}
    </div>
  );
}

interface MeetingDetailTasksProps {
  meeting: InvestorMeetingView;
  disabled: boolean;
}

function MeetingDetailTasks({
  meeting,
  disabled,
}: MeetingDetailTasksProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [tasks, setTasks] = useState<DetailedTask[]>(meeting.tasks ?? []);
  const [lastSavedState, setLastSavedState] = useState<{
    tasks: DetailedTask[];
    values: Record<string, boolean>;
  } | null>(null);

  // Create a ref to store the save function
  const saveDataRef = useRef<
    | ((tasksToSave: DetailedTask[], values: Record<string, boolean>) => void)
    | null
  >(null);

  // Initialize the debounced save function
  useEffect(() => {
    const saveData = async (
      tasksToSave: DetailedTask[],
      values: Record<string, boolean>,
    ) => {
      try {
        const updatedTasks = tasksToSave.map((task) => ({
          ...task,
          _id: task._id.startsWith(NEW_TASK_ID_PREFIX) ? undefined : task._id,
          completedOn: values[task._id] ? new Date() : MIN_DATE,
        }));

        const updatedMeeting = await API.put<InvestorMeetingView>(
          StartupUpdateInvestorMeetingTasks.buildEndpoint(meeting._id),
          {
            tasks: updatedTasks,
          },
        );

        if (updatedMeeting) {
          setTasks(updatedMeeting.tasks ?? []);
          setLastSavedState({
            tasks: updatedMeeting.tasks ?? [],
            values: { ...values },
          });
        }
      } catch (error) {
        Logger.error(error);
        if (lastSavedState) {
          setTasks(lastSavedState.tasks);
        }
      }
    };

    saveDataRef.current = debounce(saveData, 1500);
  }, [meeting._id, lastSavedState, meeting.tasks]);

  useEffect(() => {
    setTasks(meeting.tasks ?? []);
  }, [meeting.tasks]);

  const handleAddTask = () => {
    const newTask: DetailedTask = {
      createdOn: new Date(),
      updatedOn: new Date(),
      _id: `${NEW_TASK_ID_PREFIX}${Date.now()}`,
      name: 'New Task',
      completedOn: MIN_DATE,
    };
    setTasks((prevTasks) => [...prevTasks, newTask]);
  };

  const handleDeleteTask = (taskId: string) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task._id !== taskId));
  };

  const handleUpdateTaskName = (taskId: string, newName: string) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task._id === taskId ? { ...task, name: newName } : task,
      ),
    );
  };

  async function onSubmit(
    values: Record<string, boolean>,
    { setSubmitting }: FormikHelpers<Record<string, boolean>>,
  ) {
    setSubmitting(true);
    if (saveDataRef.current) {
      saveDataRef.current(tasks, values);
    }
    setSubmitting(false);
  }

  const initialValues: Record<string, boolean> = {};
  tasks.forEach((task) => {
    initialValues[task._id] =
      task?.completedOn?.getTime() !== MIN_DATE.getTime();
  });

  return (
    <div className="relative">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({})}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, submitForm, setFieldValue }) => (
          <Form>
            <FormCard
              headerStyles="top-10"
              fieldsLayout={cn('px-4 py-5 sm:px-6 grid grid-cols-1 gap-6')}
              title={
                tasks.length > 0
                  ? `Tasks (${Object.values(values).filter((x) => x === true).length} / ${tasks.length} completed)`
                  : 'Tasks'
              }
              fields={tasks.map((task) => ({
                fieldComponent: (
                  <TaskItem
                    key={task._id}
                    task={task}
                    onDelete={handleDeleteTask}
                    onUpdateName={handleUpdateTaskName}
                    setFieldValue={setFieldValue}
                    submitForm={submitForm}
                    disabled={disabled}
                  />
                ),
                validation: yup.object().shape({}),
              }))}
              footer={
                tasks.length < TASKS_PER_MEETING_MAX ? (
                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={handleAddTask}
                      className="flex w-full items-center justify-center space-x-2 rounded p-3 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-600"
                      disabled={disabled}
                    >
                      <PlusIcon className="size-5" />
                      <span>Add New Task</span>
                    </button>
                  </div>
                ) : undefined
              }
            />

            <DebugRender>
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default MeetingDetailTasks;
