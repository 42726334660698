import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { ComparableInvestorListItemView } from '/../libs/shared-types/src/types/view/InvestorListView';
import API from '/src/middleware/API';
import {
  StartupGetCurrentRound,
  StartupGetInvestorLists,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import Logger from '/src/services/logger';
import { SortOrder } from '/src/interfaces/Sortable';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import {
  INVESTOR_DETAIL,
  STARTUP_INVESTOR_LEADS_ROUTE,
  STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE,
  UNVERIFIED_INVESTOR_DETAIL,
} from '/src/constants/Routes';
import {
  CurrencyDollarIcon,
  PaperAirplaneIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid';
import { useInvestorDatabaseContext } from '../InvestorDatabase/InvestorDatabaseContext';
import SearchBar from '/src/components/inputs/SearchBar';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, UPGRADE_MESSAGE } from '/src/constants/SuccessMessages';
import DateString from '/src/components/utility/DateString';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { TargetInvestorMapper } from './TargetInvestorMapper';
import ShareInvestorListDialog from '../InvestorDatabase/ShareInvestorListDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import {
  addManyInvestorToTargetList,
  removeInvestorListFromTargetList,
} from '/src/services/startupService';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import AddInvestorDialog from '../RoundManager/InvestorPipeline/AddInvestorDialog';
import DropdownMultiCheckBoxes from '/src/components/DropdownMultiCheckBoxes';
import { RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS } from '/../libs/shared-types/src/constants/Strength';
import {
  BOOLEAN_WITH_UNSET_OPTIONS,
  NOT_SET_OPTION,
} from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import FieldFilterWrapper from '/src/components/FieldFilterWrapper';
import useGetInvestorListById, {
  INVESTOR_LIST_BY_ID_KEY,
} from '../../../hooks/useGetInvestorListById';
import { useQueryClient } from '@tanstack/react-query';
import useUrlParams from '/src/hooks/useUrlParams';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { enumToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import TargetListIcon from '/src/components/TargetListIcon';
import AddInvestorToListsDialog from '../../investor/ActiveDealflow/dialogs/AddInvestorToListsDialog';
import { InvestorList } from '../../../types/model/InvestorList';

export const defaultSortKey =
  'fitScore' as keyof ComparableInvestorListItemView;

export enum TARGET_LIST_DETAILS_ACTION {
  REFRESH,
  ADD_TO_PIPELINE,
}

export type TargetListActionProps =
  | {
      type: TARGET_LIST_DETAILS_ACTION.REFRESH;
      payload?: undefined;
    }
  | {
      type: TARGET_LIST_DETAILS_ACTION.ADD_TO_PIPELINE;
      payload: {
        investorId: string;
        investorDataType: InvestorDataType;
      };
    };

function InvestorListDetailRoute(): JSX.Element {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [currentRound, setCurrentRound] = useState<RoundView>();
  const [investorLists, setInvestorLists] = useState<InvestorList[]>([]);
  const { addInvestorsToPipeline } = useInvestorDatabaseContext();
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [isAddingToPipeline, setIsAddingToPipeline] = useState(false);
  const [isAddingToTargetList, setIsAddingToTargetList] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [removeInvestorLoading, setRemoveInvestorLoading] = useState(false);
  const { addInvestorToPipeline } = useInvestorDatabaseContext();
  const [resetSelectedTrigger, setResetSelectedTrigger] = useState(false);
  const navigate = useNavigate();
  const { getParam } = useUrlParams();
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'share' | 'addInvestor' | 'addToTargetList';
    isModalOpen: boolean;
  }>({
    type: '',
    isModalOpen: false,
  });

  const {
    data: apiResponse,
    isLoading: isLoadingInvestorList,
    isFetching: isFetchingInvestorList,
    failureReason: failureReasonInvestorList,
  } = useGetInvestorListById({
    id,
    page: getParam('page') || 1,
    perPage: getParam('perPage') || 20,
    filter: getParam('filter'),
    sortKey: getParam('sortKey') || 'fitScore',
    sortOrder: getParam('sortOrder') || String(SortOrder.Desc),
    fieldFilters: getParam('fieldFilters'),
  });
  const queryClient = useQueryClient();

  if (failureReasonInvestorList) {
    setToastConfig({
      title: UPGRADE_MESSAGE,
      message: failureReasonInvestorList.message,
      isError: true,
    });
  }

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch both resources in parallel
        const [round, lists] = await Promise.all([
          API.get<RoundView>(StartupGetCurrentRound.buildEndpoint()),
          API.get<InvestorList[]>(StartupGetInvestorLists.buildEndpoint()),
        ]);

        if (round) {
          setCurrentRound(round);
        }
        setInvestorLists(lists);
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : 'Failed to fetch data';
        Logger.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading || isLoadingInvestorList) {
    return <PageLoadingSpinner message="Loading investor target list... 🎯" />;
  }

  if (!id || !apiResponse) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          <h3>
            Oops! This investor target list doesn&apos;t exist, or you may have
            deleted it.
          </h3>
        </main>
        {toastConfig && (
          <Toast
            isShown={toastConfig !== undefined}
            onClose={() => setToastConfig(undefined)}
            title={toastConfig.title ?? ''}
            isError={toastConfig.isError}
            text={toastConfig.message}
          />
        )}
      </div>
    );
  }

  const invalidateInvestorListData = (investorListIds: string[]) => {
    for (const investorListId of investorListIds) {
      queryClient.invalidateQueries({
        queryKey: [INVESTOR_LIST_BY_ID_KEY, investorListId],
      });
    }
  };

  async function handleAddInvestorToPipeline() {
    if (!currentRound) {
      setToastConfig({
        title: 'You do not have an Investor Pipeline',
        message: 'Open a round to create an Investor Pipeline.',
        isError: true,
      });
      return;
    }

    if (!apiResponse) {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
      return;
    }

    setIsAddingToPipeline(true);

    await addInvestorsToPipeline(
      selectedRows.map((investorId: string) => ({
        investorId: apiResponse.investorList.investors.find(
          (investor) => investor.investorId === investorId,
        )!.investorId,
        investorDataType: apiResponse.investorList.investors.find(
          (investor) => investor.investorId === investorId,
        )!.investorDataType,
      })),
      id,
    );
    resetSelectedRows();
    setIsAddingToPipeline(false);
  }

  const handleRemoveInvestors = async () => {
    setRemoveInvestorLoading(true);
    const { isSuccess } = await removeInvestorListFromTargetList({
      listId: apiResponse.investorList._id,
      investorIds: selectedRows,
    });
    resetSelectedRows();
    if (isSuccess) {
      setToastConfig({
        isError: false,
        message: 'Investors removed from target list',
      });
    } else {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
    }
    setRemoveInvestorLoading(false);
    invalidateInvestorListData([id]);
  };

  const onSelectedChange = (rowsId: string[]) => {
    setSelectedRows(rowsId);
  };

  const onAction = async ({ type, payload }: TargetListActionProps) => {
    if (type === TARGET_LIST_DETAILS_ACTION.ADD_TO_PIPELINE) {
      const { investorId, investorDataType } = payload;
      await addInvestorToPipeline(investorId, investorDataType, id);
      invalidateInvestorListData([id]);
    }

    if (type === TARGET_LIST_DETAILS_ACTION.REFRESH) {
      invalidateInvestorListData([id]);
    }
  };

  const onRowClick = (rowKey: string) => {
    const investor = apiResponse.results.find(
      (result) => result.investorId === rowKey,
    )!;

    switch (investor.investorDataType) {
      case InvestorDataType.Verified:
        navigate(`${INVESTOR_DETAIL}/${investor.investorId}`);
        break;
      case InvestorDataType.Unverified:
        navigate(`${UNVERIFIED_INVESTOR_DETAIL}/${investor.investorId}`);
        break;
      case InvestorDataType.Fragment:
        navigate(
          `${STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE}/${investor.investorId}`,
        );
        break;
      default:
        break;
    }
  };

  const resetSelectedRows = () => {
    setResetSelectedTrigger((prev) => !prev);
  };

  const handleAddInvestorToTargetList = async (
    investorId: string,
    investorDataType: InvestorDataType,
    shouldCloseModal: boolean,
  ) => {
    const { isSuccess } = await addManyInvestorToTargetList({
      investors: [{ investorId, investorDataType }],
      investorListIds: [apiResponse.investorList._id],
    });
    if (isSuccess) {
      setToastConfig({
        isError: false,
        message: 'Investor added to target list',
      });
    } else {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
    }

    invalidateInvestorListData([id]);
    if (shouldCloseModal) {
      closeModal();
    }
  };

  async function handleAddManyInvestorsToTargetList(investorListIds: string[]) {
    if (!apiResponse) {
      return;
    }

    const investorsData = apiResponse.results
      .filter((investor) => selectedRows.includes(investor.investorId))
      .map((populatedInvestor) => ({
        investorId: populatedInvestor.investorId,
        investorDataType: populatedInvestor.investorDataType,
      }));
    setIsAddingToTargetList(true);
    const { isSuccess } = await addManyInvestorToTargetList({
      investors: investorsData,
      investorListIds,
    });
    setIsAddingToTargetList(false);
    if (isSuccess) {
      setToastConfig({
        isError: false,
        message: 'Investors added to target lists',
      });
      resetSelectedRows();
    } else {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
    }
    setModalConfig({ type: '', isModalOpen: false });
    invalidateInvestorListData(investorListIds);
  }

  // To map api data into cell components
  const rows = new TargetInvestorMapper(
    apiResponse.investorList._id,
    !selectedRows.length ? onAction : undefined,
  ).mapAllTo(apiResponse.results);

  return (
    <main>
      <header className="mb-1 mt-3 lg:my-6">
        <section className="space-y-3 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center">
            <div className="flex items-center space-x-1">
              <Link
                to={STARTUP_INVESTOR_LEADS_ROUTE + '/target-lists'}
                className="text-gray-500 hover:text-gray-600 hover:underline hover:underline-offset-2"
              >
                Target Lists
              </Link>
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <h2 className="flex items-center gap-x-3 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              <span className="text-blue-600">
                {apiResponse.investorList.name}
              </span>
              <span className="ml-3 rounded-full bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800">
                {apiResponse.investorList.investors.length} Investors
              </span>
            </h2>
          </div>

          <button
            type="button"
            className="app-button--primary"
            onClick={() => {
              setModalConfig({
                type: 'share',
                isModalOpen: true,
              });
            }}
          >
            <PaperAirplaneIcon className="mr-2 size-5" />
            Share
          </button>
        </section>
        <div className="mt-4 flex items-center gap-x-2 text-sm leading-5 text-gray-500 sm:mt-0">
          <p className="whitespace-nowrap">
            Created{' '}
            <DateString
              date={apiResponse.investorList.createdOn}
              tooltipPosition="bottom"
            />
          </p>
          <>
            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
              <circle cx={1} cy={1} r={1} />
            </svg>
            <p className="whitespace-nowrap">
              Updated{' '}
              <DateString
                date={apiResponse.investorList.updatedOn}
                tooltipPosition="bottom"
              />
            </p>
          </>
        </div>
        {apiResponse.investorList.description && (
          <p className="mt-2 text-sm text-gray-600">
            {apiResponse.investorList.description}
          </p>
        )}
      </header>

      <section className="my-4">
        <div className="mb-3 flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
          <span className="relative flex items-center">
            <SearchBar
              isDebounce
              placeholder="Search Investors"
              initialValue={apiResponse.filter}
              isDisabled={false}
              shouldUpdateUrlParams
              key={JSON.stringify({
                fieldFilters: apiResponse.fieldFilters,
                sortOrder: apiResponse.sortOrder,
                sortKey: apiResponse.sortKey,
                perPage: apiResponse.perPage,
              })}
            />
            {isFetchingInvestorList && (
              <LoadingSpinner color="blue" className="absolute right-[-2rem]" />
            )}
          </span>
          {!!selectedRows.length && (
            <div className="flex-grow space-x-3">
              <button
                type="button"
                className="app-button--neutral"
                onClick={handleAddInvestorToPipeline}
                disabled={isAddingToPipeline}
              >
                <CurrencyDollarIcon className="mr-2 h-5 w-5" />
                Add to Pipeline
              </button>
              <button
                type="button"
                className="app-button--neutral"
                onClick={handleRemoveInvestors}
                disabled={removeInvestorLoading}
              >
                <TrashIcon className="mr-3 h-5 w-5" />
                Remove from List
              </button>
              <button
                type="button"
                className="app-button--neutral"
                onClick={() =>
                  setModalConfig({ type: 'addToTargetList', isModalOpen: true })
                }
                disabled={isAddingToTargetList}
              >
                <TargetListIcon />
                Add to Target Lists
              </button>
            </div>
          )}

          <div className="flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
            <FieldFilterWrapper>
              {(updateParams) => (
                <div className="flex flex-row items-center space-x-2">
                  <span className="text-sm text-gray-600">Filters:</span>
                  <DropdownMultiCheckBoxes
                    key={JSON.stringify(
                      apiResponse.fieldFilters?.investorTypes,
                    )}
                    label="Type"
                    checkboxes={enumToList(InvestorTypes).map((x) => ({
                      label: x,
                      value:
                        apiResponse.fieldFilters.investorTypes?.includes(
                          x as InvestorTypes,
                        ) ?? false,
                    }))}
                    onCheckboxesChange={(checkboxStates) => {
                      updateParams({
                        ...apiResponse.fieldFilters,
                        investorTypes: Object.keys(checkboxStates).filter(
                          (key) => checkboxStates[key],
                        ),
                      });
                    }}
                  />

                  <DropdownMultiCheckBoxes
                    key={JSON.stringify(
                      apiResponse.fieldFilters?.relationshipStrenghts,
                    )}
                    label="Relationship"
                    checkboxes={RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS.map(
                      (relationship) => ({
                        label: relationship.label,
                        value:
                          apiResponse.fieldFilters.relationshipStrenghts?.includes(
                            relationship.value === null
                              ? null
                              : Number(relationship.value),
                          ) ?? false,
                      }),
                    )}
                    onCheckboxesChange={(
                      checkboxStates: Record<string, boolean>,
                    ) => {
                      updateParams({
                        ...apiResponse.fieldFilters,
                        relationshipStrenghts: Object.keys(checkboxStates)
                          .filter((option) => checkboxStates[option])
                          .map((selectedOption) =>
                            selectedOption === NOT_SET_OPTION.label
                              ? NOT_SET_OPTION.value
                              : Number(
                                  RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS.find(
                                    (relationshipOption) =>
                                      relationshipOption.label ===
                                      selectedOption,
                                  )?.value,
                                ),
                          ),
                      });
                    }}
                  />

                  <DropdownMultiCheckBoxes
                    key={JSON.stringify(
                      apiResponse.fieldFilters?.isIntroNeededOptions,
                    )}
                    label="Intro Needed"
                    checkboxes={BOOLEAN_WITH_UNSET_OPTIONS.map((option) => ({
                      label: option.label,
                      value:
                        apiResponse.fieldFilters.isIntroNeededOptions?.includes(
                          option.value,
                        ) ?? false,
                    }))}
                    onCheckboxesChange={(
                      checkboxStates: Record<string, boolean>,
                    ) => {
                      updateParams({
                        ...apiResponse.fieldFilters,
                        isIntroNeededOptions: Object.keys(checkboxStates)
                          .filter((key) => checkboxStates[key])
                          .map((selectedRelationships) =>
                            selectedRelationships === 'Yes'
                              ? true
                              : selectedRelationships === 'No'
                                ? false
                                : NOT_SET_OPTION.value,
                          ),
                      });
                    }}
                  />
                </div>
              )}
            </FieldFilterWrapper>
            <button
              type="button"
              className="app-button--primary"
              onClick={() => {
                setModalConfig({ type: 'addInvestor', isModalOpen: true });
              }}
            >
              <UserPlusIcon className="mr-2 h-5 w-5" />
              Add Investor
            </button>
          </div>
        </div>

        <BasePaginatedTable
          tableName="target-list-detail"
          onSelectedChange={onSelectedChange}
          onRowClick={onRowClick}
          resetSelectedTrigger={resetSelectedTrigger}
          enableSelect
          enableSort
          headerStructure={[
            {
              headerKey: 'name',
              label: 'Name',
            },
            {
              headerKey: 'type',
              label: 'Type',
            },
            {
              headerKey: 'firm',
              label: 'Firm & Role',
            },
            {
              headerKey: 'fitScore',
              label: 'Fit',
              tooltip:
                "Represents how well your company fits in the investor's thesis",
            },
            {
              headerKey: 'checkRangeMin',
              label: 'Check Size',
            },
            {
              headerKey: 'leadsRounds',
              label: 'Leads Rounds',
            },
            {
              headerKey: 'priority',
              label: 'Priority',
            },
            {
              headerKey: 'relationshipStrength',
              label: 'Relationship',
            },
            {
              headerKey: 'isIntroNeeded',
              label: 'Intro needed',
            },
            {
              headerKey: 'targetIntroPathsCount',
              label: 'Intros',
              tooltip:
                'Represents the number of intro paths you have towards this investor',
            },
            {
              headerKey: 'action',
              label: 'Action',
              className: 'sr-only',
            },
          ]}
          rows={rows.map((row) => ({
            rowKey: row.id,
            data: row,
          }))}
          resultCount={apiResponse.totalCount}
          perPage={apiResponse.perPage}
          page={apiResponse.page}
          pageCount={apiResponse.totalPages}
          requestParams={{
            page: apiResponse.page,
            perPage: apiResponse.perPage,
            sortOrder: apiResponse.sortOrder,
            sortKey: apiResponse.sortKey,
            filter: apiResponse.filter,
            fieldFilters: apiResponse.fieldFilters,
          }}
        />
      </section>

      <ModalWrapper
        open={modalConfig.isModalOpen}
        onClose={modalConfig.type === 'share' ? () => closeModal() : () => null}
      >
        {modalConfig.type === 'share' && (
          <ShareInvestorListDialog
            investorList={apiResponse.investorList}
            onShareSettingUpdated={() => {
              onAction({ type: TARGET_LIST_DETAILS_ACTION.REFRESH });
            }}
          />
        )}
        {modalConfig.type === 'addInvestor' && (
          <AddInvestorDialog
            onAddInvestor={handleAddInvestorToTargetList}
            addIn="targetList"
            listId={apiResponse.investorList._id}
            onCancel={() => closeModal()}
          />
        )}
        {modalConfig.type === 'addToTargetList' && (
          <AddInvestorToListsDialog
            investorLists={investorLists}
            onSave={handleAddManyInvestorsToTargetList}
            onCancel={() => closeModal()}
          />
        )}
      </ModalWrapper>

      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.title ?? ''}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </main>
  );
}

export default InvestorListDetailRoute;
