import React from 'react';
import {
  DataRoomViewStatusMap,
  DataRoomViewStatus as DataRoomViewStatusEnum,
} from '/../libs/shared-types/src/constants/DataRoom/DataRoomViewStatus';
import { cn } from '/src/util/cn';
import Tooltip from '../../utility/Tooltip';

export function DataRoomViewStatusCell({
  dataRoomViewStatus,
}: {
  dataRoomViewStatus: DataRoomViewStatusEnum;
}) {
  const viewStatus = DataRoomViewStatusMap.get(dataRoomViewStatus);

  if (!viewStatus) {
    return <span>{dataRoomViewStatus}</span>;
  }

  return (
    <span
      className={cn(
        'rounded-full px-2.5 py-0.5 text-xs font-medium',
        'inline-flex',
        viewStatus.color,
      )}
    >
      {dataRoomViewStatus}
      <Tooltip tooltipText={viewStatus.tooltip} />
    </span>
  );
}
