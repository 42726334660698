import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import FormField from '/src/interfaces/FormField';
import AmountField from './Fields/AmountField';
import CarryChargedField from './Fields/CarryChargedField';
import HasRightsInformationField from './Fields/HasRightsInformationField';
import HasRightsProRataField from './Fields/HasRightsProRataField';
import IsLeadField from './Fields/IsLeadField';
import ReasonsField from './Fields/ReasonsField';
import DealRatingField from './Fields/DealRatingField';
import HasRightsMfnField from './Fields/HasRightsMfnField';
import BoardParticipationField from './Fields/BoardParticipationField';
import InvestorUpdatesFrequencyField from './Fields/InvestorUpdatesFrequencyField';

const RECORD_INVESTMENT_FIELD_MAPPING: { [key: string]: FormField } = {
  [RecordInvestmentFieldNames.Amount]: AmountField,
  [RecordInvestmentFieldNames.BoardParticipation]: BoardParticipationField,
  [RecordInvestmentFieldNames.CarryCharged]: CarryChargedField,
  [RecordInvestmentFieldNames.DealRating]: DealRatingField,
  [RecordInvestmentFieldNames.HasRightsInformation]: HasRightsInformationField,
  [RecordInvestmentFieldNames.HasRightsMfn]: HasRightsMfnField,
  [RecordInvestmentFieldNames.HasRightsProRata]: HasRightsProRataField,
  [RecordInvestmentFieldNames.InvestorUpdatesFrequency]:
    InvestorUpdatesFrequencyField,
  [RecordInvestmentFieldNames.IsLead]: IsLeadField,
  [RecordInvestmentFieldNames.Reasons]: ReasonsField,
};

export default RECORD_INVESTMENT_FIELD_MAPPING;
