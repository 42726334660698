import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import throttle from 'lodash.throttle';
import PageLoadingSpinner from './utility/PageLoadingSpinner';
import { cn } from '../util/cn';
import { File as ReactPdfFile } from 'react-pdf/dist/cjs/shared/types';
import ShortcutKeysConst from '../constants/ShortcutKeys';

// Set up the pdf worker via CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

//
// Set up the pdf worker using the solution for Parcel 2
// Unsure if it is better than the CDN
//
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'npm:pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url
// ).toString();

const DEFAULT_CLASS_NAME = 'relative';
const FULL_SCREEN_CLASS_NAME = 'fixed top-0 left-0 z-20 h-screen w-screen';

interface ViewerSettings {
  containerClassName: string;
}

interface PageSettings {
  pageHeight: number | undefined;
  pageWidth: number | undefined;
}

interface PdfViewer2Props {
  file: ReactPdfFile;
  minWidth?: number;
}

// This should be a simple component to display PDF files. It should not have to handle navigation or do any fancy file fetch logic
// Given a File or url it should render the PDF
function PdfViewer2({ file, minWidth }: PdfViewer2Props): JSX.Element {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const [viewerSettings, setViewerSettings] = useState<ViewerSettings>({
    containerClassName: DEFAULT_CLASS_NAME,
  });
  const [pageSettings, setPageSettings] = useState<PageSettings>({
    pageHeight: undefined,
    pageWidth: minWidth,
  });

  // NOTE: Refs are needed because EventListeners do not get updated when the component re-renders
  // So without useRef the EventListeners stay "stuck" with the initial value of the first render
  const pageRef = useRef(pageNumber);
  const setPageNumberRef = (data: any) => {
    pageRef.current = data;
    setPageNumber(data);
  };

  const totalPageCountRef = useRef(pageNumber);
  const setTotalPageCountRef = (data: any) => {
    totalPageCountRef.current = data;
    setTotalPageCount(data);
  };

  const decrementPage = () => {
    if (pageRef.current > 1) {
      setPageNumberRef(pageRef.current - 1);
    }
  };

  const incrementPage = () => {
    if (pageRef.current < totalPageCountRef.current) {
      setPageNumberRef(pageRef.current + 1);
    }
  };

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      setViewerSettings({
        containerClassName: FULL_SCREEN_CLASS_NAME,
      });
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setViewerSettings({
        containerClassName: DEFAULT_CLASS_NAME,
      });
      setIsFullScreen(false);
    }
  }

  function onPdfContainerSizeChanged() {
    const container = document
      .getElementById('pdf-container')
      ?.getBoundingClientRect();

    if (!container) {
      setPageSettings({
        pageHeight: undefined,
        pageWidth: 400,
      });
      return;
    }
    // setPdfContainerWidth(Math.min(container.width, 1536));
    setPageSettings({
      pageHeight: undefined,
      pageWidth: container.width,
    });
  }

  const onKeyDown = useCallback((event: any) => {
    const { key } = event;
    switch (key) {
      case ShortcutKeysConst.SHORTCUT_ARROW_LEFT_KEY:
        decrementPage();
        break;
      case ShortcutKeysConst.SHORTCUT_ARROW_RIGHT_KEY:
        incrementPage();
        break;
      case ShortcutKeysConst.SHORTCUT_ESCAPE_KEY:
        // TODO:  Handler full screen exit
        // if (isFullScreen) {
        //   toggleFullScreen();
        // }
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setPageNumberRef(1);
  }, [file]);

  useEffect(() => {
    onPdfContainerSizeChanged();

    // Listen to changes to the containser size, so that the pdf width can be updated dynamically
    window.addEventListener('resize', throttle(onPdfContainerSizeChanged, 200));
    // Listen to key presses, to use arrow keys to change pages
    window.addEventListener('keydown', onKeyDown, true);

    // Must clean up event handlers when component unmounts
    return function cleanup() {
      window.removeEventListener(
        'resize',
        throttle(onPdfContainerSizeChanged, 200),
      );
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, []);

  return (
    <div
      id="pdf-container"
      className={cn(viewerSettings.containerClassName, 'bg-white')}
    >
      <Document
        file={file}
        onLoadSuccess={({ numPages }) => setTotalPageCountRef(numPages)}
        onLoadError={(error) => {
          setTotalPageCountRef(undefined);
          Logger.error(error);
        }}
        loading={
          <div className="flex min-h-[300px] items-center justify-center">
            <PageLoadingSpinner
              message="Fetching a potential unicorn... 🦄"
              color="blue"
              size={14}
            />
          </div>
        }
        error={
          <div className="flex min-h-[300px] items-center justify-center">
            Unfortunately, the file could not be loaded 🤌
          </div>
        }
        className="flex h-full items-center justify-center border"
      >
        <Page
          pageNumber={pageNumber}
          // For portrait files we must support Height instead of width
          // height={pageSettings.pageHeight}
          width={pageSettings.pageWidth}
          canvasBackground="white"
          loading={
            <div className="flex min-h-[300px] items-center justify-center">
              <PageLoadingSpinner
                message="Loading page..."
                color="blue"
                size={14}
              />
            </div>
          }
        />
      </Document>

      {totalPageCount > 0 && (
        <div className="absolute bottom-0 z-50 flex h-10 w-full flex-row items-center justify-between bg-slate-500/40 text-white">
          <span className="flex items-center">
            <button
              onClick={decrementPage}
              type="button"
              disabled={pageNumber === 1}
              className="relative mx-4 inline-flex items-center rounded-md px-2 py-1 text-sm font-medium hover:bg-slate-300/25 disabled:bg-transparent disabled:text-slate-300 disabled:hover:bg-transparent disabled:hover:text-slate-300"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumber} / {totalPageCount}
            <button
              onClick={incrementPage}
              type="button"
              disabled={pageNumber === totalPageCount}
              className="relative mx-4 inline-flex items-center rounded-md px-2 py-1 text-sm font-medium hover:bg-slate-300/25 disabled:bg-transparent disabled:text-slate-300 disabled:hover:bg-transparent disabled:hover:text-slate-300"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </span>

          {/* <button
            onClick={() => toggleFullScreen()}
            type="button"
            className="relative mx-4 inline-flex items-center rounded-md px-2 py-1 text-sm font-medium hover:bg-slate-300/25"
          >
            <span className="sr-only">Full screen</span>
            {!isFullScreen && (
              <ArrowsPointingOutIcon className="h-5 w-5" aria-hidden="true" />
            )}
            {isFullScreen && (
              <ArrowsPointingInIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button> */}
        </div>
      )}
    </div>
  );
}

export default PdfViewer2;
