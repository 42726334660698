import React, { useCallback, useContext, useState } from 'react';
import useKeyPress, { KeyModifiers } from '../hooks/useKeyPress';
import CommandPaletteGlobalSearch from '../routes/startup/GlobalSearch/CommandPaletteGlobalSearch';
import { AccountMetadataContext } from './AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import ShortcutKeysConst from '../constants/ShortcutKeys';

function ShortcutProvider({
  children,
}: {
  children: string | JSX.Element[] | JSX.Element;
}): JSX.Element {
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const canAccessGlobalSearch = hasFeatureAccess(
    StartupPaidFeatures.GlobalSearch,
    subscriptionTier,
  );
  const handleCommandK = useCallback((event: KeyboardEvent) => {
    event.preventDefault();

    // Ignore if target is an input or textarea
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLTextAreaElement
    ) {
      return;
    }

    setIsCommandPaletteOpen(true);
  }, []);

  useKeyPress(
    [ShortcutKeysConst.SHORTCUT_GLOBAL_SEARCH_KEY],
    handleCommandK,
    KeyModifiers.Meta,
  );

  useKeyPress([ShortcutKeysConst.SHORTCUT_ESCAPE_KEY], () =>
    setIsCommandPaletteOpen(false),
  );

  return (
    <>
      {children}
      <CommandPaletteGlobalSearch
        isOpen={isCommandPaletteOpen}
        onClose={() => setIsCommandPaletteOpen(false)}
        setIsCommandPaletteOpen={setIsCommandPaletteOpen}
        isDisabled={!canAccessGlobalSearch}
      />
    </>
  );
}

export { ShortcutProvider };
