import React, { useContext, useState } from 'react';
import BasicStatistic from '/src/components/cards/BasicStatistic';
import { formatMilliSecondsAsTimeDuration } from '/src/util/formatting/dates';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import DropdownSelect from '/src/components/DropdownSelect';
import Alert from '/src/components/notifications/Alert';
import { Link } from 'react-router-dom';
import { STARTUP_FLOWLINK_MANAGER_ROUTE } from '/src/constants/Routes';
import {
  StartupAccessManagerViewSessionPaginatedCollectionResponse,
  StartupAccessManagerViewSessionView,
} from '/../libs/shared-types/src/types/view/StartupAccessManagerView';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import EmptyState from '/src/components/notifications/EmptyState';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { CsvDownloadButton } from '../CsvDownloadButton';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import ImportInvestorDialog from '../../InvestorDatabase/ImportInvestorDialog';
import { splitOnWhiteSpace } from '/src/util/formatting/strings';
import FieldFilterWrapper from '/src/components/FieldFilterWrapper';
import API from '/src/middleware/API';
import { StartupGetViewSessionsCsv } from '../../../../../../libs/shared-types/src/constants/ApiRoutes';
import saveAs from 'file-saver';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import AccessManagerViewSessionsTable from './AccessManagerViewSessionsTable';

interface AccessManagerViewSessionsProps {
  apiResponse?: StartupAccessManagerViewSessionPaginatedCollectionResponse;
  isLoading: boolean;
  isFirstLoading: boolean;
  refetchData: () => void;
}

function AccessManagerViewSessions({
  apiResponse,
  isLoading,
  isFirstLoading,
  refetchData,
}: AccessManagerViewSessionsProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const canAccessViewSessions = hasFeatureAccess(
    StartupPaidFeatures.AccessManagerViewSessions,
    subscriptionTier,
  );

  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'importInvestor';
    isModalOpen: boolean;
    viewSession: StartupAccessManagerViewSessionView | undefined;
  }>({
    type: '',
    isModalOpen: false,
    viewSession: undefined,
  });

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false, viewSession: undefined });
  }

  function handleImportInvestor(
    viewSession: StartupAccessManagerViewSessionView,
  ) {
    setModalConfig({ type: 'importInvestor', isModalOpen: true, viewSession });
  }

  const canDownloadCsv = hasFeatureAccess(
    StartupPaidFeatures.ViewSessionCsvExport,
    subscriptionTier,
  );

  async function handleGetStartupViewSessionsCsv() {
    if (!apiResponse) {
      return;
    }

    const blob = await API.getCsv(
      StartupGetViewSessionsCsv.buildEndpoint(undefined, {
        fieldFilters: apiResponse.fieldFilters,
        filter: apiResponse.filter,
        sortKey: apiResponse.sortKey,
        sortOrder: apiResponse.sortOrder,
      }),
    );
    if (!blob) {
      return;
    }
    saveAs(blob, 'View Sessions.csv');
  }

  if (!apiResponse || !apiResponse.results || isFirstLoading) {
    return (
      <PageLoadingSpinner message="Gathering your biggest supporters... 🙌" />
    );
  }

  return (
    <>
      <dl className="my-4 grid w-full grid-cols-2 gap-5 lg:grid-cols-5">
        <BasicStatistic
          label="Total Views"
          statistic={apiResponse.stats.viewSessions}
          suffix={
            apiResponse.stats.uniqueViewers > 0
              ? `(${apiResponse.stats.uniqueViewers} unique)`
              : ''
          }
        />
        <BasicStatistic
          label="Avg. Duration"
          statistic={formatMilliSecondsAsTimeDuration(
            apiResponse.stats.avgDuration,
          )}
        />
        <BasicStatistic
          label="Avg. Deck View Time"
          statistic={formatMilliSecondsAsTimeDuration(
            apiResponse.stats.avgDeckViewTime,
          )}
        />
        <BasicStatistic
          label="Deck Downloads"
          statistic={apiResponse.stats.deckDownloadCount}
        />
        <BasicStatistic
          label="Data Room Views"
          statistic={apiResponse.stats.dataRoomViewCount}
        />
      </dl>

      {apiResponse.totalCount === 0 && apiResponse.filter === '' && (
        <EmptyState
          title="No one has viewed your one-pager yet"
          subTitle="Every time someone views your one-pager their session will be recorded here."
          icon={<EyeSlashIcon className="mx-auto h-12 w-12 text-gray-400" />}
          actionButton={
            <Link
              to={STARTUP_FLOWLINK_MANAGER_ROUTE}
              className="app-button--primary"
            >
              Share your deck & one-pager
            </Link>
          }
        />
      )}

      {(apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
        <>
          <div className="mb-3 flex flex-row items-center justify-between">
            <span className="flex items-center">
              <SearchBar
                placeholder="Search View Sessions"
                initialValue={apiResponse.filter}
                isDisabled={!canAccessViewSessions}
                isDebounce
                shouldUpdateUrlParams
                key={JSON.stringify({
                  fieldFilters: apiResponse.fieldFilters,
                  sortOrder: apiResponse.sortOrder,
                  sortKey: apiResponse.sortKey,
                  perPage: apiResponse.perPage,
                })}
              />
              {isLoading && <LoadingSpinner color="blue" />}
            </span>

            <div className="flex flex-row space-x-2">
              <FieldFilterWrapper>
                {(updateParams) => (
                  <DropdownSelect
                    onSelectionChange={(option) => {
                      updateParams({
                        roundStage: option,
                      });
                    }}
                    initialValue={apiResponse?.fieldFilters?.roundStage ?? ''}
                    label="Round Stage"
                    items={apiResponse?.startupRoundOptions ?? []}
                  />
                )}
              </FieldFilterWrapper>
              <CsvDownloadButton
                handleDownload={handleGetStartupViewSessionsCsv}
                tooltip="Export to CSV a list of all view sessions on your one-pager & deck"
                isDisabled={!canDownloadCsv}
              />
            </div>
          </div>

          <AccessManagerViewSessionsTable
            blurRowsAfterIndex={canAccessViewSessions ? undefined : 4}
            viewSessions={apiResponse.results}
            pageCount={apiResponse.totalPages}
            viewSessionsCount={apiResponse.totalCount}
            requestParams={{
              page: apiResponse.page,
              perPage: apiResponse.perPage,
              sortOrder: apiResponse.sortOrder,
              sortKey: apiResponse.sortKey,
              filter: apiResponse.filter,
              fieldFilters: apiResponse.fieldFilters,
            }}
            onImportInvestor={handleImportInvestor}
          />
        </>
      )}

      {apiResponse.totalCount > 0 && apiResponse.totalCount < 5 && (
        <div className="my-4">
          <Alert
            color="blue"
            alertType="Info"
            content={
              <p>
                <Link
                  className="hyperlink font-bold underline"
                  to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                >
                  Send your FlowLink
                </Link>{' '}
                to investors to securely share your deck and company one-pager
              </p>
            }
            isShown={true}
            canDismiss={false}
          />
        </div>
      )}

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => null}>
        {modalConfig.type === 'importInvestor' && modalConfig.viewSession && (
          <ImportInvestorDialog
            investor={{
              email: modalConfig.viewSession.email ?? '',
              firstName: splitOnWhiteSpace(modalConfig.viewSession.name)[0],
              lastName: splitOnWhiteSpace(modalConfig.viewSession.name)[1],
            }}
            onCancel={() => closeModal()}
            onSave={async () => {
              closeModal();
              refetchData();
            }}
          />
        )}
      </ModalWrapper>
    </>
  );
}

export default AccessManagerViewSessions;
