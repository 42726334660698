import { ClockIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { formatMillisecondsToTime } from '/src/util/formatting/dates';
import { cn } from '/src/util/cn';

interface ActiveStageDurationLeft {
  durationLeft: number | undefined;
}

function ActiveStageDurationLeft({ durationLeft }: ActiveStageDurationLeft) {
  return durationLeft ? (
    <span
      className={cn(
        durationLeft < 0 ? 'text-red-600' : 'text-gray-900',
        'inline-flex items-center truncate text-xs',
      )}
    >
      <ClockIcon className="mr-1 h-3 w-3" aria-hidden="true" />
      {formatMillisecondsToTime(Math.abs(durationLeft))}
      {durationLeft < 0 ? ' overdue' : ' remaining'}
    </span>
  ) : (
    <></>
  );
}

export default ActiveStageDurationLeft;
