import React, { useContext, useState } from 'react';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';
import EmptyState from '/src/components/notifications/EmptyState';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import SearchBar from '/src/components/inputs/SearchBar';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { getConnectionDetailUrl } from '/src/util/navigation';
import { useNavigate } from 'react-router-dom';
import {
  INTRO_PATH_ACTION,
  IntroPathActionProps,
  IntroPathMapper,
} from './IntroPathMapper';
import {
  IntroPathPaginatedCollectionResponse,
  IntroPathView,
} from '../../../types/view/IntroPathView';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import {
  addOrUpdateIntroPath,
  deleteIntroPathById,
} from '/src/services/startupService';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';
import { useQueryClient } from '@tanstack/react-query';
import AddOrUpdateIntroPathDialog from '../MyNetwork/AddOrUpdateIntroPathDialog';
import { getConnectionDataType } from '../../../../../libs/shared-types/src/constants/ConnectionDataType';
import { IntroPathDirection } from '../../../../../libs/shared-types/src/constants/IntroPathDirection';
import FieldFilterWrapper from '/src/components/FieldFilterWrapper';
import DropdownMultiCheckBoxes from '/src/components/DropdownMultiCheckBoxes';
import { enumToSelectOptions } from '../../../../../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { IntroPathStatus } from '../../../../../libs/shared-types/src/constants/IntroPathStatus';
import { NOT_SET_OPTION } from '../../../../../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import { StartupRelationshipType } from '../../../../../libs/shared-types/src/constants/StartupRelationshipType';

interface MyIntroPathsProps {
  apiResponse?: IntroPathPaginatedCollectionResponse;
  isSearchLoading: boolean;
  refetch: () => void;
}

function MyIntroPaths({
  apiResponse,
  isSearchLoading,
  refetch,
}: MyIntroPathsProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'editIntroPath' | 'delete' | 'addIntroPath';
    isModalOpen: boolean;
    introPath: IntroPathView | undefined;
  }>({
    type: '',
    isModalOpen: false,
    introPath: undefined,
  });

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  function invalidateTargetLists() {
    queryClient.invalidateQueries({ queryKey: [INVESTOR_LIST_BY_ID_KEY] });
  }

  function closeModal() {
    setModalConfig({
      type: '',
      isModalOpen: false,
      introPath: undefined,
    });
  }

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Map intro paths between your network and target investors
        </p>
        <ul className="mt-4 list-inside list-disc">
          <li>
            Add new intro paths linking your connections to investors you want
            to reach
          </li>
          <li>See at a glance all the warm intros available to you</li>
          <li>
            Manage the status of each intro request and track your warm intro
            outreach process
          </li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock the ability to import
          your network and map intro paths
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_my_network_persons"
            text="Begin mapping intro paths"
          />
        </div>
      </div>
    );
  }

  if (!apiResponse) {
    return <></>;
  }

  function onRowClick(rowKey: string) {
    const connection = apiResponse?.results.find(
      (x) => x._id === rowKey,
    )?.sourceConnection;
    if (!connection) {
      return;
    }

    const detailUrl = getConnectionDetailUrl(
      getConnectionDataType(connection),
      connection._id,
    );
    navigate(detailUrl);
  }

  const handleDeleteIntroPath = async (introPathId?: string) => {
    if (introPathId) {
      const { isSuccess } = await deleteIntroPathById(introPathId);

      if (isSuccess) {
        invalidateTargetLists();
        closeModal();
        refetch();
      }
    }
  };

  const onAction = async ({ type, payload }: IntroPathActionProps) => {
    if (type === INTRO_PATH_ACTION.DELETE_INTRO_PATH) {
      setModalConfig({
        type: 'delete',
        isModalOpen: true,
        introPath: payload.introPath,
      });
    }

    if (type === INTRO_PATH_ACTION.EDIT_INTRO_PATH) {
      setModalConfig({
        type: 'editIntroPath',
        isModalOpen: true,
        introPath: payload.introPath,
      });
    }

    if (type === INTRO_PATH_ACTION.EDIT_INTRO_PATH_WITHOUT_FORM) {
      const { introPath } = payload;
      await addOrUpdateIntroPath(introPath);
      refetch();
    }
  };

  const rows = new IntroPathMapper(onAction).mapAllTo(apiResponse.results);

  return (
    <>
      {apiResponse.totalCount === 0 &&
        apiResponse.filter === '' &&
        !Object.keys(apiResponse.fieldFilters).length && (
          <EmptyState
            title="You have not mapped any intro paths yet"
            subTitle="Create an intro path to begin mapping connections between your network and target investors."
            icon={
              <ArrowsRightLeftIcon className="mx-auto h-12 w-12 text-gray-400" />
            }
            actionButton={
              <button
                type="button"
                className="app-button--primary"
                onClick={() =>
                  setModalConfig({
                    type: 'addIntroPath',
                    isModalOpen: true,
                    introPath: undefined,
                  })
                }
              >
                Add Intro Path
              </button>
            }
          />
        )}

      {(apiResponse.totalCount > 0 ||
        apiResponse.filter !== '' ||
        Object.keys(apiResponse.fieldFilters).length > 0) && (
        <>
          <div className="mb-3 flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
            <span className="relative flex items-center">
              <SearchBar
                isDebounce
                placeholder="Search intros"
                initialValue={apiResponse.filter}
                isDisabled={false}
                shouldUpdateUrlParams
                key={JSON.stringify({
                  fieldFilters: apiResponse.fieldFilters,
                  sortOrder: apiResponse.sortOrder,
                  sortKey: apiResponse.sortKey,
                  perPage: apiResponse.perPage,
                })}
              />
              {isSearchLoading && (
                <LoadingSpinner
                  color="blue"
                  className="absolute right-[-2rem]"
                />
              )}
            </span>

            <div className="flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
              <div className="flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
                <FieldFilterWrapper>
                  {(updateParams) => {
                    return (
                      <div className="flex flex-row items-center space-x-2">
                        <span className="text-sm text-gray-600">Filters:</span>
                        <DropdownMultiCheckBoxes
                          key={JSON.stringify(
                            apiResponse.fieldFilters?.introPathStatuses,
                          )}
                          label="Status"
                          checkboxes={enumToSelectOptions(IntroPathStatus).map(
                            ({ value, label }) => ({
                              label: label,
                              value:
                                apiResponse.fieldFilters.introPathStatuses?.includes(
                                  value as IntroPathStatus | null,
                                ) ?? false,
                            }),
                          )}
                          onCheckboxesChange={(checkboxStates) => {
                            updateParams({
                              ...apiResponse.fieldFilters,
                              introPathStatuses: Object.keys(
                                checkboxStates,
                              ).filter((key) => checkboxStates[key]),
                            });
                          }}
                        />

                        <DropdownMultiCheckBoxes
                          key={JSON.stringify(
                            apiResponse.fieldFilters?.connectionTypes,
                          )}
                          label="Connection Type"
                          checkboxes={[
                            ...enumToSelectOptions(StartupRelationshipType),
                            NOT_SET_OPTION,
                          ].map(({ label, value }) => ({
                            label: label,
                            value:
                              apiResponse.fieldFilters.connectionTypes?.includes(
                                value === null
                                  ? null
                                  : (value as StartupRelationshipType | null),
                              ) ?? false,
                          }))}
                          onCheckboxesChange={(
                            checkboxStates: Record<string, boolean>,
                          ) => {
                            updateParams({
                              ...apiResponse.fieldFilters,
                              connectionTypes: Object.keys(checkboxStates)
                                .filter((option) => checkboxStates[option])
                                .map((selectedOption) =>
                                  selectedOption === NOT_SET_OPTION.label
                                    ? NOT_SET_OPTION.value
                                    : [
                                        ...enumToSelectOptions(
                                          StartupRelationshipType,
                                        ),
                                        NOT_SET_OPTION,
                                      ].find(
                                        (relationshipOption) =>
                                          relationshipOption.label ===
                                          selectedOption,
                                      )?.value,
                                ),
                            });
                          }}
                        />
                      </div>
                    );
                  }}
                </FieldFilterWrapper>
              </div>
              <button
                type="button"
                className="app-button--primary"
                onClick={() => {
                  setModalConfig({
                    type: 'addIntroPath',
                    isModalOpen: true,
                    introPath: undefined,
                  });
                }}
              >
                <ArrowsRightLeftIcon className="mr-2 h-5 w-5" />
                Add Intro Path
              </button>
            </div>
          </div>

          <BasePaginatedTable
            tableName="imported-investors-table"
            enableSort
            onRowClick={onRowClick}
            headerStructure={[
              {
                headerKey: 'sourceName',
                label: 'Connection',
              },
              {
                headerKey: 'sourceStartupRelationshipType',
                label: 'Connection Type',
                tooltip: 'Represents how you know the connection',
              },
              {
                headerKey: 'targetName',
                label: 'Target Person',
              },
              {
                headerKey: 'targetFirm',
                label: 'Firm & Role',
              },
              {
                headerKey: 'targetIntroRelationshipType',
                label: 'Intro Type',
                tooltip:
                  'Represents how the connection knows the target investor',
              },
              {
                headerKey: 'targetIntroRelationshipStrength',
                label: 'Intro Strength',
                tooltip:
                  'Represents how strong the relationship between the connection and the target investor is',
              },
              {
                headerKey: 'status',
                label: 'Status',
              },
              {
                headerKey: 'impactScore',
                label: 'Path Impact',
                tooltip:
                  'Represents how likely the intro path will result in a high-quality introduction to the target investor',
              },
              {
                headerKey: 'action',
                label: 'Action',
                className: 'sr-only',
              },
            ]}
            rows={rows.map((row) => ({
              rowKey: row.id,
              data: row,
            }))}
            resultCount={apiResponse.totalCount}
            perPage={apiResponse.perPage}
            page={apiResponse.page}
            pageCount={apiResponse.totalPages}
            requestParams={{
              page: apiResponse.page,
              perPage: apiResponse.perPage,
              sortOrder: apiResponse.sortOrder,
              sortKey: apiResponse.sortKey,
              filter: apiResponse.filter,
              fieldFilters: apiResponse.fieldFilters,
            }}
          />
        </>
      )}

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => null}>
        {modalConfig.type === 'delete' && modalConfig?.introPath && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() =>
              handleDeleteIntroPath(modalConfig.introPath?._id)
            }
            title="Are you sure you want to delete this intro path?"
            text="This action cannot be undone."
            primaryAction="Delete Intro Path"
            color="red"
          />
        )}

        {modalConfig.type === 'editIntroPath' && modalConfig.introPath && (
          <AddOrUpdateIntroPathDialog
            direction={IntroPathDirection.FacilitatedBy}
            currentId={modalConfig.introPath.sourceConnection._id}
            currentDataType={getConnectionDataType(
              modalConfig.introPath.sourceConnection,
            )}
            currentName={
              modalConfig.introPath.sourceConnection.firstName +
              ' ' +
              modalConfig.introPath.sourceConnection.lastName
            }
            introPath={{
              ...modalConfig.introPath,
            }}
            onCancel={closeModal}
            onSave={() => {
              closeModal();
              refetch();
            }}
          />
        )}

        {modalConfig.type === 'addIntroPath' && (
          <AddOrUpdateIntroPathDialog
            onCancel={closeModal}
            onSave={() => {
              closeModal();
              refetch();
            }}
          />
        )}
      </ModalWrapper>
    </>
  );
}

export default MyIntroPaths;
