import React, { useContext, useState } from 'react';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { UserPlusIcon as UserPlusIconSolid } from '@heroicons/react/24/solid';
import EmptyState from '/src/components/notifications/EmptyState';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { useNavigate } from 'react-router-dom';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import SearchBar from '/src/components/inputs/SearchBar';
import ImportInvestorDialog from './ImportInvestorDialog';
import { InvestorFragment } from '/../libs/shared-types/src/types/model/InvestorFragment';
import {
  ArrowTopRightOnSquareIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/20/solid';
import {
  StartupAddInvestorToLists,
  StartupDeleteInvestorFragment,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import AddInvestorToListsDialog from '../../investor/ActiveDealflow/dialogs/AddInvestorToListsDialog';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import { STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE } from '/src/constants/Routes';
import {
  ImportedInvestorActionProps,
  ImportedInvestorMapper,
} from './ImportedInvestorMapper';
import { INVESTOR_ACTION } from './InvestorMapper';
import {
  addInvestorsToPipeline,
  addManyInvestorToTargetList,
  fetchActiveRound,
} from '/src/services/startupService';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import {
  InvestorFragmentsPaginatedCollectionResponse,
  InvestorFragmentView,
} from '../../../types/view/InvestorFragmentView';
import Logger from '/src/services/logger';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';
import TargetListIcon from '/src/components/TargetListIcon';

interface InvestorDatabaseImportedInvestorsProps {
  apiResponse?: InvestorFragmentsPaginatedCollectionResponse;
  isSearchLoading: boolean;
  investorLists: InvestorList[];
  fetchInvestorLists: () => Promise<void>;
  invalidate: () => void;
}

function InvestorDatabaseImportedInvestors({
  apiResponse,
  isSearchLoading,
  investorLists,
  fetchInvestorLists,
  invalidate,
}: InvestorDatabaseImportedInvestorsProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [isAddingToPipeline, setIsAddingToPipeline] = useState(false);
  const [isAddingToTargetList, setIsAddingToTargetList] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'edit' | 'lists' | 'delete';
    isModalOpen: boolean;
    investor: InvestorFragmentView | undefined;
  }>({
    type: '',
    isModalOpen: false,
    investor: undefined,
  });
  const [resetSelectedTrigger, setResetSelectedTrigger] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const invalidateInvestorListData = (investorListIds: string[]) => {
    for (const investorListId of investorListIds) {
      queryClient.invalidateQueries({
        queryKey: [INVESTOR_LIST_BY_ID_KEY, investorListId],
      });
    }
  };

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false, investor: undefined });
    setErrorMessage('');
  }

  async function handleSaveInvestor() {
    invalidate();
    await fetchInvestorLists();
    closeModal();
  }

  async function handleDeleteInvestor(investor: InvestorFragmentView) {
    try {
      await API.delete<InvestorFragment>(
        `${StartupDeleteInvestorFragment.buildEndpoint(investor._id)}`,
      );
      invalidate();
      await fetchInvestorLists();
      closeModal();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Import your investor network and manage your whole fundraise in one
          place
        </p>
        <a
          href="https://www.loom.com/share/8a138a798aa1469f98d03ed9f18e9dfa?sid=542d8ad5-dbc5-47f9-a661-04037a4a815e"
          target="_blank"
          rel="noopener noreferrer"
          className="hyperlink flex items-center space-x-2"
        >
          <span>Check out how Investor Import and Target Lists work</span>
          <ArrowTopRightOnSquareIcon className="size-5" />
        </a>
        <ul className="mt-4 list-inside list-disc">
          <li>
            Create and manage investors who are not available in Flowlie&apos;s
            database
          </li>
          <li>Use imported investors across the whole application</li>
          <li>All your imported investors stay private to you</li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock the full investor
          database, target lists, and the ability to manage your investor
          pipeline.
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_investor_database_imported_investors"
            text="Import your own investor network"
          />
        </div>
      </div>
    );
  }

  if (!apiResponse) {
    return <></>;
  }

  const onAction = async ({ type, payload }: ImportedInvestorActionProps) => {
    if (type === INVESTOR_ACTION.ADD_TO_TARGET_LIST) {
      setModalConfig({ type: 'lists', isModalOpen: true, investor: payload });
    }

    if (type === INVESTOR_ACTION.REFRESH) {
      invalidate();
      fetchInvestorLists();
    }
  };

  const onSelectedChange = (rowsId: string[]) => {
    setSelectedRows(rowsId);
  };

  const handleAddInvestorsToPipeline = async () => {
    const currentRound = await fetchActiveRound();
    if (!currentRound) {
      setToastConfig({
        title: 'You do not have an Investor Pipeline',
        message: 'Open a round to create an Investor Pipeline.',
        isError: true,
      });
      return;
    }

    if (!apiResponse) {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
      return;
    }

    setIsAddingToPipeline(true);

    const investorsToAdd = selectedRows.map((investorId: string) => ({
      investorId: apiResponse.results.find(
        (investor) => investor._id === investorId,
      )!._id,
      investorDataType: InvestorDataType.Fragment,
    }));

    const { isSuccess } = await addInvestorsToPipeline(investorsToAdd);
    if (isSuccess) {
      resetSelectedRows();
      onAction({ type: INVESTOR_ACTION.REFRESH });
      setToastConfig({
        isError: false,
        message: 'Investors added to pipeline',
      });
    } else {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
    }
    setIsAddingToPipeline(false);
  };

  async function updateListsWithInvestor(
    investorId: string,
    investorListIds: string[],
  ) {
    try {
      await API.put(StartupAddInvestorToLists.buildEndpoint(), {
        investorId: investorId,
        investorDataType: InvestorDataType.Fragment,
        investorListIds: investorListIds,
      });
      closeModal();
      resetSelectedRows();
      fetchInvestorLists();
      invalidateInvestorListData(investorListIds);
    } catch (error) {
      Logger.error(error);
    }
  }

  const handleAddInvestorsToTargetList = async (investorListIds: string[]) => {
    const investorsData = apiResponse.results
      .filter((investor) => selectedRows.includes(investor._id))
      .map((populatedInvestor) => ({
        investorId: populatedInvestor._id,
        investorDataType: InvestorDataType.Fragment,
      }));
    setIsAddingToTargetList(true);
    const { isSuccess } = await addManyInvestorToTargetList({
      investors: investorsData,
      investorListIds,
    });
    setIsAddingToTargetList(false);
    if (isSuccess) {
      setToastConfig({
        isError: false,
        message: 'Investors added to target lists',
      });
    } else {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
    }
    closeModal();
    fetchInvestorLists();
    invalidateInvestorListData(investorListIds);
  };

  const resetSelectedRows = () => {
    setResetSelectedTrigger((prev) => !prev);
  };

  const onRowClick = (rowKey: string) => {
    navigate(`${STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE}/${rowKey}`);
  };

  const rows = new ImportedInvestorMapper(
    !selectedRows.length ? onAction : undefined,
  ).mapAllTo(apiResponse.results);

  return (
    <>
      {apiResponse.totalCount === 0 && apiResponse.filter === '' && (
        <EmptyState
          title="You have not imported any investors yet"
          subTitle="Create and manage your investor records that you have not found in the Investor Database."
          icon={<UserPlusIcon className="mx-auto h-12 w-12 text-gray-400" />}
          actionButton={
            <button
              type="button"
              className="app-button--primary"
              onClick={() =>
                setModalConfig({
                  type: 'edit',
                  isModalOpen: true,
                  investor: undefined,
                })
              }
            >
              Add Investor
            </button>
          }
        />
      )}

      {(apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
        <>
          <div className="mb-3 flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
            <span className="relative flex items-center">
              <SearchBar
                isDebounce
                placeholder="Search Investors"
                initialValue={apiResponse.filter}
                isDisabled={false}
                shouldUpdateUrlParams
                key={JSON.stringify({
                  fieldFilters: apiResponse.fieldFilters,
                  sortOrder: apiResponse.sortOrder,
                  sortKey: apiResponse.sortKey,
                  perPage: apiResponse.perPage,
                })}
              />
              {isSearchLoading && (
                <LoadingSpinner
                  color="blue"
                  className="absolute right-[-2rem]"
                />
              )}
            </span>
            {!!selectedRows.length && (
              <div className="flex-grow space-x-3">
                <button
                  type="button"
                  className="app-button--neutral"
                  onClick={handleAddInvestorsToPipeline}
                  disabled={isAddingToPipeline}
                >
                  <CurrencyDollarIcon className="mr-2 h-5 w-5" />
                  Add to Pipeline
                </button>
                <button
                  type="button"
                  className="app-button--neutral"
                  onClick={() =>
                    setModalConfig({
                      type: 'lists',
                      isModalOpen: true,
                      investor: undefined,
                    })
                  }
                  disabled={isAddingToTargetList}
                >
                  <TargetListIcon />
                  Add to Target Lists
                </button>
              </div>
            )}
            <button
              type="button"
              className="app-button--primary"
              onClick={() => {
                setModalConfig({
                  type: 'edit',
                  isModalOpen: true,
                  investor: undefined,
                });
              }}
            >
              <UserPlusIconSolid className="mr-2 h-5 w-5" />
              Add Investor
            </button>
          </div>

          <BasePaginatedTable
            tableName="imported-investors-table"
            onSelectedChange={onSelectedChange}
            onRowClick={onRowClick}
            resetSelectedTrigger={resetSelectedTrigger}
            enableSelect
            enableSort
            headerStructure={[
              {
                headerKey: 'name',
                label: 'Name',
              },
              {
                headerKey: 'type',
                label: 'Type',
              },
              {
                headerKey: 'firm',
                label: 'Firm & Role',
              },
              {
                headerKey: 'email',
                label: 'email',
              },
              {
                headerKey: 'links',
                label: 'links',
              },
              {
                headerKey: 'fitLabel',
                label: 'Fit',
              },
              {
                headerKey: 'checkRangeMin',
                label: 'Check Size',
              },
              {
                headerKey: 'leadsRounds',
                label: 'Leads Rounds',
              },
              {
                headerKey: 'createdOn',
                label: 'Imported On',
              },
              {
                headerKey: 'action',
                label: 'Action',
                className: 'sr-only',
              },
            ]}
            rows={rows.map((row) => ({
              rowKey: row.id,
              data: row,
            }))}
            resultCount={apiResponse.totalCount}
            perPage={apiResponse.perPage}
            page={apiResponse.page}
            pageCount={apiResponse.totalPages}
            requestParams={{
              page: apiResponse.page,
              perPage: apiResponse.perPage,
              sortOrder: apiResponse.sortOrder,
              sortKey: apiResponse.sortKey,
              filter: apiResponse.filter,
            }}
          />
        </>
      )}

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => null}>
        {modalConfig.type === 'edit' && (
          <ImportInvestorDialog
            investor={modalConfig.investor}
            onCancel={() => closeModal()}
            onSave={handleSaveInvestor}
          />
        )}
        {/* Adding several investors to target list */}
        {modalConfig.type === 'lists' && !modalConfig.investor && (
          <AddInvestorToListsDialog
            investorLists={investorLists}
            onSave={handleAddInvestorsToTargetList}
            onCancel={() => closeModal()}
          />
        )}
        {/* Adding one investor to target list */}
        {modalConfig.type === 'lists' && modalConfig.investor && (
          <AddInvestorToListsDialog
            investorLists={investorLists}
            onSave={updateListsWithInvestor}
            investorId={modalConfig.investor._id}
            investorName={`${modalConfig.investor.firstName} 
              ${modalConfig.investor.lastName}`}
            onCancel={() => closeModal()}
          />
        )}

        {modalConfig.type === 'delete' && modalConfig.investor && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() => handleDeleteInvestor(modalConfig.investor!)}
            title="Are you sure you want to delete this investor?"
            text="If you added it to your investor pipeline, you must first remove it from the pipeline. All associated relationships and intro paths will be deleted."
            primaryAction="Delete Investor"
            color="red"
            errorMessage={errorMessage}
          />
        )}
      </ModalWrapper>
      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </>
  );
}

export default InvestorDatabaseImportedInvestors;
