import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvestorMeetingView } from '/../libs/shared-types/src/types/view/InvestorMeetingView';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import {
  StartupGetMeetingAnalysis,
  StartupInvestorMeetingById,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { PencilSquareIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import MeetingDetailCard from './MeetingDetailCard';
import MeetingDetailHeader from './MeetingDetailHeader';
import SentimentTag from './MeetingSentimentTag';
import MeetingDetailTasks from './MeetingDetailTasks';
import EmptyState from '/src/components/notifications/EmptyState';
import { GenAiButton } from '/src/components/GenAiButton';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid';
import AddOrUpdateInvestorMeetingDialog from '../InvestorMeeting/AddOrUpdateInvestorMeeting';
import Tooltip from '/src/components/utility/Tooltip';
import FileCard from '/src/components/cards/FileCard';
import { FileType } from '/src/constants/FileUploadTypes';
import { downloadPdf, downloadTxt } from '/src/services/File';
import {
  GenAiTransactionTypes,
  getGenAiTransactionCost,
} from '/../libs/shared-types/src/constants/GenAiTransactionTypes';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { MeetingAnalysis } from '/../libs/shared-types/src/types/model/InvestorMeeting';
import { DetailedTask } from '../../../types/model/Task';

function MeetingDetailRoute(): JSX.Element {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessingTranscript, setIsProcessingTranscript] = useState(false);
  const [meeting, setMeeting] = useState<InvestorMeetingView>();
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [isFirstActivityLoading, setIsFirstActivityLoading] = useState(true);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'edit' | 'share';
    isModalOpen: boolean;
  }>({
    type: '',
    isModalOpen: false,
  });

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false });
  }

  async function fetchData(isRefreshing = false) {
    if (id === undefined) {
      return;
    }

    setIsLoading(!isRefreshing);

    try {
      const meetingData = await API.get<InvestorMeetingView>(
        StartupInvestorMeetingById.buildEndpoint(id),
      );
      setMeeting(meetingData);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsFirstActivityLoading(false);
      setIsLoading(false);
    }
  }

  async function onDownloadFile() {
    if (!meeting?.transcriptKey || !meeting?.transcriptSignedUrl) {
      return;
    }

    if (meeting.transcriptKey.includes(FileType.Pdf)) {
      await downloadPdf(meeting.transcriptSignedUrl, meeting.transcriptKey);
      return;
    }

    if (
      meeting.transcriptKey.includes(FileType.Txt) ||
      meeting.transcriptKey.includes(FileType.TxtPlain)
    ) {
      await downloadTxt(meeting.transcriptSignedUrl, meeting.transcriptKey);
      return;
    }
  }

  // we listen for url changes and re-fetch data
  // if we get a new id
  React.useEffect(() => {
    fetchData();
  }, [id]);

  if (isFirstActivityLoading) {
    return <PageLoadingSpinner message="Loading the meeting... 🤝" />;
  }

  if (!meeting) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          <h3>Oops! The meeting you are looking for doesn&apos;t exist.</h3>
        </main>
      </div>
    );
  }

  async function requestMeetingAnalysis() {
    if (!meeting?.transcriptKey || isProcessingTranscript) {
      return;
    }

    setIsProcessingTranscript(true);
    try {
      const response = await API.get<MeetingAnalysis>(
        StartupGetMeetingAnalysis.buildEndpoint(meeting._id),
      );
      setMeeting({
        ...meeting,
        summary: response.summary,
        pointsOfDiscussion: response.pointsOfDiscussion,
        investorThesisInsights: response.investorThesisInsights,
        sentimentReasons: response.sentimentReasons,
        sentiment: response.sentiment,
        pointsOfInterest: response.pointsOfInterest,
        pointsOfConcern: response.pointsOfConcern,
        tasks: response.tasks,
      });
    } catch (error: any) {
      Logger.error(error);
      setToastConfig({
        isError: true,
        title: 'Failed to analyze transcript',
        message: error.message,
      });
    } finally {
      setIsProcessingTranscript(false);
    }
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="pb-8">
        <header className="sticky top-0 z-40 bg-gray-100 sm:pt-6">
          <div className="relative mx-auto max-w-full lg:flex lg:items-center lg:justify-between lg:space-x-5">
            <MeetingDetailHeader meeting={meeting} />
            <div className="flex space-x-3">
              <button
                className="app-button--neutral mt-3 lg:mt-0"
                onClick={() =>
                  setModalConfig({ type: 'edit', isModalOpen: true })
                }
                disabled={isProcessingTranscript}
              >
                <div className="relative mr-2">
                  <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                Edit
              </button>
              {/* <button
                className="app-button--primary mt-3 lg:mt-0"
                onClick={() =>
                  setModalConfig({ type: 'share', isModalOpen: true })
                }
              >
                <div className="relative mr-2">
                  <PaperAirplaneIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                Share
              </button> */}
            </div>
          </div>
        </header>
        <div className="mt-5 px-1">
          <div className="mx-auto mt-8 grid max-w-full grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
            <section className="space-y-6 lg:col-span-2 lg:col-start-1">
              {isProcessingTranscript && (
                <EmptyState
                  title="Transcript analysis in progress"
                  subTitle=""
                  icon={
                    <ClipboardDocumentListIcon className="mx-auto h-12 w-12 text-gray-400" />
                  }
                  actionButton={
                    <div className="flex justify-center">
                      <LoadingSpinner color="blue" size={7} />
                    </div>
                  }
                />
              )}
              {!isProcessingTranscript &&
                !meeting.summary &&
                !meeting.pointsOfDiscussion?.length &&
                !meeting.investorThesisInsights?.length &&
                !meeting.sentimentReasons?.length &&
                !meeting.pointsOfInterest?.length &&
                !meeting.pointsOfConcern?.length && (
                  <EmptyState
                    title={
                      meeting.transcriptKey
                        ? 'You have not analyzed your transcript yet'
                        : 'Add a transcript to this meeting to analyze it'
                    }
                    subTitle={
                      'Get powerful insights from your meeting, including summary points, investor sentiment, and points of feedback '
                    }
                    icon={
                      <ClipboardDocumentListIcon className="mx-auto h-12 w-12 text-gray-400" />
                    }
                    actionButton={
                      meeting.transcriptKey ? (
                        <GenAiButton
                          onClick={requestMeetingAnalysis}
                          text={`Analyze Transcript (${getGenAiTransactionCost(GenAiTransactionTypes.MeetingAnalysis)} credits)`}
                          tooltipText={
                            'Analyze your transcript with Flowlie AI'
                          }
                          disabled={isProcessingTranscript}
                        />
                      ) : (
                        <button
                          type="button"
                          className="app-button--primary"
                          onClick={() =>
                            setModalConfig({
                              type: 'edit',
                              isModalOpen: true,
                            })
                          }
                          disabled={isProcessingTranscript}
                        >
                          Upload Transcript
                        </button>
                      )
                    }
                  />
                )}
              <MeetingDetailCard
                title="Meeting Summary"
                content={meeting.summary}
              />
              <MeetingDetailCard
                title="Key Discussion Points"
                content={meeting.pointsOfDiscussion}
              />
              <MeetingDetailCard
                title="Investment Thesis Insights"
                content={meeting.investorThesisInsights}
                titleAside={
                  <Tooltip
                    position="left"
                    tooltipText="Additional details about the investor's investment preferences, timeline, and process"
                  />
                }
              />
              <MeetingDetailCard
                title="Sentiment Analysis"
                content={meeting.sentimentReasons}
                titleAside={
                  <div className="flex items-center">
                    {meeting.sentiment ? (
                      <SentimentTag sentiment={meeting.sentiment} />
                    ) : undefined}
                    <Tooltip
                      position="left"
                      tooltipText="Key indicators of investor sentiment about the company and the investment opportunity"
                    />
                  </div>
                }
              />
              <MeetingDetailCard
                title="Investor Interests"
                content={meeting.pointsOfInterest}
                titleAside={
                  <Tooltip
                    position="left"
                    tooltipText="These are the areas in which the investor showed significant interest and that should be emphasized"
                  />
                }
              />
              <MeetingDetailCard
                title="Investor Concerns"
                content={meeting.pointsOfConcern}
                titleAside={
                  <Tooltip
                    position="left"
                    tooltipText="These are the areas in which the investor has concerns that need to be addressed otherwise they can put the deal at risk"
                  />
                }
              />
            </section>

            <section className="flex flex-col space-y-6">
              {meeting.transcriptKey && (
                <FileCard
                  fileKey={meeting.transcriptKey}
                  title="Transcript"
                  onDownloadFile={onDownloadFile}
                  // onViewFile={() => {}}
                  processFileButton={
                    <GenAiButton
                      onClick={requestMeetingAnalysis}
                      disabled={isProcessingTranscript}
                      text={`Analyze (${getGenAiTransactionCost(GenAiTransactionTypes.MeetingAnalysis)} credits)`}
                      tooltipText={'Analyze your transcript with Flowlie AI'}
                    />
                  }
                />
              )}
              <MeetingDetailTasks
                meeting={meeting}
                disabled={isProcessingTranscript}
              />
            </section>
          </div>
        </div>
      </main>

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => null}>
        {modalConfig.type === 'edit' && (
          <AddOrUpdateInvestorMeetingDialog
            investorDataType={meeting.investorDataType}
            investorId={meeting.investorId}
            investorName={meeting.investor.name}
            onCancel={closeModal}
            onSuccess={() => {
              closeModal();
              fetchData(true);
            }}
            investorMeeting={meeting}
          />
        )}
      </ModalWrapper>

      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? (toastConfig.title ?? ERROR) : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </div>
  );
}

export default MeetingDetailRoute;
