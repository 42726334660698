import React from 'react';
import { cn } from '../util/cn';

interface TextCharacterCounterProps {
  textLength: number;
  maxLength: number;
}

function TextCharacterCounter({
  textLength,
  maxLength,
}: TextCharacterCounterProps): JSX.Element {
  return (
    <div className="flex justify-end">
      <span
        className={cn(
          textLength > maxLength ? 'text-red-600' : 'text-gray-800',
          'text-xs',
        )}
      >
        {textLength ?? 0}/{maxLength}
        &nbsp;Characters
      </span>
    </div>
  );
}

export default TextCharacterCounter;
