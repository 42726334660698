import React, { useContext, useState } from 'react';
import { AccountMetadataContext } from '../contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import KeyShortcutLabel, { detectOs } from './inputs/KeyShortcutLabel';
import ShortcutKeysConst from '/src/constants/ShortcutKeys';
import { KeyModifiers } from '../hooks/useKeyPress';
import { SubscriptionCTAPill } from './SubscriptionCTA';

function GlobalSearchButton({}): JSX.Element {
  const [osData] = useState(detectOs());

  const { subscriptionTier } = useContext(AccountMetadataContext);
  const canAccessGlobalSearch = hasFeatureAccess(
    StartupPaidFeatures.GlobalSearch,
    subscriptionTier,
  );

  const triggerShortcut = () => {
    if (!canAccessGlobalSearch) {
      return;
    }

    // Create a new keyboard event with the appropriate modifier key
    const event = new KeyboardEvent('keydown', {
      key: 'k',
      code: 'KeyK',
      metaKey: osData.modifierKey === KeyModifiers.Meta,
      ctrlKey: osData.modifierKey === KeyModifiers.Ctrl,
      altKey: false,
      shiftKey: false,
      bubbles: true,
    });

    // Dispatch the event on the document
    document.dispatchEvent(event);
  };

  return (
    <>
      <button type="button" onClick={triggerShortcut}>
        <div className="flex flex-col items-start">
          <div className="group relative flex items-center">
            <div className="absolute inset-y-0 left-0 flex py-1.5 pr-1.5">
              <div className="inline-flex items-center space-x-2 px-1.5 font-sans text-sm font-medium text-gray-400">
                <MagnifyingGlassIcon className="h-4 w-4" />
              </div>
            </div>

            <input
              placeholder="Search investors and connections"
              readOnly
              tabIndex={-1}
              disabled={!canAccessGlobalSearch}
              className="block w-[24rem] truncate rounded-md border-gray-300 py-1.5 pl-[1.5rem] pr-9 shadow-sm group-hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              {!canAccessGlobalSearch ? (
                <SubscriptionCTAPill
                  id="cta_global_search_button"
                  tooltipText="Upgrade to Pro to unlock Search"
                />
              ) : (
                <div className="inline-flex items-center px-1.5">
                  <KeyShortcutLabel
                    shortcutKey={ShortcutKeysConst.SHORTCUT_GLOBAL_SEARCH_KEY.toUpperCase()}
                    keyModifier={osData}
                    srLabel="search"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </button>
    </>
  );
}

export default GlobalSearchButton;
