import React, { Fragment, useContext, useState } from 'react';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { cn } from '/src/util/cn';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { SlideUp } from '/src/components/animations/Slide';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';

function PartnerOffersRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const hasAccess = hasFeatureAccess(
    StartupPaidFeatures.DiscountsAndPerks,
    subscriptionTier,
  );

  const style = { background: 'transparent', border: '1px solid #ccc' };

  return (
    <main className="flex h-screen flex-col">
      <h2 className="page-title">Discounts & Perks</h2>
      <p className="-mt-3 text-gray-800">
        Check back regularly for curated discounts and exclusive perks to help
        you build your business. You can claim some offers immediately, while
        others require you to submit a request.
      </p>
      <div className="flex-1 sm:-mx-6 lg:-mx-8">
        {isLoading && (
          <PageLoadingSpinner message="Loading discounts & perks for you... 💰" />
        )}
        <section className="relative h-full min-w-full">
          <div
            className={cn(
              isLoading
                ? 'invisible'
                : 'inline-block h-full min-w-full py-2 align-middle sm:px-6 lg:px-8',
              hasAccess ? '' : 'pointer-events-none select-none blur-[5px]',
            )}
          >
            <iframe
              title="Discounts & Perks"
              className="airtable-embed"
              src="https://airtable.com/embed/shrpDdxD21NQfnq0Q"
              width="100%"
              height="100%"
              style={style}
              onLoad={() => setIsLoading(false)}
            ></iframe>
          </div>

          {!hasAccess && (
            <SlideUp as={Fragment}>
              <div className="absolute left-0 right-0 top-[50%] z-30 space-y-4 rounded-lg border-2 border-gray-200 bg-white p-8 shadow-md sm:left-[20%] sm:top-[30%] sm:w-[60%]">
                <p className="font-medium">
                  Unlock over $1,000 in discounts and perks for your startup
                </p>
                <p>
                  Access a curated collection of offers tailored specifically
                  for early-stage startups like yours. Upgrade to Flowlie Pro
                  today and start saving on tools and services essential for
                  your growth.
                </p>

                <div className="w-max">
                  <SubscriptionCTAPill id="cta_discounts_and_perks" />
                </div>
              </div>
            </SlideUp>
          )}
        </section>
      </div>
      {!isLoading && hasAccess && (
        <p className="p-1 pb-2 text-sm text-gray-600">
          Is there any other service you wish to see here?{' '}
          <a href="mailto:support@flowlie.com" className="hyperlink">
            Let us know!
          </a>
        </p>
      )}
    </main>
  );
}

export default PartnerOffersRoute;
