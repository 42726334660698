import React from 'react';
import {
  ArrowsRightLeftIcon,
  HandThumbDownIcon,
  IdentificationIcon,
  InboxArrowDownIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  ViewColumnsIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/24/outline';
import {
  ACTIVE_DEALFLOW_ROUTE,
  INVESTOR_ACCESS_REQUESTS_ROUTE,
  INVESTOR_CONTACTS_ROUTE,
  INVESTOR_PROFILE_ROUTE,
  PASSED_ROUTE,
  PORTFOLIO_ROUTE,
  SCREENING_ROUTE,
} from '/src/constants/Routes';
import {
  NOTIFICATIONS_ACCESS_REQUESTS,
  NOTIFICATIONS_INVESTOR_CONTACTS,
} from '/src/contexts/InvestorRequestsContext';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import CopyTextButton from '../../CopyTextButton';
import { NavigationItem, NotificationIndicator } from './NavigationItems';
import { NavLink } from 'react-router-dom';
import { cn } from '/src/util/cn';

function getInvestorNavigationLinks(
  flowlink: string,
  notifications: Map<string, number>,
): NavigationItem[] {
  return [
    {
      navigateTo: `/app/${AccountTypes.Investor.toLowerCase()}-flowlink`,
      icon: <PaperAirplaneIcon className="app-nav-icon" />,
      content: (
        <>
          <span>Send FlowLink</span>
          <CopyTextButton
            text={flowlink}
            className="ml-auto mr-2 inline-block"
          />
        </>
      ),
    },
    {
      navigateTo: SCREENING_ROUTE,
      icon: <InboxArrowDownIcon className="app-nav-icon" />,
      content: (
        <>
          <span>Screening</span>
        </>
      ),
    },
    {
      navigateTo: ACTIVE_DEALFLOW_ROUTE,
      icon: <ViewColumnsIcon className="app-nav-icon" />,
      content: (
        <>
          <span>Active Dealflow</span>
        </>
      ),
    },
    {
      navigateTo: PORTFOLIO_ROUTE,
      icon: <PresentationChartLineIcon className="app-nav-icon" />,
      content: (
        <>
          <span>Portfolio</span>
        </>
      ),
    },
    {
      navigateTo: PASSED_ROUTE,
      icon: <HandThumbDownIcon className="app-nav-icon" />,
      content: (
        <>
          <span>Passed Deals</span>
        </>
      ),
    },
    {
      navigateTo: INVESTOR_ACCESS_REQUESTS_ROUTE,
      icon: <ArrowsRightLeftIcon className="app-nav-icon" />,
      content: (
        <>
          <div className="flex flex-grow justify-between">
            <span>Access Requests</span>

            {notifications.has(NOTIFICATIONS_ACCESS_REQUESTS) &&
              notifications.get(NOTIFICATIONS_ACCESS_REQUESTS)! > 0 && (
                <NotificationIndicator
                  title="Pending Access Requests"
                  count={notifications.get(NOTIFICATIONS_ACCESS_REQUESTS)}
                />
              )}
          </div>
        </>
      ),
    },
    {
      navigateTo: INVESTOR_CONTACTS_ROUTE,
      icon: <UserGroupIcon className="app-nav-icon" />,
      content: (
        <>
          <div className="flex flex-grow justify-between">
            <span>Investor Contacts</span>
            {notifications.has(NOTIFICATIONS_INVESTOR_CONTACTS) &&
              notifications.get(NOTIFICATIONS_INVESTOR_CONTACTS)! > 0 && (
                <NotificationIndicator
                  title="Pending Contact Requests"
                  count={notifications.get(NOTIFICATIONS_INVESTOR_CONTACTS)}
                />
              )}
          </div>
        </>
      ),
    },
    {
      navigateTo: INVESTOR_PROFILE_ROUTE,
      icon: <IdentificationIcon className="app-nav-icon" />,
      content: (
        <>
          <span>Profile</span>
        </>
      ),
    },
  ];
}

interface InvestorNavigationLinksProps {
  flowlink: string;
  notifications: Map<string, number>;
  closeNav: () => void;
  isMenuExpanded: boolean;
}

export default function InvestorNavigationLinks({
  flowlink,
  notifications,
  closeNav,
  isMenuExpanded,
}: InvestorNavigationLinksProps): JSX.Element {
  return (
    <nav className="flex-0 relative mt-5 space-y-1 px-2">
      {getInvestorNavigationLinks(flowlink, notifications).map((item) => (
        <NavLink
          key={item.navigateTo}
          onClick={closeNav}
          to={item.navigateTo}
          className={({ isActive }) =>
            cn(
              isActive
                ? 'bg-gray-100 text-blue-600 before:absolute before:left-[0.5rem] before:h-4 before:w-1 before:rounded before:bg-blue-500 hover:bg-gray-100 hover:text-blue-600 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
              'flex items-center space-x-2 rounded-md px-2 py-2 text-sm font-medium',
            )
          }
        >
          {item.icon}
          {isMenuExpanded ? item.content : null}
        </NavLink>
      ))}
    </nav>
  );
}
