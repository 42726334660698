import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import {
  PresentationChartLineIcon,
  ShareIcon,
  HandThumbDownIcon,
  ViewColumnsIcon,
} from '@heroicons/react/20/solid';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { DealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import { cn } from '/src/util/cn';
import { roundIsInGracePeriod, isRoundOpen } from '/src/util/rounds';
import React, { Fragment } from 'react';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';

interface DropdownItem {
  icon?: JSX.Element;
  isDisabled: boolean;
  label: string;
  onClick: () => void;
  supportedStatus: DealStatusTypes[];
  tooltip?: string;
}

interface DealActionsDropdownProps {
  deal: DealSummaryView;
  handleMoveToActive: (id: string) => void;
  handlePass: (deal: DealSummaryView) => void;
  handleRecordInvestment: (deal: DealSummaryView) => void;
  handleShare: (deal: DealSummaryView) => void;
}

function DealActionsDropdown({
  deal,
  handleMoveToActive,
  handlePass,
  handleRecordInvestment,
  handleShare,
}: DealActionsDropdownProps): JSX.Element {
  const isCurrentRoundOpen =
    deal.currentRound && isRoundOpen(deal.currentRound);
  const isCurrentRoundInGracePeriod =
    deal.currentRound && roundIsInGracePeriod(deal.currentRound);

  const isCurrentRoundInvestable =
    (isCurrentRoundOpen &&
      deal.currentRound?.roundType !== RoundTypes.ToBeDetermined) ||
    isCurrentRoundInGracePeriod;

  const getRecordInvestmentTooltipText = () => {
    if (!isCurrentRoundOpen) {
      return 'This startup is not raising a round';
    }

    if (!isCurrentRoundInvestable) {
      return 'The founder has not set the terms of the round yet';
    }

    return '';
  };
  const buttonOptions: DropdownItem[] = [
    {
      supportedStatus: [DealStatusTypes.Active],
      label: 'Record Investment',
      icon: (
        <PresentationChartLineIcon
          className={cn(
            isCurrentRoundInvestable ? 'group-hover:text-gray-500' : '',
            'mr-3 h-5 w-5 text-gray-400',
          )}
          aria-hidden="true"
        />
      ),
      onClick: () => handleRecordInvestment(deal),
      isDisabled: !isCurrentRoundInvestable,
      tooltip: getRecordInvestmentTooltipText(),
    },
    {
      supportedStatus: [DealStatusTypes.Screening, DealStatusTypes.Active],
      label: 'Pass',
      icon: (
        <HandThumbDownIcon
          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      ),
      onClick: () => handlePass(deal),
      isDisabled: false,
    },
    {
      supportedStatus: [DealStatusTypes.Screening, DealStatusTypes.Passed],
      label: 'Move to Active',
      icon: (
        <ViewColumnsIcon
          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      ),
      onClick: () => handleMoveToActive(deal.startupId),
      isDisabled: false,
    },
    {
      supportedStatus: [
        DealStatusTypes.Screening,
        DealStatusTypes.Active,
        DealStatusTypes.Passed,
        DealStatusTypes.Portfolio,
      ],
      label: 'Share',
      icon: (
        <ShareIcon
          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      ),
      onClick: () => handleShare(deal),
      isDisabled: false,
    },
  ];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton
        className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
        onClick={(e: any) => e.stopPropagation()}
      >
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </MenuButton>

      <Transition
        as="div"
        transition
        className={`data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:transition data-[leave]:transition data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
      >
        <MenuItems
          anchor="bottom start"
          className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
        >
          <div className="py-1">
            {buttonOptions
              .filter((x) =>
                x.supportedStatus.includes(deal.currentDealStatusType),
              )
              .map((option) => (
                <MenuItem key={option.label} as={Fragment}>
                  {({ focus }) => (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        option.onClick();
                      }}
                      type="button"
                      className={cn(
                        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex w-full items-center px-4 py-2 text-sm',
                      )}
                      disabled={option.isDisabled}
                      title={option.tooltip}
                    >
                      {option.icon}
                      {option.label}
                    </button>
                  )}
                </MenuItem>
              ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}

export default DealActionsDropdown;
