export enum GenAiTransactionTypes {
  FeedbackIcp = 'FeedbackIcp',
  FeedbackCompanyDescription = 'FeedbackCompanyDescription',
  MeetingAnalysis = 'MeetingAnalysis',
}

export function getGenAiTransactionCost(
  transactionType: GenAiTransactionTypes
): number {
  if (
    transactionType === GenAiTransactionTypes.FeedbackCompanyDescription ||
    transactionType === GenAiTransactionTypes.FeedbackIcp
  ) {
    return 1;
  }

  if (transactionType === GenAiTransactionTypes.MeetingAnalysis) {
    return 2;
  }

  return 0;
}
