const ShortcutKeysConst = {
  SHORTCUT_GLOBAL_SEARCH_KEY: 'k',
  SHORTCUT_DEFAULT_SEARCH_KEY: '/',
  SHORTCUT_ESCAPE_KEY: 'Escape',
  SHORTCUT_ENTER_KEY: 'Enter',
  SHORTCUT_ARROW_LEFT_KEY: 'ArrowLeft',
  SHORTCUT_ARROW_RIGHT_KEY: 'ArrowRight',
};

export default ShortcutKeysConst;
