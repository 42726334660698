import React, { useContext, useState } from 'react';
import {
  Route,
  useParams,
  Routes,
  Navigate,
  Link,
  useLocation,
} from 'react-router-dom';

import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import {
  localStorageGetAccountEmail,
  localStorageGetAuthenticatedAccountType,
} from '/src/middleware/LocalStorage';
import { InvestorDetailView } from '/../libs/shared-types/src/types/view/InvestorDetailView';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import TabsHeader, { TabHeader } from '/src/components/tabs/TabsHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import InvestorDetailThesisCard from './cards/InvestorDetailThesisCard';
import InvestorDetailContactCard from './cards/InvestorDetailContactCard';
import InvestorDetailHeader from './InvestorDetailHeader';
import InvestorDetailAboutCard from './cards/InvestorDetailAboutCard';
import InvestorDetailValueAddCard from './cards/InvestorDetailValueAddCard';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import { NOT_FOUND_ROUTE, INVESTOR_PROFILE_ROUTE } from '/src/constants/Routes';
import {
  CurrencyDollarIcon,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import {
  SharedGetInvestorById,
  StartupAddInvestorToLists,
  StartupGetInvestorLists,
  StartupInvestorAccessActivity,
  StartupInvestorPipelineAddInvestors,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import AddInvestorToListsDialog from '../ActiveDealflow/dialogs/AddInvestorToListsDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import InvestorDetailFitScoreCard from './cards/InvestorDetailFitScoreCard';
import InvestorActivityTab from './tabs/InvestorActivityTab';
import { InvestorActivityTabView } from '../../../types/view/InvestorActivityTabView';
import useGetViewSessionByConnection from '/src/hooks/useGetViewSessionByConnection';
import ViewSessionsByConnectionTab from './tabs/ViewSessionsByConnectionTab';
import useGetConnectionNotes from '/src/hooks/useGetConnectionNotes';
import { TextEditorCallBackProps } from '/src/components/TextEditor/TextEditor';
import {
  AddOrUpdateConnectionNote,
  deleteConnectionNoteById,
} from '/src/services/startupService';
import ConnectionNoteTab from '/src/components/ConnectionNotes/ConnectionNoteTab';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import { cn } from '/src/util/cn';
import InvestorDetailMeetingsTab from './tabs/InvestorDetailMeetingsTab';
import TargetListIcon from '/src/components/TargetListIcon';

function InvestorDetailRoute(): JSX.Element {
  const { id } = useParams();
  const [accountType] = useState(localStorageGetAuthenticatedAccountType());
  const [investor, setInvestor] = useState<InvestorDetailView>();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidInvestor, setIsValidInvestor] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();

  const canAccessFitScore = hasFeatureAccess(
    StartupPaidFeatures.InvestorFitScore,
    subscriptionTier,
  );
  const [investorLists, setInvestorLists] = useState<InvestorList[]>([]);
  const [modalConfig, setModalConfig] = useState<
    | {
        type: '' | 'updateListsWithInvestor';
        isModalOpen: boolean;
      }
    | {
        type: 'deleteConnectionNote';
        isModalOpen: boolean;
        connectionNoteId: string;
      }
  >({
    type: '',
    isModalOpen: false,
  });

  const [aggregateInvestorActivity, setAggregateInvestorActivity] =
    useState<InvestorActivityTabView>();
  const [isFirstActivityLoading, setIsFirstActivityLoading] = useState(true);
  const { pathname } = useLocation();
  const connectionDataType = InvestorDataType.Verified;

  const {
    isFirstLoading: isViewSessionByConnectionFirstLoading,
    response: viewSessionByConnectionData,
    totalCount: viewSessionCount,
  } = useGetViewSessionByConnection({
    connectionDataType: InvestorDataType.Verified,
    connectionId: id,
  });

  const {
    isFirstLoading: isConnectionNotesFirstLoading,
    isLoading: isConnectionNotesLoading,
    response: connectionNotesData,
    totalCount: connectionNotesCount,
    refetch: refetchConnectionNotes,
  } = useGetConnectionNotes({
    connectionDataType,
    connectionId: id,
  });

  async function closeModal() {
    setModalConfig({
      type: '',
      isModalOpen: false,
    });
  }

  async function fetchInvestorLists() {
    try {
      const data = await API.get<InvestorList[]>(
        StartupGetInvestorLists.buildEndpoint(),
      );
      setInvestorLists(data);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function updateListsWithInvestor(
    investorId: string,
    investorListIds: string[],
  ) {
    try {
      await API.put(StartupAddInvestorToLists.buildEndpoint(), {
        investorId: investorId,
        investorDataType: InvestorDataType.Verified,
        investorListIds: investorListIds,
      });
      closeModal();
      fetchInvestorLists();
    } catch (error) {
      Logger.error(error);
    }
  }

  async function addInvestorToPipeline(
    investorId: string,
    investorDataType: InvestorDataType,
  ) {
    try {
      await API.put(StartupInvestorPipelineAddInvestors.buildEndpoint(), {
        investors: [
          {
            investorId: investorId,
            investorDataType: investorDataType,
          },
        ],
      });
      setToastConfig({
        isError: false,
        message: 'Investor added to pipeline',
      });
    } catch (error: any) {
      Logger.error(error.message);
      setToastConfig({
        isError: true,
        message: error.message,
      });
    }
  }

  async function fetchData(isRefreshing = false) {
    if (id === undefined) {
      return;
    }

    setIsLoading(!isRefreshing);

    try {
      // if you are an investor who is not connected,
      // this will throw an error and jump to the catch statement
      const investorData = await API.get<InvestorDetailView>(
        SharedGetInvestorById.buildEndpoint(id),
      );
      setInvestor(investorData);
      setIsValidInvestor(true);

      // here we know you are an investor and connected (from above)
      if (accountType === AccountTypes.Investor) {
        setIsConnected(true);
      }

      // if you are a startup, to be "connected", this investor
      // must have access to your deal
      if (accountType === AccountTypes.Startup) {
        fetchInvestorLists();
        setIsConnected(true);
        const investorActivityData: InvestorActivityTabView = await API.get(
          StartupInvestorAccessActivity.buildEndpoint(id, {
            investorType: investorData?.investorDataType,
          }),
        );
        setAggregateInvestorActivity(investorActivityData);
      }
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsFirstActivityLoading(false);
      setIsLoading(false);
      setJoyrideActivationTourState({
        ...joyrideActivationTourState,
        run: true,
      });
    }
  }

  async function handleUpdateOrSaveConnectionNote({
    id: connectionNoteId,
    content,
    title,
  }: TextEditorCallBackProps) {
    // update or create a note
    if (!isConnectionNotesLoading && id) {
      const { isSuccess, data: responseData } = await AddOrUpdateConnectionNote(
        {
          _id: connectionNoteId,
          content,
          title,
          connectionId: id,
          connectionDataType,
        },
      );

      if (isSuccess && responseData) {
        refetchConnectionNotes();
        return responseData;
      }
    }
  }

  async function handleDeleteConnectionNote(connectionNoteId: string) {
    if (!isConnectionNotesLoading) {
      const { isSuccess } = await deleteConnectionNoteById([connectionNoteId]);

      if (isSuccess) {
        refetchConnectionNotes();
        return true;
      }
    }
    return false;
  }

  // we listen for url changes and re-fetch data
  // if we get a new id
  React.useEffect(() => {
    fetchData();
  }, [id]);

  if (
    isFirstActivityLoading ||
    isViewSessionByConnectionFirstLoading ||
    isConnectionNotesFirstLoading
  ) {
    return <PageLoadingSpinner message="Loading a valued investor 🤝" />;
  }

  if (!isValidInvestor) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          {accountType === AccountTypes.Investor && (
            <h3>
              Oops! The investor either doesn&apos;t exist or you&apos;re not
              connected with them.
            </h3>
          )}
          {accountType === AccountTypes.Startup && (
            <h3>Oops! The investor you are looking for doesn&apos;t exist.</h3>
          )}
        </main>
      </div>
    );
  }

  const tabs =
    accountType === AccountTypes.Startup
      ? [
          { name: 'Profile' },
          { name: 'Activity' },
          { name: 'Views', stat: viewSessionCount },
          { name: 'Notes', stat: connectionNotesCount },
          { name: 'Meetings' },
        ]
      : [{ name: 'Profile' }];

  const activityTab = tabs.find((tab) => tab.name === 'Activity');
  const viewsTab = tabs.find((tab) => tab.name === 'Views');
  const notesTab = tabs.find((tab) => tab.name === 'Notes');
  const meetingsTab = tabs.find((tab) => tab.name === 'Meetings');
  const isOnConnectionNotesPage =
    notesTab && pathname.split('/').at(-1) === tabs[3].name.toLowerCase();

  return (
    <div className="min-h-screen bg-gray-100">
      <main
        className={cn(
          'pb-8',
          isOnConnectionNotesPage ? 'flex h-screen flex-col' : '',
        )}
      >
        <header className="sticky top-0 z-40 bg-gray-100 sm:pt-6">
          <div className="relative mx-auto max-w-full lg:flex lg:items-center lg:justify-between lg:space-x-5">
            {investor && (
              <InvestorDetailHeader
                hasFitScoreAccess={canAccessFitScore}
                investor={investor}
                fetchInvestor={fetchData}
              />
            )}
            {investor?.email === localStorageGetAccountEmail() && (
              <Link
                className="app-button--neutral mt-3 lg:mt-0"
                to={INVESTOR_PROFILE_ROUTE}
              >
                <PencilSquareIcon className="mr-2 h-4 w-4" />
                Edit Profile
              </Link>
            )}
            {accountType === AccountTypes.Startup && investor && (
              <div className="flex space-x-3">
                <button
                  className="app-button--neutral mt-3 lg:mt-0"
                  onClick={() =>
                    setModalConfig({
                      type: 'updateListsWithInvestor',
                      isModalOpen: true,
                    })
                  }
                >
                  <TargetListIcon />
                  Add to Target Lists
                </button>
                {investor.pipelineCurrentStage === undefined && (
                  <button
                    className="app-button--primary mt-3 lg:mt-0"
                    onClick={async () => {
                      await addInvestorToPipeline(
                        investor._id,
                        InvestorDataType.Verified,
                      );
                      await fetchData(true);
                    }}
                  >
                    <div className="relative mr-2">
                      <CurrencyDollarIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    Add to Pipeline
                  </button>
                )}
              </div>
            )}
          </div>

          <TabsHeader tabs={tabs} />
        </header>
        <div className={cn('px-1', isOnConnectionNotesPage ? 'flex grow' : '')}>
          <>
            <Routes>
              {investor &&
                ['', tabs[0].name].map((path) => (
                  <Route
                    key={path}
                    path={toDashCase(path)}
                    element={
                      <main className="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2">
                        <div className="flex flex-1 flex-col">
                          <div className="joyride-investorDetailThesis mt-4">
                            <InvestorDetailThesisCard investor={investor} />
                          </div>
                          <div className="mt-4">
                            <InvestorDetailValueAddCard investor={investor} />
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col space-y-4 pt-4">
                          <InvestorDetailFitScoreCard investor={investor} />
                          <InvestorDetailAboutCard investor={investor} />
                          <InvestorDetailContactCard
                            investor={investor}
                            isConnected={isConnected}
                          />
                        </div>
                      </main>
                    }
                  />
                ))}

              {aggregateInvestorActivity && investor?._id && activityTab && (
                <Route
                  path={`${toDashCase(activityTab.name)}/*`}
                  element={
                    <InvestorActivityTab
                      aggregateInvestorActivity={aggregateInvestorActivity}
                      investorId={investor._id}
                      investorName={
                        investor.firstName + ' ' + investor.lastName
                      }
                      investorDataType={investor.investorDataType}
                      fetchInvestorData={fetchData}
                    />
                  }
                />
              )}

              {viewsTab && (
                <Route
                  path={`${toDashCase(viewsTab.name)}/*`}
                  element={
                    <ViewSessionsByConnectionTab
                      apiResponse={viewSessionByConnectionData}
                      connectionEmail={investor?.email}
                    />
                  }
                />
              )}

              {notesTab && (
                <Route
                  path={`${toDashCase(notesTab.name)}/*`}
                  element={
                    <ConnectionNoteTab
                      connectionNotes={connectionNotesData}
                      isLoading={isConnectionNotesLoading}
                      onChange={handleUpdateOrSaveConnectionNote}
                      onDelete={(id: string) =>
                        setModalConfig({
                          connectionNoteId: id,
                          type: 'deleteConnectionNote',
                          isModalOpen: true,
                        })
                      }
                    />
                  }
                />
              )}

              {meetingsTab && investor && (
                <Route
                  path={`${toDashCase(meetingsTab.name)}/*`}
                  element={
                    <InvestorDetailMeetingsTab
                      investorDataType={InvestorDataType.Verified}
                      investorId={investor._id}
                      investorName={investor.name}
                    />
                  }
                />
              )}
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        </div>
      </main>

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => null}>
        {investor && modalConfig.type === 'updateListsWithInvestor' && (
          <AddInvestorToListsDialog
            investorLists={investorLists}
            onSave={updateListsWithInvestor}
            investorId={investor._id}
            investorName={investor.firstName + ' ' + investor.lastName}
            onCancel={closeModal}
          />
        )}

        {modalConfig.type === 'deleteConnectionNote' && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() => {
              handleDeleteConnectionNote(modalConfig.connectionNoteId);
              closeModal();
            }}
            title="Are you sure you want to delete this note?"
            text="This action cannot be undone."
            primaryAction="Delete Note"
            color="red"
          />
        )}
      </ModalWrapper>

      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </div>
  );
}

export default InvestorDetailRoute;
