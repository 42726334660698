import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogBackdrop,
  DialogPanel,
} from '@headlessui/react';
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import {
  BriefcaseIcon,
  MapPinIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';
import { StartupGlobalSearch } from '/../libs/shared-types/src/constants/ApiRoutes';
import { useNavigate } from 'react-router-dom';
import API from '/src/middleware/API';
import { PaginatedCollectionResponse } from '../../../types/view/APIResponse';
import {
  ComparableInvestorFragmentView,
  InvestorFragmentView,
} from '../../../types/view/InvestorFragmentView';
import {
  ComparablePersonView,
  PersonView,
} from '../../../types/view/PersonView';
import { USER_INPUT_DEBOUNCE_DELAY_MS } from '/src/constants/UserInputDebounceDelay';
import { debounce } from '/src/util/debounce';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import { formatImageAddress } from '/src/util/formatting/strings';
import Logger from '/src/services/logger';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { cn } from '/src/util/cn';
import { generateTag } from '/src/util/generateTagCloud';
import SocialHandles, { SocialHandleName } from '/src/components/SocialHandles';
import { getConnectionDetailUrl } from '/src/util/navigation';
import { getConnectionDataType } from '../../../../../libs/shared-types/src/constants/ConnectionDataType';
import { SelectOption } from '../../../types/SelectOptions';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';

interface CommandPaletteGlobalSearchProps {
  isOpen: boolean;
  onClose: () => void;
  setIsCommandPaletteOpen: (isOpen: boolean) => void;
  isDisabled?: boolean;
}

function CommandPaletteGlobalSearch({
  isOpen,
  onClose,
  setIsCommandPaletteOpen,
  isDisabled,
}: CommandPaletteGlobalSearchProps): JSX.Element {
  const [query, setQuery] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [recentResults, setRecentResults] = useState<ActiveOption[]>([]);
  const navigate = useNavigate();

  const [filteredResults, setFilteredResults] = useState<
    { label: string; value: string; details: object }[]
  >([]);
  const debounceDelayMs = USER_INPUT_DEBOUNCE_DELAY_MS;

  type ActiveOption = {
    label: string;
    value: string;
    details: any;
  };

  const fetchGlobalOptions = async (inputValue: string) => {
    const url = StartupGlobalSearch.buildEndpoint(undefined, {
      filter: inputValue,
    });
    try {
      const data =
        await API.get<
          PaginatedCollectionResponse<
            InvestorFragmentView | PersonView,
            ComparableInvestorFragmentView | ComparablePersonView
          >
        >(url);

      const options = data.results.map((item) => ({
        label: item.name,
        value: item._id,
        details: item,
      }));
      return options;
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
    }
  };

  const loadOptionsDebounced = useCallback(
    debounce(
      (inputValue: string, callback: (options: SelectOption[]) => void) => {
        fetchGlobalOptions(inputValue).then((options) =>
          callback(options ? options : []),
        );
      },
      debounceDelayMs,
    ),
    [],
  );

  const handleChangeFilter = (inputValue: string) => {
    setQuery(inputValue);
    loadOptionsDebounced(
      inputValue,
      (options: { label: string; value: string; details: object }[]) => {
        setFilteredResults(options);
      },
    );
  };

  function handleRedirectToInvestorOrConnectionDetails(activeOption: {
    label: string;
    value: string;
    details: any;
  }) {
    setQuery('');
    setFilteredResults([]);
    try {
      const detailUrl = getConnectionDetailUrl(
        getConnectionDataType(activeOption.details),
        activeOption.details._id,
      );

      if (!detailUrl) {
        throw new Error('Detail URL generation failed.');
      }

      navigate(detailUrl);
    } catch (error) {
      Logger.error('Error while redirecting to details:', error);
    } finally {
      setIsCommandPaletteOpen(false);
    }
  }

  return (
    <Dialog
      className="relative z-10"
      open={isOpen}
      onClose={() => {
        onClose();
        setQuery('');
      }}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/25 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <DialogPanel
          transition
          className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <Combobox<ActiveOption> disabled={isDisabled}>
            {({ activeOption }) => (
              <>
                <div className="grid grid-cols-1 justify-center align-middle">
                  <ComboboxInput
                    // Auto focus was triggering lint error. AutoFocus potential problems have been acknowledged (specially for screen reader users), and for this specific case,
                    // I opted to leave it, once it enhances the UX. As a rule of thumb, dialogues envolving search bars have the autoFocus on when triggered.
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    className="col-start-1 row-start-1 h-12 w-full border-none pl-11 pr-4 text-base text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm"
                    placeholder="Search investors and connections"
                    onChange={(event) => handleChangeFilter(event.target.value)}
                  />
                  {isDisabled && (
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5 pt-3">
                      <SubscriptionCTAPill
                        id="cta_global_search_palette"
                        tooltipText="Upgrade to Pro to unlock Search"
                      />
                    </div>
                  )}
                  <MagnifyingGlassIcon
                    className="pointer-events-none col-start-1 row-start-1 ml-4 size-5 self-center text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                {isSearchLoading && <LoadingSpinner color="blue" />}
                <ComboboxOptions
                  as="div"
                  static
                  hold
                  className="flex transform-gpu divide-x divide-gray-100"
                >
                  {((query !== '' && filteredResults.length > 0) ||
                    recentResults.length > 0) && (
                    <div
                      className={cn(
                        'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                      )}
                    >
                      {query === '' && recentResults.length > 0 && (
                        <h2
                          className={cn(
                            'mb-4 mt-2 text-xs font-semibold text-gray-500',
                          )}
                        >
                          Recent searches
                        </h2>
                      )}
                      <div className="-mx-2 text-sm text-gray-700">
                        {(query === '' ? recentResults : filteredResults).map(
                          (option: ActiveOption) => (
                            <ComboboxOption
                              key={option.value}
                              value={option}
                              className="group flex cursor-default select-none items-center rounded-md p-2 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              <UserAvatarWithInitials
                                containerStyles="h-6 w-6"
                                firstName={option.details.firstName}
                                lastName={option.details.lastName}
                                textStyles="text-sm"
                                imgAlt="result"
                                imgSrc={formatImageAddress(
                                  option.details.profilePicKey,
                                )}
                              />

                              <span className="ml-3 flex-auto truncate">
                                {option.details.name}
                              </span>
                              <span className="ml-1.5">
                                {generateTag(option.details.type, true)}
                              </span>
                              <ChevronRightIcon
                                className="ml-3 h-5 w-5 flex-none text-gray-400"
                                aria-hidden="true"
                              />
                            </ComboboxOption>
                          ),
                        )}
                      </div>
                    </div>
                  )}

                  {activeOption && (
                    <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                      <div className="flex-none p-6 text-center">
                        <UserAvatarWithInitials
                          containerStyles="size-16 mx-auto"
                          firstName={activeOption.details.firstName}
                          lastName={activeOption.details.lastName}
                          textStyles="text-2xl"
                          imgAlt="ActiveOption"
                          imgSrc={formatImageAddress(
                            activeOption.details.profilePicKey,
                          )}
                        />
                        <h2 className="mt-3 flex items-center justify-center font-semibold text-gray-900">
                          <span>{activeOption.details.name}</span>
                          {activeOption.details.linkedIn && (
                            <span className="ml-2">
                              <SocialHandles
                                socialUrls={[
                                  {
                                    name: SocialHandleName.LinkedIn,
                                    url: activeOption.details.linkedIn,
                                  },
                                ]}
                              />
                            </span>
                          )}
                        </h2>

                        <div className="flex items-center justify-center">
                          <BriefcaseIcon className="h-5 w-5 text-gray-600" />
                          <p className="truncate pl-1 text-sm font-medium text-gray-700">
                            {activeOption.details.role &&
                            activeOption.details.firm
                              ? `${activeOption.details.role} @ ${activeOption.details.firm}`
                              : 'Not Informed'}
                          </p>
                        </div>
                        {activeOption.details.location && (
                          <div className="flex items-center justify-center">
                            <MapPinIcon className="h-5 w-5 text-gray-600" />
                            <p className="truncate pl-1 text-sm font-medium text-gray-600">
                              {activeOption.details.location}
                            </p>
                          </div>
                        )}
                        <p></p>
                      </div>
                      <div className="flex flex-auto flex-col justify-between p-6">
                        <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                          <dt className="col-end-1 font-semibold text-gray-900">
                            Email
                          </dt>
                          {activeOption.details.email ? (
                            <dd className="truncate">
                              <a
                                href={`mailto:${activeOption.details.email}`}
                                className="text-blue-600 underline"
                              >
                                {activeOption.details.email}
                              </a>
                            </dd>
                          ) : (
                            '-'
                          )}
                          <dt className="col-end-1 font-semibold text-gray-900">
                            Website
                          </dt>
                          <dd className="truncate">
                            {activeOption.details.website ? (
                              <a
                                href={activeOption.details.website}
                                className="hyperlink"
                                target='_blank'
                                rel="noreferrer"
                              >
                                {activeOption.details.website}
                              </a>
                            ) : (
                              '-'
                            )}
                          </dd>
                        </dl>
                        <button
                          type="button"
                          className="mt-6 w-full rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          onClick={() => {
                            handleRedirectToInvestorOrConnectionDetails(
                              activeOption,
                            );
                            setRecentResults((prev) => {
                              const exists = prev.some(
                                (item) => item.value === activeOption.value,
                              );
                              return exists ? prev : [activeOption, ...prev];
                            });
                          }}
                        >
                          See Profile
                        </button>
                      </div>
                    </div>
                  )}
                </ComboboxOptions>

                {query !== '' && filteredResults?.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <UsersIcon
                      className="mx-auto size-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-gray-900">
                      No results found
                    </p>
                    <p className="mt-2 text-gray-500">
                      We could not find any people matching your search. Please
                      try again.
                    </p>
                  </div>
                )}
              </>
            )}
          </Combobox>
        </DialogPanel>
      </div>
    </Dialog>
  );
}

export default CommandPaletteGlobalSearch;
