import API from '../middleware/API';
import { getUserIpAddress } from './GetUserIpAddress';
import Logger from './logger';
import {
  PublicCreateNewUnconfirmedUser,
  PublicGetUnconfirmedUser,
  PublicUpdateUnconfirmedUser,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { UnconfirmedUser } from '/../libs/shared-types/src/types/model/UnconfirmedUser';

function buildUnconfirmedUser(
  email: string,
  firstName: string,
  lastName: string,
  token?: string,
  unconfirmedAccountType?: string,
  discoveredFrom?: string,
  message?: string,
) {
  // This should have type UnconfirmedUser
  // But type issues with Enums in local storage...
  const unconfirmedUser = {
    email,
    firstName,
    lastName,
    token,
    unconfirmedAccountType,
    discoveredFrom,
    message,
  };

  if (!token) {
    unconfirmedUser.discoveredFrom = undefined;
    unconfirmedUser.message = undefined;
  }

  return unconfirmedUser;
}

export async function createUnconfirmedUser(
  email: string,
  firstName: string,
  lastName: string,
  token?: string,
  unconfirmedAccountType?: string,
  discoveredFrom?: string,
  message?: string,
) {
  const unconfirmedUser = buildUnconfirmedUser(
    email,
    firstName,
    lastName,
    token,
    unconfirmedAccountType,
    discoveredFrom,
    message,
  );
  const ipAddress = await getUserIpAddress();

  return await API.post<UnconfirmedUser>(
    PublicCreateNewUnconfirmedUser.buildEndpoint(),
    { ...unconfirmedUser, ipAddress },
    false,
  );
}

export const getUnconfirmedUserByEmail = async (
  email: string,
): Promise<UnconfirmedUser | undefined> => {
  try {
    return await API.get<UnconfirmedUser>(
      PublicGetUnconfirmedUser.buildEndpoint(email),
    );
  } catch (error: any) {
    Logger.error(error);
    return undefined;
  }
};

export async function updateUnconfirmedUser(
  email: string,
  token?: string,
  unconfirmedAccountType?: string,
  discoveredFrom?: string,
  message?: string,
) {
  // Remember: Changes to firstName, lastName and email will be ignored
  const unconfirmedUser = buildUnconfirmedUser(
    email,
    '',
    '',
    token,
    unconfirmedAccountType,
    discoveredFrom,
    message,
  );

  return API.put<UnconfirmedUser>(
    PublicUpdateUnconfirmedUser.buildEndpoint(),
    unconfirmedUser,
  );
}
