import React from 'react';
import { ArrowLongUpIcon, QueueListIcon } from '@heroicons/react/20/solid';
import { cn } from '../util/cn';

interface TargetListIconProps {
  containerClassName?: string;
  queueListIconClassName?: string;
  arrowLongUpIconClassName?: string;
}

const TargetListIcon: React.FC<TargetListIconProps> = ({
  containerClassName,
  queueListIconClassName,
  arrowLongUpIconClassName,
}) => {
  const baseContainerClassName = 'relative mr-2';
  const baseQueueListIconClassName = 'h-5 w-5';
  const baseArrowLongUpIconClassName =
    'absolute -bottom-0.5 -right-1 h-3.5 w-3.5 bg-white';
  return (
    <div className={cn(baseContainerClassName, containerClassName)}>
      <QueueListIcon
        className={cn(baseQueueListIconClassName, queueListIconClassName)}
        aria-hidden="true"
      />
      <ArrowLongUpIcon
        className={cn(baseArrowLongUpIconClassName, arrowLongUpIconClassName)}
        aria-hidden="true"
      />
    </div>
  );
};

export default TargetListIcon;
