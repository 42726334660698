import React, { Fragment, useState } from 'react';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { cn } from '/src/util/cn';

interface SelectStageProps {
  label: string;
  items: string[];
  initialValue: string;
  onSelectionChange: (x: any) => void;
}

export default function DropdownSelect({
  label,
  items,
  initialValue,
  onSelectionChange,
}: SelectStageProps): JSX.Element {
  const [selectedItem, setSelectedItem] = useState(initialValue);

  const handleSelectionChange = (value: string) => {
    onSelectionChange(value);
    setSelectedItem(value);
  };

  return (
    <Listbox value={selectedItem} onChange={handleSelectionChange}>
      {({ open }) => (
        <div className="flex items-center space-x-2">
          <Label className="text-base font-medium text-gray-500 sm:text-sm">
            {label}:
          </Label>
          <div className="relative w-44">
            <ListboxButton className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
              <span className="block truncate">{selectedItem}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as="div"
              transition
              className={`data-[closed]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in`}
            >
              <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {items.map((item: string) => (
                  <ListboxOption
                    key={item}
                    className={({ focus }) =>
                      cn(
                        focus ? 'bg-blue-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={item}
                  >
                    {({ selected, focus }) => (
                      <>
                        <span
                          className={cn(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {item}
                        </span>

                        {selected ? (
                          <span
                            className={cn(
                              focus ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
