import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import {
  ActiveDealSummaryView,
  DealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import React, { Fragment, useState } from 'react';
import ActiveDeal from './ActiveDeal';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronUpIcon, ClockIcon } from '@heroicons/react/20/solid';
import { cn } from '/src/util/cn';
import { getColorByIndex } from '/src/util/colorLookup';
import { getDealCountByStage } from './util';
import { formatMsToDays } from '/src/util/formatting/dates';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableHeader } from '/src/interfaces/TableHeader';
import { SortOrder } from '/src/interfaces/Sortable';
import SortIcon from '/src/components/utility/SortIcon';
import { ComparableAggregatedActiveDeal } from '/src/interfaces/Comparable';
import { StarRating } from '/../libs/shared-types/src/constants/StarRating';
import { Priority } from '/../libs/shared-types/src/constants/Priority';

interface ActiveDealsGroupedTableProps {
  activeDeals: ActiveDealSummaryView[];
  boardStages: Stage[];
  handlePass: (deal: DealSummaryView) => void;
  handleRecordInvestment: (deal: DealSummaryView) => void;
  handleShare: (deal: DealSummaryView) => void;
  handleUpdateDealPriority: (
    deal: ActiveDealSummaryView,
    newRating: Priority,
  ) => void;
  handleUpdateDealRating: (
    deal: ActiveDealSummaryView,
    newRating: StarRating,
  ) => void;
  headers: TableHeader<ComparableAggregatedActiveDeal>[];
  isSearching: boolean;
  onSort?: (key: string, order: SortOrder) => void;
  selectedStage: Stage | undefined;
  stageDealCountMap: Map<string, number>;
}

// TODO: Refactor this component to use the TableWithStages.tsx component
function ActiveDealsGroupedTable({
  activeDeals,
  boardStages,
  handlePass,
  handleRecordInvestment,
  handleShare,
  handleUpdateDealPriority,
  handleUpdateDealRating,
  headers,
  isSearching,
  onSort,
  selectedStage,
  stageDealCountMap,
}: ActiveDealsGroupedTableProps): JSX.Element {
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = React.useCallback(
    (deal: DealSummaryView) => {
      navigate(`/app/deal-detail/${deal.startupId}`);
    },
    [location],
  );

  const handleSort = (sortKey: string) => {
    if (!onSort) {
      return;
    }

    onSort(sortKey, sortOrder * -1);
    setSortedColumn(sortKey);
    setSortOrder((order) => order * -1);
  };

  const emptyContent = boardStages.every(
    (stage) =>
      activeDeals.filter(
        (x) =>
          x.currentActiveBoardStage?.name.toUpperCase() ===
          stage.name.toUpperCase(),
      ).length === 0,
  ) ? (
    <tr>
      <td
        colSpan={headers.length}
        className="w-100 text-center text-sm text-gray-600"
      >
        <p className="my-4">No results found</p>
      </td>
    </tr>
  ) : null;

  return (
    <div className="mt-4 flex flex-col px-0.5">
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="border-b border-gray-200 bg-gray-50 p-0.5 shadow md:rounded-lg">
            <table className="min-w-full table-fixed select-none divide-y divide-gray-200">
              <thead className="w-full rounded-lg bg-gray-50">
                <tr className="sticky top-24 z-20 bg-gray-50 sm:top-32">
                  {headers.map((header) => (
                    <th
                      scope="col"
                      key={headers.indexOf(header)}
                      onClick={() =>
                        header.sortKey ? handleSort(header.sortKey) : null
                      }
                      className={cn(
                        'app-table-header-item group',
                        header.width ?? '',
                        header.sortKey ? 'cursor-pointer' : '',
                      )}
                    >
                      <div
                        className={cn(
                          header.sortKey && sortedColumn === header.sortKey
                            ? 'font-bold text-gray-700'
                            : '',
                          'relative inline-flex items-center',
                        )}
                      >
                        {header.element}
                        <span className="absolute -right-6 bottom-0">
                          {sortedColumn !== header.sortKey && (
                            <span
                              className={cn(
                                'ml-1 hidden rounded bg-gray-200 text-gray-500 group-hover:block',
                                header.sortKey ? 'group-hover:opacity-100' : '',
                              )}
                            >
                              <SortIcon
                                order={
                                  sortOrder === SortOrder.Asc
                                    ? SortOrder.Desc
                                    : SortOrder.Asc
                                }
                              />
                            </span>
                          )}
                          {header.sortKey &&
                            sortedColumn === header.sortKey && (
                              <span className="ml-1 rounded bg-gray-200 text-gray-700 group-hover:block">
                                <SortIcon order={sortOrder} />
                              </span>
                            )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {boardStages
                  .filter(
                    (stage) =>
                      stage._id === selectedStage?._id || !selectedStage,
                  )
                  .map((stage) => (
                    <Disclosure
                      defaultOpen={
                        selectedStage !== undefined ||
                        getDealCountByStage(activeDeals, stage.name) > 0
                      }
                      key={stage._id}
                    >
                      {({ open }) => (
                        <>
                          <tr>
                            <th
                              className="sticky top-32 z-10 sm:top-40"
                              colSpan={headers.length}
                              scope="colgroup"
                            >
                              <DisclosureButton
                                className={cn(
                                  `${
                                    getColorByIndex(
                                      boardStages.findIndex(
                                        (x) => x._id === stage._id,
                                      ),
                                      boardStages.length,
                                    ).borderColor
                                  }`,
                                  'flex w-full items-center rounded-sm border-l-8 bg-gray-50 py-2 text-left text-gray-800',
                                )}
                              >
                                <ChevronUpIcon
                                  className={`${
                                    !open ? 'rotate-180 transform' : ''
                                  } mx-2 h-6 w-6 transition`}
                                />
                                <span className="text-xs font-semibold">
                                  {stage.name}
                                </span>
                                <span
                                  className={cn(
                                    getColorByIndex(
                                      boardStages.findIndex(
                                        (x) => x._id === stage._id,
                                      ),
                                      boardStages.length,
                                    ).bgColor,
                                    getColorByIndex(
                                      boardStages.findIndex(
                                        (x) => x._id === stage._id,
                                      ),
                                      boardStages.length,
                                    ).textColor,
                                    'ml-2 rounded-full px-1.5 text-2xs font-medium',
                                  )}
                                >
                                  {getDealCountByStage(activeDeals, stage.name)}
                                  {/* {isSearching && (
                                    // NOTE: This is broken, because the stageDealCountMap only contains the filtered deals
                                    //
                                    <>/{stageDealCountMap.get(stage._id)}</>
                                  )} */}
                                </span>
                                {stage.duration && (
                                  <div
                                    className="ml-4 inline-flex items-center text-xs"
                                    title="Stage duration"
                                  >
                                    <ClockIcon
                                      className="mr-1 h-3 w-3"
                                      aria-hidden="true"
                                    />
                                    <span>
                                      {formatMsToDays(stage.duration)}
                                    </span>
                                  </div>
                                )}
                              </DisclosureButton>
                            </th>
                          </tr>
                          {getDealCountByStage(activeDeals, stage.name) === 0 &&
                            !emptyContent && (
                              <tr>
                                <td
                                  colSpan={headers.length}
                                  className="w-100 text-center text-sm text-gray-600"
                                >
                                  <DisclosurePanel>
                                    <p className="my-4">
                                      No deals at this stage
                                    </p>
                                  </DisclosurePanel>
                                </td>
                              </tr>
                            )}
                          {activeDeals
                            .filter(
                              (x) =>
                                x.currentActiveBoardStage?.name.toUpperCase() ===
                                stage.name.toUpperCase(),
                            )
                            .map((deal) => (
                              <DisclosurePanel
                                as="tr"
                                key={deal.startupId}
                                className="cursor-pointer transition-transform duration-200 hover:bg-gray-100"
                                onClick={() => handleOnClick(deal)}
                              >
                                <ActiveDeal
                                  key={deal.startupId}
                                  deal={deal}
                                  onShareDeal={handleShare}
                                  onPassDeal={handlePass}
                                  onRecordInvestment={handleRecordInvestment}
                                  handleUpdateDealPriority={
                                    handleUpdateDealPriority
                                  }
                                  handleUpdateDealRating={
                                    handleUpdateDealRating
                                  }
                                />
                              </DisclosurePanel>
                            ))}
                        </>
                      )}
                    </Disclosure>
                  ))}
                {emptyContent}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveDealsGroupedTable;
