import React from 'react';
import { FieldProps, FieldInputProps } from 'formik';

import { formatCurrentDate } from '/src/util/formatting/dates';
import Tooltip from '/src/components/utility/Tooltip';
import FormikDebugger from './FormikDebugger';
import FormikErrorMessage from './FormikErrorMessage';
import { cn } from '/src/util/cn';

function InputPrefix({ prefixValue }: InputPrefixProps): JSX.Element {
  return (
    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
      <span className="text-gray-500 sm:text-sm">{prefixValue}</span>
    </div>
  );
}

interface InputPrefixProps {
  prefixValue: string;
}

interface PasswordVisibilityToggleProps {
  passwordShown: boolean;
  togglePasswordShown: () => void;
}

function PasswordVisibilityToggle({
  passwordShown,
  togglePasswordShown,
}: PasswordVisibilityToggleProps): JSX.Element {
  return (
    <div className="w-100 flex flex-col">
      <span
        onClick={togglePasswordShown}
        tabIndex={0}
        onKeyDown={togglePasswordShown}
        role="button"
        className="cursor-pointer self-end justify-self-end text-xs"
      >
        {passwordShown ? 'Hide Password' : 'Show Password'}
      </span>
    </div>
  );
}

function getInputComponent(
  customStyle: string | undefined,
  field: FieldInputProps<any>,
  isDisabled = false,
  max: string | undefined,
  placeholder: string | undefined,
  type: 'date' | 'email' | 'text' | 'url' | 'password' | 'number',
  as = 'input',
  prefix: string | undefined,
) {
  let classNames = customStyle || '';
  // If there's prefix, InputPrefix adds pl-3 to the prefix, which means we must
  // add pl-7 to the input else inputted value will overlap with the prefix symbols.
  if (prefix) {
    classNames = `pl-7 ${classNames}`;
  }

  switch (as) {
    case 'textarea':
      return (
        <textarea
          className={cn('input', classNames)}
          placeholder={placeholder}
          disabled={isDisabled}
          {...field}
          value={field.value ?? ''}
        />
      );
    case 'input':
      return (
        <input
          type={type}
          className={cn('input', classNames)}
          placeholder={placeholder}
          disabled={isDisabled}
          max={max}
          {...field}
          value={field.value ?? ''}
        />
      );
    default:
      return <></>;
  }
}

interface InputFieldProps extends FieldProps {
  boxType?: 'textarea' | 'input';
  customStyle?: string;
  disabled?: boolean;
  label: string;
  max?: string;
  name: string;
  placeholder?: string;
  prefix?: '$' | '%' | 'https://';
  secondaryLabel?: string;
  tooltip?: string;
  type: 'date' | 'email' | 'text' | 'url' | 'password' | 'number';
}

function FormikInput({
  boxType,
  customStyle,
  disabled,
  field,
  form,
  label,
  max,
  meta, // this prop actually does not seem to work for Formik custom components
  placeholder,
  prefix,
  secondaryLabel,
  tooltip,
  type,
}: InputFieldProps): JSX.Element {
  const [passwordShown, setPasswordShown] = React.useState(false);
  const fieldType = passwordShown && type === 'password' ? 'text' : type;

  // If the input has a prefix the label style must contain relative
  const labelStyle = `input-label ${prefix !== undefined ? 'relative' : ''}`;

  const getMaxValue = () => {
    if (max) {
      if (fieldType === 'date') {
        return formatCurrentDate();
      }
      return max;
    }
    return undefined;
  };

  const togglePasswordShown = () => setPasswordShown(!passwordShown);

  return (
    <section className="form-section">
      <label htmlFor={field.name} className={labelStyle}>
        <span className="flex flex-row items-center">
          {label}
          {tooltip && <Tooltip tooltipText={tooltip} width="w-72" />}
        </span>
        {secondaryLabel && (
          <p className="text-xs text-gray-800">{secondaryLabel}</p>
        )}
        <div className="relative rounded-md shadow-sm">
          {prefix && <InputPrefix prefixValue={prefix} />}
          {getInputComponent(
            customStyle,
            field,
            disabled,
            getMaxValue(),
            placeholder,
            passwordShown ? 'text' : type,
            boxType,
            prefix,
          )}
        </div>
      </label>
      <FormikErrorMessage field={field} form={form} meta={meta} />

      {type === 'password' && (
        <PasswordVisibilityToggle
          passwordShown={passwordShown}
          togglePasswordShown={togglePasswordShown}
        />
      )}
      <FormikDebugger field={field} form={form} meta={meta} />
    </section>
  );
}

export default FormikInput;
