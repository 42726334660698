import React from 'react';
import TailwindColors from '../../constants/TailwindColors';
import { cn } from '/src/util/cn';

export type ColorOptions = Extract<TailwindColors, 'blue' | 'white' | 'slate'>;
export type SizeOptions = 5 | 6 | 7 | 8 | 10 | 14;

const ColorClasses = new Map<ColorOptions, string>([
  ['blue', 'text-blue-600'],
  ['slate', 'text-slate-600'],
  ['white', 'text-white'],
]);

const SizeClasses = new Map<SizeOptions, string>([
  [5, 'w-5 h-5'],
  [6, 'w-6 h-6'],
  [7, 'w-7 h-7'],
  [8, 'w-8 h-8'],
  [10, 'w-10 h-10'],
  [14, 'w-14 h-14'],
]);

interface LoadingSpinnerProps {
  color: ColorOptions;
  size?: SizeOptions;
  className?: string;
}

function LoadingSpinner({
  color,
  className = '',
  size = 5,
}: LoadingSpinnerProps): JSX.Element {
  return (
    <div className={cn('mx-2', className)}>
      <svg
        className={cn(
          'animate-spin',
          SizeClasses.get(size) ?? '',
          ColorClasses.get(color) ?? '',
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
}

export default LoadingSpinner;
