import { FormikHelpers } from 'formik';
import React, { Fragment, useContext, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { FormikStep, FormikStepper } from '../multiStepForm/FormikStepper';
import {
  heapIdentify,
  updateHeapInvestorUser,
  updateHeapUserSubscriptionTier,
} from '/src/middleware/Heap';
import { Investor } from '/../libs/shared-types/src/types/model/Investor';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import {
  localStorageGetAccountEmail,
  localStorageSetAccountFirstLastName,
  localStorageSetAccountTypeAndAuthenticationToken,
} from '/src/middleware/LocalStorage';
import { UNCONFIRMED_USER_NOT_FOUND } from '/src/constants/ErrorMessages';
import { SharedStateContext } from '../../contexts/SharedStateContext';
import API from '/src/middleware/API';
import emptyInvestor from './EmptyInvestor';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import Logger from '/src/services/logger';
import { removeUnsetOptionalValues } from '/src/util/forms';
import steps from './MultiStepFormSteps';
import CompleteProfileSuccess from './CompleteProfileSuccess';
import { formatImageAddress } from '/src/util/formatting/strings';
import { cleanInvestorData } from './InvestorFormValuesCleaner';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { getUnconfirmedUserByEmail } from '/src/services/UnconfirmedUser';
import { validateToken } from '/src/services/ValidateToken';
import UserAvatarWithInitials from '../UserAvatarWithInitials';
import { fetchAndCreateImageFile, uploadFile } from '/src/util/FileHelpers';
import { getLinkShareMetadata } from '/src/services/LinkShareMetadata';
import { parseFlowlieAuthToken } from '/src/services/jwt';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { Invitation } from '/../libs/shared-types/src/types/model/UnconfirmedUser';
import { NewAccountView } from '/../libs/shared-types/src/types/view/NewAccountView';
import { Posthog } from '/src/middleware/Posthog';
import { getOnboardingFlow } from '/../libs/shared-types/src/extensions/OnboardingFlowsExtensions';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { PUBLIC_NOT_FOUND_ROUTE } from '/src/constants/Routes';
import {
  CognitoCreateNewInvestor,
  CognitoUploadPublic,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import Alert from '../notifications/Alert';
import SquaredLogo from '../SquaredLogo';
import { getUserIpAddress } from '/src/services/GetUserIpAddress';

const InfoHeader = () => {
  const [textContent, setTextContent] = useState('');
  const [invitedByImages, setInvitedByImages] = useState<
    {
      firstName: string;
      lastName: string;
      imageKey: string;
      accountType: AccountTypes;
    }[]
  >([]);
  const { invitation } = useContext(AuthenticationContext);

  const fetchLinkData = async () => {
    if (!invitation) {
      setTextContent('Complete to get full access to Flowlie');
      return;
    }
    const user = await getLinkShareMetadata(
      invitation.shareToken,
      invitation.linkType,
    );
    if (!user) {
      return;
    }

    if (invitation.linkType === LinkTypes.Flowlink && user.investor) {
      setTextContent(
        `Complete to connect with ${user.investor.firstName} ${
          user.investor.lastName
        } (${
          user.investor.type === InvestorTypes.Angel
            ? 'Angel'
            : user.investor.firm
        })`,
      );
      setInvitedByImages([
        {
          firstName: user.investor.firstName,
          lastName: user.investor.lastName,
          imageKey: user.investor.profilePicKey ?? '',
          accountType: AccountTypes.Investor,
        },
      ]);
    } else if (invitation.linkType === LinkTypes.Flowlink && user.startup) {
      setTextContent(`Complete to view ${user.startup.name}`);
      setInvitedByImages([
        {
          firstName: user.startup.accountFirstName,
          lastName: user.startup.accountFirstName,
          imageKey: user.startup.logoKey,
          accountType: AccountTypes.Startup,
        },
      ]);
    } else if (
      invitation.linkType === LinkTypes.Dealshare &&
      user.startup &&
      user.investor
    ) {
      setTextContent(
        `${user.investor.firstName} is inviting you to view ${user.startup.name}`,
      );
      setInvitedByImages([
        {
          firstName: user.investor.firstName,
          lastName: user.investor.lastName,
          imageKey: user.investor.profilePicKey ?? '',
          accountType: AccountTypes.Investor,
        },
        {
          firstName: user.startup.accountFirstName,
          lastName: user.startup.accountFirstName,
          imageKey: user.startup.logoKey,
          accountType: AccountTypes.Startup,
        },
      ]);
    }
  };

  React.useEffect(() => {
    fetchLinkData();
  }, []);

  return (
    <div className="mb-10">
      <div className="flex flex-row items-center justify-center">
        {invitedByImages.map((image, index) => (
          <div key={image.imageKey + index}>
            {image.accountType === AccountTypes.Investor && (
              <UserAvatarWithInitials
                containerStyles="h-16 w-16 my-1 mx-2"
                firstName={image.firstName}
                lastName={image.lastName}
                textStyles="text-2xl"
                imgAlt={`Invited by ${image.firstName}}`}
                imgSrc={formatImageAddress(image.imageKey)}
              />
            )}
            {image.accountType === AccountTypes.Startup && (
              <SquaredLogo
                containerClassName="size-16 my-1 mx-2"
                alt={`Invited by ${image.firstName}}`}
                src={formatImageAddress(image.imageKey)}
              />
            )}
          </div>
        ))}
      </div>
      {textContent && (
        <p className="-mt-3 rounded-full bg-white px-6 py-3 text-sm tracking-wide text-gray-500 shadow-sm">
          {textContent}
        </p>
      )}
    </div>
  );
};

function InvestorMultiStepForm(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const { setToastConfiguration } = useContext(SharedStateContext);
  const { setInvitation } = useContext(AuthenticationContext);
  const [consumedInvitationData, setConsumedInvitationData] =
    useState<Invitation>();
  const navigate = useNavigate();

  const { setCachedPage } = useContext(SharedStateContext);

  async function createNewInvestor(formValues: any) {
    const accountEmail = localStorageGetAccountEmail();
    const unconfirmedUser = await getUnconfirmedUserByEmail(accountEmail);

    if (!unconfirmedUser) {
      throw Error(UNCONFIRMED_USER_NOT_FOUND);
    }

    const updatedData = removeUnsetOptionalValues(formValues);

    const investorProfile: Investor = {
      ...updatedData,
      firstName: unconfirmedUser.firstName,
      lastName: unconfirmedUser.lastName,
      email: unconfirmedUser.email,
    };

    // Check if there is a picture from Google Account
    const googleAccountImageUrl = localStorage.getItem(
      LocalStorageKeysConst.GOOGLE_ACCOUNT_PICTURE,
    );
    localStorage.removeItem(LocalStorageKeysConst.GOOGLE_ACCOUNT_PICTURE);
    if (googleAccountImageUrl) {
      const imageFile = await fetchAndCreateImageFile(
        googleAccountImageUrl,
        'profilePic',
      );
      const imageKey = await uploadFile(
        imageFile,
        CognitoUploadPublic.buildEndpoint(),
      );
      if (imageKey) {
        investorProfile[InvestorFieldNames.ProfilePicKey] = imageKey;
      }
      Logger.warn('uploaded google image url');
    }

    // remove nulls from the above call to remove options values
    Object.keys(investorProfile).forEach(
      (key) =>
        investorProfile[key as keyof Investor] === null &&
        delete investorProfile[key as keyof Investor],
    );

    localStorageSetAccountFirstLastName(
      investorProfile.firstName,
      investorProfile.lastName,
    );

    const newAccountResult = await API.post<NewAccountView<Investor>>(
      CognitoCreateNewInvestor.buildEndpoint(),
      { investorProfile },
    );
    Logger.warn('posted new account data');
    if (!newAccountResult || !newAccountResult.flowlieAuthenticationToken) {
      throw Error('Investor profile could not be created');
    }

    const posthogClient = new Posthog();
    posthogClient.addUserProperties({
      invited_by_name: newAccountResult.invitation?.fullName,
      company_name: newAccountResult.profile.firm,
      mongoid_onboarded: newAccountResult.profile._id,
      mongoid_unconfirmed: unconfirmedUser._id,
      type: AccountTypes.Investor,
      sub_type: Posthog.getInvestorSubType(newAccountResult.profile.type),
      onboarding_flow: getOnboardingFlow(
        AccountTypes.Investor,
        newAccountResult.invitation?.accountType,
        newAccountResult.invitation?.linkType,
      ),
    });

    setConsumedInvitationData(newAccountResult.invitation);
    setInvitation(undefined);
    localStorageSetAccountTypeAndAuthenticationToken(
      AccountTypes.Investor,
      newAccountResult.flowlieAuthenticationToken,
    );

    if (newAccountResult.linkShareOutcome) {
      setToastConfiguration({
        message: newAccountResult.linkShareOutcome.message,
        isError: newAccountResult.linkShareOutcome.error,
      });
    }

    const parsedToken = parseFlowlieAuthToken(
      newAccountResult.flowlieAuthenticationToken,
    );
    heapIdentify(parsedToken.userInfo._id);
    updateHeapInvestorUser(investorProfile);
    updateHeapUserSubscriptionTier(SubscriptionTiers.InvestorFree);

    if (!newAccountResult.invitation) {
      return;
    }

    const { shareToken, linkType } = newAccountResult.invitation;

    if (
      newAccountResult.invitation.accountType === AccountTypes.Startup &&
      linkType === LinkTypes.Flowlink
    ) {
      try {
        const response = await validateToken(shareToken);
        if (response && response.originatorId) {
          setCachedPage({
            url: `/app/deal-detail/${response.originatorId}`,
            accountType: null,
          });
        }
      } catch (error) {
        Logger.error(error);
      }
    } else if (linkType === LinkTypes.Dealshare) {
      setCachedPage({
        url: `/app/access-requests/requests-made`,
        accountType: null,
      });
    }
  }

  async function onSubmit(
    values: any,
    { setSubmitting }: FormikHelpers<any>,
  ): Promise<void> {
    try {
      setSubmitting(true);
      // YOU MUST CHECK IF THE DATA BEING SUBMITTED IS VALID
      // For example, certain fields are mutually exclusive
      const cleanValues = cleanInvestorData(values);
      await createNewInvestor(cleanValues);
      navigate('completed');
    } catch (formError: any) {
      setErrorMessage(formError.message);
      setIsError(true);
      Logger.error(formError);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Routes>
      <Route
        path=""
        element={
          <div className="flex min-h-screen flex-col items-center justify-center sm:bg-gray-100">
            <InfoHeader />
            <main className="min-w-min max-w-4xl rounded-lg bg-white px-3 py-12 sm:p-12 sm:shadow lg:w-2/3 xl:w-1/2">
              <FormikStepper
                initialValues={{
                  ...emptyInvestor,
                }}
                enableReinitialize
                onSubmit={onSubmit}
              >
                {steps.map((step) => (
                  <FormikStep
                    key={step.label}
                    label={step.label}
                    isOptional={step.isOptional}
                    validationSchema={step.fields
                      .map((field) => field.validation)
                      .reduce((merged, schema) => merged.concat(schema))}
                    customLayout={step.customLayout ?? ''}
                  >
                    {step.fields.map((field, index) => (
                      <Fragment key={index}>{field.fieldComponent}</Fragment>
                    ))}
                  </FormikStep>
                ))}
              </FormikStepper>
              {isError && (
                <section className="mt-8">
                  <Alert
                    alertType={'Warning'}
                    color={'yellow'}
                    content={
                      <div>
                        {
                          "Oops! There was an issue submitting your data. Let's retry... please click the 'Complete' button again."
                        }
                        <pre className="-mb-1 mt-2 text-2xs">
                          Error: {errorMessage}
                        </pre>
                      </div>
                    }
                    isShown={isError}
                    canDismiss={false}
                  />
                </section>
              )}
            </main>
          </div>
        }
      />

      <Route
        path="completed"
        element={
          <div className="flex min-h-screen items-center justify-center sm:bg-gray-100">
            <main className="m-auto min-w-min max-w-4xl rounded-lg bg-white px-3 py-12 sm:p-12 sm:shadow lg:w-2/3 xl:w-1/2">
              <CompleteProfileSuccess
                shareToken={consumedInvitationData?.shareToken}
                linkType={consumedInvitationData?.linkType}
              />
            </main>
          </div>
        }
      />
      <Route
        path="*"
        element={<Navigate to={PUBLIC_NOT_FOUND_ROUTE} replace />}
      />
    </Routes>
  );
}

export default InvestorMultiStepForm;
