import { Dialog, DialogTitle } from '@headlessui/react';
import React from 'react';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import IframeWrapper from '/src/components/utility/IframeWrapper';
import { cn } from '/src/util/cn';

export default function ActivationTaskModal({
  name,
  iframeURL,
  isCompleted,
}: {
  name: string;
  iframeURL: string;
  isCompleted: boolean;
}): JSX.Element {
  const [showModal, setShowModal] = React.useState(false);
  function onClose() {
    setShowModal(false);
  }

  return (
    <>
      <button
        className={cn(
          'ml-3 cursor-pointer text-left text-sm',
          isCompleted ? 'line-through' : '',
        )}
        onClick={() => setShowModal(true)}
      >
        {name}
      </button>
      <ModalWrapper
        open={showModal}
        onClose={() => {
          onClose();
        }}
      >
        <div className="space-y-10 bg-white p-4 sm:max-w-2xl sm:p-7">
          <DialogTitle className="my-3 text-center text-xl font-medium leading-6 text-gray-900 sm:mt-0">
            {name}
          </DialogTitle>
          <IframeWrapper
            className="h-[40rem] md:w-[40rem]"
            frameTitle={`Guide for ${name}`}
            frameHeight="h-[42.8rem]"
            frameSrc={iframeURL}
          />
        </div>
      </ModalWrapper>
    </>
  );
}
