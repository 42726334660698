export enum PolicyType {
  AcceptableUse = 'acceptable-use',
  Cookies = 'cookies',
  Privacy = 'privacy',
  TermsOfService = 'terms',
}

export const PolicyTypeMap: Record<PolicyType, string> = {
  [PolicyType.AcceptableUse]: 'Acceptable Use Policy',
  [PolicyType.Cookies]: 'Cookies Policy',
  [PolicyType.Privacy]: 'Privacy Policy',
  [PolicyType.TermsOfService]: 'Terms of Service',
};
