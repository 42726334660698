import Logger from './logger';

export async function getUserIpAddress(): Promise<string | null> {
  // Why are not using the same service we pay for in the backend?
  // https://api.db-ip.com/v2/free/self/ipAddress
  const url = 'https://api.ipify.org/?format=json';
  try {
    const response = await fetch(url);
    if (response.status === 200) {
      const data = await response.json();
      const ipAddress = data.ip;

      if (!ipAddress || typeof ipAddress !== 'string') {
        return null;
      }

      return ipAddress;
    }
  } catch (error) {
    Logger.error(error);
  }

  return null;
}
