import Carlos from '/src/res/Testimonials/carlos_del_carpio.jpg';
import Chuck from '/src/res/Testimonials/chuck_walter.jpg';
import Sam from '/src/res/Testimonials/sam_hornsby.jpg';

export interface Testimonial {
  body: string;
  author: {
    name: string;
    roleAtFirm: string;
    imageUrl: string;
  };
  url?: string;
}

export const TESTIMONIALS_STARTUP: Testimonial[] = [
  {
    body: 'Having all the data in one place and being able to update it regularly without sending any additional emails to investors is just so useful. Especially for founders who have very little time, the ability to easily share the deck and keep track of all the engagement analytics on top of the rich investor profiles and fit ranking is a true productivity booster.',
    author: {
      name: 'Sam Hornsby',
      roleAtFirm: 'Co-founder & CEO of ERIC',
      imageUrl: Sam,
    },
    url: 'https://www.meet-eric.com/',
  },
  {
    body: "Much better than just a deck! - Flowlie has boosted my confidence when reaching out to investors. Our FlowLink provides us with a clear framework for sharing key information, and it's great to know when an investor has accessed the link and our pitch deck.",
    author: {
      name: 'Chuck Walter',
      roleAtFirm: 'Co-founder & CEO of FriendApp',
      imageUrl: Chuck,
    },
    url: 'https://www.friendapp.com/',
  },
  {
    body: 'Flowlie allowed us to get more information about investors than any other tool we tried. Since investors have a dedicated page, we see not only their engagement with our deck but also their thesis and degree of fit. This proved extremely valuable throughout our fundraising saving us time and allowing us to focus on the best ones.',
    author: {
      name: 'Carlos Del Carpio',
      roleAtFirm: 'Co-founder & CEO of Valia',
      imageUrl: Carlos,
    },
    url: 'https://www.valiapro.com/',
  },
];
