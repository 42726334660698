import RecordInvestmentFieldNames from '/src/enums/RecordInvestmentFieldNames';
import FormStep from '/src/interfaces/FormStep';
import RECORD_INVESTMENT_FIELD_MAPPING from './RecordInvestmentFormFieldMapping';

const steps: FormStep[] = [
  {
    label: 'Record Investment',
    fields: [
      RECORD_INVESTMENT_FIELD_MAPPING[RecordInvestmentFieldNames.Reasons],
      RECORD_INVESTMENT_FIELD_MAPPING[RecordInvestmentFieldNames.DealRating],
      RECORD_INVESTMENT_FIELD_MAPPING[RecordInvestmentFieldNames.Amount],
      RECORD_INVESTMENT_FIELD_MAPPING[RecordInvestmentFieldNames.CarryCharged],
      RECORD_INVESTMENT_FIELD_MAPPING[RecordInvestmentFieldNames.IsLead],
      RECORD_INVESTMENT_FIELD_MAPPING[
        RecordInvestmentFieldNames.BoardParticipation
      ],
    ],
    isOptional: false,
  },
  {
    label: 'Record Investment',
    fields: [
      RECORD_INVESTMENT_FIELD_MAPPING[
        RecordInvestmentFieldNames.HasRightsProRata
      ],
      RECORD_INVESTMENT_FIELD_MAPPING[
        RecordInvestmentFieldNames.HasRightsInformation
      ],
      RECORD_INVESTMENT_FIELD_MAPPING[RecordInvestmentFieldNames.HasRightsMfn],
      RECORD_INVESTMENT_FIELD_MAPPING[
        RecordInvestmentFieldNames.InvestorUpdatesFrequency
      ],
    ],
    isOptional: false,
  },
];

export default steps;
