import React, { useContext, useState } from 'react';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { cn } from '/src/util/cn';
import { PolicyTypeMap } from '/../libs/shared-types/src/constants/UserPolicies/PolicyType';
import {
  InvestorPolicyAgreements,
  StartupPolicyAgreements,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { getUserIpAddress } from '/src/services/GetUserIpAddress';
import API from '/src/middleware/API';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import Logger from '/src/services/logger';

function PolicyAgreementDialog(): JSX.Element {
  const { requiredPolicies, accountType, refreshUserMetadata } = useContext(
    AccountMetadataContext,
  );
  const [hasAcceptedPolicies, setHasAcceptedPolicies] = useState(false);

  if (!requiredPolicies || requiredPolicies.length <= 0) {
    return <></>;
  }

  async function handleAcceptPolicies() {
    if (!hasAcceptedPolicies) {
      return;
    }
    try {
      const ipAddress = await getUserIpAddress();
      if (accountType === AccountTypes.Startup) {
        await API.post(StartupPolicyAgreements.buildEndpoint(), {
          policies: requiredPolicies,
          ipAddress,
        });
      } else if (accountType === AccountTypes.Investor) {
        await API.post(InvestorPolicyAgreements.buildEndpoint(), {
          policies: requiredPolicies,
          ipAddress,
        });
      }
      refreshUserMetadata();
    } catch (error: any) {
      Logger.error('Failed to accept policies', error);
    }
  }

  // Join the policy types into a string with commas, except for the last one which should be 'and'
  const policyTypesString = requiredPolicies
    .map((policy) => PolicyTypeMap[policy.policyType])
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1');

  return (
    <ModalWrapper open={requiredPolicies.length > 0} onClose={() => null}>
      <SimpleDialog
        onCancel={undefined}
        onPrimaryAction={() => handleAcceptPolicies()}
        primaryAction="Agree and continue"
        isPrimaryActionDisabled={!hasAcceptedPolicies}
        color="blue"
        iconType="InformationCircle"
        title="Please read and accept our updated policies to proceed"
        text={
          <div className="my-4 space-y-6">
            <p className="text-base font-medium text-gray-700">
              We have updated our {policyTypesString} to reflect our new and
              upcoming features. <br />
              You can find the updated{' '}
              {requiredPolicies.length > 1 ? 'policies' : 'policy'} linked
              below:
            </p>
            <ul className="list-inside list-disc space-y-1">
              {requiredPolicies.map((policy) => (
                <li key={policy.policyName}>
                  <a
                    href={`https://www.flowlie.com/legal/${policy.policyType}`}
                    className="hyperlink"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {policy.policyName}
                  </a>
                </li>
              ))}
            </ul>
            <label
              className="mt-10 flex cursor-pointer flex-row"
              htmlFor="hasAcceptedPolicies"
            >
              <input
                id="hasAcceptedPolicies"
                name="hasAcceptedPolicies"
                type="checkbox"
                className={cn(
                  'input-checkbox h-5 w-5',
                  !hasAcceptedPolicies
                    ? 'ring-2 ring-red-600 ring-offset-2'
                    : '',
                )}
                checked={hasAcceptedPolicies}
                onChange={() => setHasAcceptedPolicies(!hasAcceptedPolicies)}
              />
              <span className="ml-2 flex flex-row items-center">
                I confirm I have read and agree with the new {policyTypesString}
                .
              </span>
            </label>
          </div>
        }
      />
    </ModalWrapper>
  );
}

export default PolicyAgreementDialog;
