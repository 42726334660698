import throttle from 'lodash.throttle';
import {
  ClockIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/20/solid';
import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import {
  ActiveDealSummaryView,
  DealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import {
  dayDiff,
  formatDaysUntil,
  formatMsToDays,
} from '/src/util/formatting/dates';
import { abbreviateNumber } from '/src/util/formatting/numbers';
import {
  formatImageAddress,
  shortenWebUrl,
} from '/src/util/formatting/strings';
import { cn } from '/src/util/cn';
import { generateTag } from '/src/util/generateTagCloud';
import { roundIsInGracePeriod, isRoundOpen } from '/src/util/rounds';
import { isMinDate } from '/src/util/time';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProps,
  DropResult,
} from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { getColorByIndex } from '/src/util/colorLookup';
import { getDealCountByStage } from './util';
import DealActionsDropdown from '/src/components/DealActionsDropdown';
import ActiveStageDurationLeft from '../DealDetail/ActiveStageDurationLeft';
import { Priority } from '/../libs/shared-types/src/constants/Priority';
import { StarRating } from '/../libs/shared-types/src/constants/StarRating';
import PrioritySelect from '../DealDetail/PrioritySelect';
import StarRatingSelect from '../DealDetail/StarRatingSelect';
import SquaredLogo from '/src/components/SquaredLogo';

/**
 * Use this component when running React 18 in StrictMode.
 * Droppable component does not work in StrictMode
 */
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

interface DealCardProps {
  deal: ActiveDealSummaryView;
  handlePass: (deal: DealSummaryView) => void;
  handleRecordInvestment: (deal: DealSummaryView) => void;
  handleShare: (deal: DealSummaryView) => void;
  handleUpdateDealPriority: (
    deal: ActiveDealSummaryView,
    newRating: Priority,
  ) => void;
  handleUpdateDealRating: (
    deal: ActiveDealSummaryView,
    newRating: StarRating,
  ) => void;
}

function DealCard({
  deal,
  handlePass,
  handleRecordInvestment,
  handleShare,
  handleUpdateDealPriority,
  handleUpdateDealRating,
}: DealCardProps) {
  const navigate = useNavigate();
  const isCurrentRoundOpen =
    deal.currentRound && isRoundOpen(deal.currentRound);

  const handleOnClick = useCallback(
    () => navigate(`/app/deal-detail/${deal.startupId}`),
    [history],
  );

  function dealPriorityOnSelect(newPriority: Priority) {
    handleUpdateDealPriority(deal, newPriority);
  }

  function dealRatingOnSelect(newRating: StarRating) {
    handleUpdateDealRating(deal, newRating);
  }

  return (
    <div
      onClick={handleOnClick}
      aria-hidden="true"
      className="w-full cursor-pointer rounded-lg bg-white shadow"
    >
      <div className="flex w-full items-center justify-between space-x-2 p-3">
        <figure className="relative flex-none">
          <SquaredLogo
            containerClassName="size-10"
            src={formatImageAddress(deal.startupLogoKey)}
            alt={`${deal.startupName} logo`}
          />
          {deal.isPortfolioDeal && (
            <span
              className="absolute -left-1.5 -top-1.5 rounded-full bg-purple-300 p-0.5"
              title="This deal is in your portfolio"
            >
              <PresentationChartLineIcon className="h-4 w-4 text-purple-700" />
            </span>
          )}
        </figure>
        <div className="w-fit max-w-[170px] flex-1 break-all">
          <h3 className="w-fit break-all text-sm font-medium text-gray-900">
            {deal.startupName}
          </h3>
          <a
            className="block w-fit truncate text-xs text-gray-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href={deal.website}
            onClick={(e) => e.stopPropagation()}
          >
            {shortenWebUrl(deal.website)}
          </a>
        </div>

        <DealActionsDropdown
          deal={deal}
          handleMoveToActive={() => {}}
          handlePass={handlePass}
          handleRecordInvestment={handleRecordInvestment}
          handleShare={handleShare}
        />
      </div>

      <dl className="grid grid-cols-2 gap-x-2 gap-y-4 px-3 pb-2">
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Stage</dt>
          {generateTag(deal.currentStage, false)}
        </div>
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Round</dt>
          <dd className="mt-0 text-sm text-gray-900">
            {generateTag(isCurrentRoundOpen ? 'Open' : 'Closed', true)}
            {isCurrentRoundOpen &&
              deal.currentRound &&
              !isMinDate(deal.currentRound.estimatedCloseDate) && (
                <div className="text-xs text-gray-500">
                  Closing&nbsp;
                  {Math.abs(dayDiff(deal.currentRound.estimatedCloseDate)) >
                    1 && 'in '}
                  {formatDaysUntil(deal.currentRound.estimatedCloseDate)}
                </div>
              )}
            {!isCurrentRoundOpen &&
              deal.currentRound &&
              roundIsInGracePeriod(deal.currentRound) && (
                <div className="text-xs text-gray-500">Action Required</div>
              )}
          </dd>
        </div>
        {deal.currentRound && (
          <>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Raising</dt>
              <dd className="mt-1 text-sm text-gray-900">{`$ ${abbreviateNumber(
                deal.currentRound.raiseTarget,
              )}`}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-xs font-medium text-gray-500">Cap</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <div>
                  {deal.currentRound.valuationCap
                    ? `$ ${abbreviateNumber(deal.currentRound.valuationCap)}`
                    : 'To Be Decided'}
                </div>
                <div className="text-xs text-gray-500">
                  {deal.currentRound.valuationCapType}
                </div>
              </dd>
            </div>
          </>
        )}

        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Rating</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <StarRatingSelect
              value={deal.rating.rating}
              onSelect={dealRatingOnSelect}
            />
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-xs font-medium text-gray-500">Priority</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <PrioritySelect
              value={deal.priority.priority}
              onSelect={dealPriorityOnSelect}
            />
          </dd>
        </div>

        <ActiveStageDurationLeft durationLeft={deal.durationLeft} />
      </dl>
    </div>
  );
}

interface ActiveDealsBoardProps {
  activeDeals: ActiveDealSummaryView[];
  boardStages: Stage[];
  handleActiveBoardStageChange: (startupId: string, stageName: string) => void;
  handlePass: (deal: DealSummaryView) => void;
  handleRecordInvestment: (deal: DealSummaryView) => void;
  handleShare: (deal: DealSummaryView) => void;
  handleUpdateDealPriority: (
    deal: ActiveDealSummaryView,
    newRating: Priority,
  ) => void;
  handleUpdateDealRating: (
    deal: ActiveDealSummaryView,
    newRating: StarRating,
  ) => void;
  stageDealCountMap: Map<string, number>;
  isSearching: boolean;
}

function ActiveDealsBoard({
  activeDeals,
  boardStages,
  handleActiveBoardStageChange,
  handlePass,
  handleRecordInvestment,
  handleShare,
  handleUpdateDealPriority,
  handleUpdateDealRating,
  stageDealCountMap,
  isSearching,
}: ActiveDealsBoardProps): JSX.Element {
  const deals = activeDeals;

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceStage = boardStages.find((x) => x._id === source.droppableId);
      const destinationStage = boardStages.find(
        (x) => x._id === destination.droppableId,
      );

      if (
        destinationStage &&
        sourceStage &&
        destinationStage._id !== sourceStage._id
      ) {
        // Update the minimum data needed for the component state to simulate a responsive UX
        // NOTE: The following code is dangerous! The Deals array will not represent a valid state until it gets refreshed
        const dealIndex = deals.findIndex(
          (x) => x.startupId.toString() === draggableId.toString(),
        );
        deals[dealIndex].currentActiveBoardStage = {
          ...destinationStage,
          createdOn: new Date(),
        };

        // Send the PUT request to update the deal
        handleActiveBoardStageChange(draggableId, destinationStage.name);
      }
    }
  }

  const throttledOnDragEnd = useMemo(() => throttle(onDragEnd, 400), [deals]);

  // Stop the invocation of the throttled function
  // after unmounting
  useEffect(
    () => () => {
      throttledOnDragEnd.cancel();
    },
    [],
  );

  return (
    <div className="flex flex-grow flex-col">
      <div className="flex flex-grow space-x-4 pb-14">
        <DragDropContext
          onDragEnd={onDragEnd}
          // Dragging multiple deals very fast can throw exception
          // onDragEnd={throttledOnDragEnd}
        >
          {boardStages.map((stage, stageIndex) => (
            <section key={stage._id} className="flex w-72 flex-col">
              <header
                className={cn(
                  getColorByIndex(stageIndex, boardStages.length).bgColor,
                  getColorByIndex(stageIndex, boardStages.length).textColor,
                  'sticky top-0 z-10 flex items-center justify-between rounded-t p-3 text-xs font-semibold tracking-wide',
                )}
              >
                <h4 className="flex items-center truncate">
                  <span className="mt-0.5">{stage.name}</span>
                  <span className="ml-2 rounded-full bg-gray-100 px-1.5 text-2xs font-medium text-gray-800">
                    {getDealCountByStage(activeDeals, stage.name)}
                    {isSearching && <>/{stageDealCountMap.get(stage._id)}</>}
                  </span>
                </h4>
                {stage.duration && (
                  <aside className="inline-flex items-center">
                    <ClockIcon className="mr-1 h-3 w-3" aria-hidden="true" />
                    <span>{formatMsToDays(stage.duration)}</span>
                  </aside>
                )}
              </header>

              <div className="flex flex-grow">
                {/*
                 NOTE:  
                  Remember that Droppable does not work in React 18 StrictMode, 
                  use StrictModeDroppable instead if running in StrictMode.
                */}
                <StrictModeDroppable droppableId={stage._id} key={stage._id}>
                  {(dropProvided, snapshot) => (
                    <ul
                      className={cn(
                        snapshot.isDraggingOver ? 'bg-blue-100' : 'bg-gray-200',
                        'flex flex-grow flex-col space-y-2 rounded-b p-2',
                      )}
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                    >
                      {deals
                        .filter(
                          (x) =>
                            x.currentActiveBoardStage?.name.toUpperCase() ===
                            stage.name.toUpperCase(),
                        )
                        .map((deal, index) => (
                          <Draggable
                            key={deal.startupId}
                            draggableId={deal.startupId.toString()}
                            index={index}
                          >
                            {(dragProvided) => (
                              <li
                                key={deal.startupId}
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                              >
                                <DealCard
                                  deal={deal}
                                  handlePass={handlePass}
                                  handleRecordInvestment={
                                    handleRecordInvestment
                                  }
                                  handleShare={handleShare}
                                  handleUpdateDealPriority={
                                    handleUpdateDealPriority
                                  }
                                  handleUpdateDealRating={
                                    handleUpdateDealRating
                                  }
                                />
                              </li>
                            )}
                          </Draggable>
                        ))}
                      {dropProvided.placeholder}
                    </ul>
                  )}
                </StrictModeDroppable>
              </div>
            </section>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
}

export default ActiveDealsBoard;
