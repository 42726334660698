import React from 'react';
import RoundImageWithPlaceholder from '/src/components/RoundImageWithPlaceholder';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import {
  formatImageAddress,
  splitOnWhiteSpace,
} from '/src/util/formatting/strings';
import { cn } from '/src/util/cn';
import DebugRender from '/src/components/utility/DebugRender';
import {
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/20/solid';
import { StartupAccessManagerViewSessionView } from '../../../../../types/view/StartupAccessManagerView';
import { VerificationStatus } from '../../../../../../../libs/shared-types/src/constants/VerificationStatus';
import { getColor } from '/src/util/colorLookup';
import CopyTextButton from '/src/components/CopyTextButton';
import { ViewerTypes } from '../../../../../../../libs/shared-types/src/constants/ViewerTypes';

export interface AccessManagerViewSessionsNameCellProps {
  viewSession: StartupAccessManagerViewSessionView;
}
function AccessManagerViewSessionsNameCell({
  viewSession,
}: AccessManagerViewSessionsNameCellProps) {
  let verificationStatusTooltip = '';

  if (viewSession.visitorVerificationStatus === VerificationStatus.Verified) {
    verificationStatusTooltip =
      'This view session was verified by the owner of the email address';
  }

  if (viewSession.visitorVerificationStatus === VerificationStatus.Fraudulent) {
    verificationStatusTooltip =
      'This view session was marked as fraudulent by the owner of the email address';
  }

  if (viewSession.viewerType === ViewerTypes.Authenticated) {
    const authenticatedTooltip =
      'This viewer has a Flowlie account and has verified their email adddress';
    verificationStatusTooltip =
      viewSession.role && viewSession.firm
        ? `${viewSession.role} at ${viewSession.firm}\n${authenticatedTooltip}`
        : authenticatedTooltip;
  }

  return (
    <div className="flex items-center">
      <div className="h-10 w-10 shrink-0">
        {viewSession.name === 'Anonymous' ? (
          <RoundImageWithPlaceholder
            containerStyles="h-10 w-10"
            text="?"
            textStyles="text-xl"
            imgAlt="Anonymous Viewer"
          />
        ) : (
          <UserAvatarWithInitials
            containerStyles="h-10 w-10"
            firstName={splitOnWhiteSpace(viewSession.name)[0]}
            lastName={splitOnWhiteSpace(viewSession.name)[1]}
            textStyles="text-xl"
            imgAlt="Viewer"
            imgSrc={formatImageAddress(viewSession.picKey)}
          />
        )}
      </div>
      <div className="ml-2">
        <DebugRender>
          <span className="text-2xs text-gray-500">
            sessionId: {viewSession.sessionId}
          </span>
        </DebugRender>
        <div
          title={verificationStatusTooltip}
          className="app-table-row-text--bold mb-0.5 flex flex-row"
        >
          <span className="mr-2">{viewSession.name}</span>
          {viewSession.visitorVerificationStatus ===
            VerificationStatus.Verified && (
            <ShieldCheckIcon className="h-5 w-5 text-green-600" />
          )}
          {viewSession.visitorVerificationStatus ===
            VerificationStatus.Fraudulent && (
            <ShieldExclamationIcon className="h-5 w-5 text-red-500" />
          )}
          {viewSession.connectionId && viewSession.investorType && (
            <span
              className={cn(
                'inline-flex items-center rounded px-1.5 text-2xs font-medium leading-5',
                getColor(viewSession.investorType).bgColor,
                getColor(viewSession.investorType).textColor,
              )}
            >
              {viewSession.investorType}
            </span>
          )}
        </div>
        {viewSession.email && (
          <div className="text-sm text-gray-500">
            <a
              className="truncate hover:underline"
              href={`mailto:${viewSession.email}`}
              onClick={(e) => e.stopPropagation()}
            >
              {viewSession.email}
            </a>
            <CopyTextButton
              text={viewSession.email}
              className="absolute ml-1"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AccessManagerViewSessionsNameCell;
