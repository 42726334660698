import React from 'react';
import { formatDate, howLongBetween } from '/src/util/formatting/dates';

function LastContactedOnCell({ lastContactedOn }: { lastContactedOn?: Date }) {
  return (
    <>
      {lastContactedOn ? (
        <div className="app-table-row-text">
          {howLongBetween(lastContactedOn)}
          <div className="app-table-row-text--light">
            {formatDate(lastContactedOn)}
          </div>
        </div>
      ) : (
        <div className="app-table-row-text">-</div>
      )}
    </>
  );
}

export default LastContactedOnCell;
