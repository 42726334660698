import AcceptPolicyAgreementsField from './fields/AcceptPolicyAgreementsField';
import EmailField from './fields/EmailField';
import FirstNameField from './fields/FirstNameField';
import LastNameField from './fields/LastNameField';
import PasswordCreateField from './fields/PasswordCreateField';
import PasswordField from './fields/PasswordField';
import AuthFieldNames from '/src/enums/AuthFieldNames';
import FormField from '/src/interfaces/FormField';

const AUTH_FIELD_MAPPING: { [key: string]: FormField } = {
  [AuthFieldNames.AcceptLegal]: AcceptPolicyAgreementsField,
  [AuthFieldNames.Email]: EmailField,
  [AuthFieldNames.FirstName]: FirstNameField,
  [AuthFieldNames.LastName]: LastNameField,
  [AuthFieldNames.Password]: PasswordField,
  [AuthFieldNames.PasswordCreate]: PasswordCreateField,
};

export default AUTH_FIELD_MAPPING;
