import React, { Fragment, useState } from 'react';
import * as yup from 'yup';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Alert from '/src/components/notifications/Alert';
import CustomSelect from '/src/components/inputs/CustomSelect';
import formatGroupLabel from '/src/components/inputs/ReactSelectAdditions/selectFormatGroupLabel';
import DebugRender from '/src/components/utility/DebugRender';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { MAX_PASSED_REASONS } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import {
  LONG_TEXTAREA_LENGTH_MAX,
  PASS_FEEDBACK_TEXTAREA_LENGTH_MAX,
} from '/../libs/shared-types/src/constants/TextLengthRanges';
import {
  COMMITMENT_AMOUNT_MIN,
  COMMITMENT_AMOUNT_MAX,
} from '/../libs/shared-types/src/constants/NumericRanges';
import MoneyInput from '/src/components/inputs/MoneyInput';
import {
  enumToSelectOptions,
  listToSelectOptions,
} from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { TernaryType } from '/../libs/shared-types/src/constants/TernaryType';
import { PIPELINE_GROUPED_REASONS_FOR_PASSING } from '/../libs/shared-types/src/constants/SelectOptions/ReasonsForPassingOptions';
import TextCharacterCounter from '/src/components/TextCharacterCounter';
import FormikInput from '/src/components/inputs/FormikInput';
import LimitedMenu from '/src/components/inputs/ReactSelectAdditions/LimitedMenu';
import { StageView } from '../../../../types/view/StageView';
import { InvestorPipelineStatusUpdate } from '/../libs/shared-types/src/types/model/InvestorPipeline';
import { PipelineInvestorItemView } from '/../libs/shared-types/src/types/view/InvestorPipelineView';
import {
  SelectOption,
  TooltipSelectOption,
} from '../../../../types/SelectOptions';
import { cn } from '/src/util/cn';
import DateString from '/src/components/utility/DateString';
import RoundView from '../../../../types/view/RoundView';
import PrioritySelectInput from '/src/components/inputs/PrioritySelectInput';
import { removeUnsetOptionalValues } from '/src/util/forms';
import { PIPELINE_INVESTOR_SOURCE_OPTIONS } from '/../libs/shared-types/src/constants/PipelineInvestorSource';
import { FormatOptionLabelMeta } from 'react-select';
import FormatOptionWithTooltipLabel from '/src/components/inputs/ReactSelectAdditions/formatOptionWithTooltipLabel';

enum FieldNames {
  Commitment = 'commitment',
  CurrentStageName = 'currentStageName',
  NextRoundFit = 'nextRoundFit',
  PassFeedback = 'passFeedback',
  PassReasons = 'passReasons',
  Priority = 'priority',
  Source = 'source',
}

const validationSchema = yup.object({
  [FieldNames.Commitment]: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be greater than 0')
    .test(
      'onlyHundredMultiples',
      'Only multiples of $100 are accepted',
      (value): boolean => {
        if (value && value > 0 && value % 100 !== 0) {
          return false;
        }
        return true;
      },
    )
    .min(COMMITMENT_AMOUNT_MIN, `Must be at least $ ${COMMITMENT_AMOUNT_MIN}`)
    .max(
      COMMITMENT_AMOUNT_MAX,
      `Cannot be greater than $ ${COMMITMENT_AMOUNT_MAX}`,
    )
    .nullable(),
  [FieldNames.CurrentStageName]: yup.string().required('Required'),
  [FieldNames.NextRoundFit]: yup.string().nullable(),
  [FieldNames.PassFeedback]: yup
    .string()
    .max(PASS_FEEDBACK_TEXTAREA_LENGTH_MAX, 'Feedback is too long')
    .nullable(),
  [FieldNames.PassReasons]: yup
    .array()
    .of(yup.string())
    .max(
      MAX_PASSED_REASONS,
      `At most ${MAX_PASSED_REASONS} pass reasons are allowed`,
    )
    .nullable(),
  [FieldNames.Priority]: yup.number().nullable(),
  [FieldNames.Source]: yup.string().nullable(),
});

interface UpdateInvestorStatusDialogProps {
  boardStages: StageView[];
  investor: PipelineInvestorItemView;
  currentRound: RoundView;
  onCancel: () => void;
  onSave: (update: InvestorPipelineStatusUpdate) => Promise<void>;
}

function UpdateInvestorStatusDialog({
  boardStages,
  investor,
  currentRound,
  onCancel,
  onSave,
}: UpdateInvestorStatusDialogProps): JSX.Element {
  const [initialValues, setInitialValues] = useState<any>({
    [FieldNames.Commitment]: investor.commitment?.amount ?? '',
    [FieldNames.CurrentStageName]: investor.currentStage.name,
    [FieldNames.NextRoundFit]: investor.nextRoundFit?.value ?? '',
    [FieldNames.PassFeedback]: investor.passFeedback?.feedback ?? '',
    [FieldNames.PassReasons]: investor.passReasons?.reasons ?? '',
    [FieldNames.Priority]: investor.priority?.priority ?? '',
    [FieldNames.Source]: investor.source?.value ?? '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  async function onSubmit(
    values: FormikValues,
    { setSubmitting }: FormikHelpers<any>,
  ) {
    setSubmitting(true);
    try {
      const cleanValues = removeUnsetOptionalValues(values);
      await onSave(cleanValues);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="w-screen bg-white p-4 sm:max-w-2xl sm:p-7">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, dirty, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 items-center justify-between sm:flex sm:flex-row">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Update status for {investor.name}
                <p className="text-sm font-normal text-gray-600">
                  Last updated <DateString date={investor.updatedOn} />
                </p>
              </h3>

              <div className="my-4 flex flex-col space-y-3 sm:my-0 sm:ml-4 sm:flex-row sm:space-x-3 sm:space-y-0">
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral justify-center truncate"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green justify-center truncate"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2" aria-hidden>
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>

            <section className="">
              <Field
                isDisabled={!currentRound.isOpen}
                className="custom-select"
                component={CustomSelect}
                closeMenuOnSelect
                label="Pipeline Stage"
                name={FieldNames.CurrentStageName}
                options={listToSelectOptions(boardStages.map((x) => x.name))}
                placeholder="Select..."
                menuPortalTarget={document.querySelector('body')}
                formatOptionLabel={({ label, value }: SelectOption) => {
                  const boardStage = boardStages.find((x) => x.name === value);
                  return (
                    <div>
                      <span
                        className={cn(
                          boardStage?.bgColor ?? '',
                          boardStage?.textColor ?? '',
                          'rounded px-2.5 py-1 text-sm font-semibold tracking-wide',
                        )}
                      >
                        {value}
                      </span>
                    </div>
                  );
                }}
              />

              <Field
                className="custom-select"
                component={CustomSelect}
                isClearable
                closeMenuOnSelect
                label="Source"
                secondaryLabel="How did you connect with this investor for this round?"
                name={FieldNames.Source}
                options={PIPELINE_INVESTOR_SOURCE_OPTIONS}
                placeholder="Select..."
                menuPortalTarget={document.querySelector('body')}
                formatOptionLabel={(
                  option: TooltipSelectOption,
                  formatOptionLabelMeta: FormatOptionLabelMeta<any>,
                ) => (
                  <FormatOptionWithTooltipLabel
                    option={option}
                    formatOptionLabelMeta={formatOptionLabelMeta}
                  />
                )}
              />

              <PrioritySelectInput
                label="Priority"
                secondaryLabel="How important is this investor to your fundraising efforts?"
                name={FieldNames.Priority}
                isClearable
              />

              <MoneyInput
                label="Commitment Amount"
                secondaryLabel="How much did they commit to invest?"
                name={FieldNames.Commitment}
                step={100}
                tooltip="You should record commitment only after a clear indication of investment has been made from the investor."
              />

              <Field
                className="custom-select"
                component={CustomSelect}
                components={{ Menu: LimitedMenu }}
                groupLabelFormat={formatGroupLabel}
                isMulti
                label="Reasons For Passing"
                secondaryLabel="Why did they not invest?"
                tooltip="You should record the specific reasons why the investor didn’t invest, or why you chose not to accept money from them."
                maxCount={MAX_PASSED_REASONS}
                name={FieldNames.PassReasons}
                options={PIPELINE_GROUPED_REASONS_FOR_PASSING}
                placeholder="Select pass reasons..."
                menuPortalTarget={document.querySelector('body')}
              />

              <Field
                component={FormikInput}
                boxType="textarea"
                name={FieldNames.PassFeedback}
                customStyle="h-28"
                label="Feedback For Passing"
                secondaryLabel="Any context or feedback you’d like to save for later?"
                tooltip="You can record any additional context around the decision to not invest, or any direct feedback the investor gave you."
                type="text"
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
              <TextCharacterCounter
                textLength={values[FieldNames.PassFeedback].length}
                maxLength={PASS_FEEDBACK_TEXTAREA_LENGTH_MAX}
              />

              <Field
                className="custom-select"
                component={CustomSelect}
                isClearable
                closeMenuOnSelect
                label="Next Round Fit"
                secondaryLabel="Are they a potential fit for the next round?"
                tooltip="You should record whether the investor could be a fit for the next fundraising round."
                name={FieldNames.NextRoundFit}
                options={enumToSelectOptions(TernaryType)}
                placeholder="Select..."
                menuPortalTarget={document.querySelector('body')}
              />
            </section>

            <div className="my-4">
              <Alert
                color="red"
                alertType="Error"
                content={errorMessage}
                isShown={errorMessage !== ''}
                onClose={() => setErrorMessage('')}
              />
            </div>

            <DebugRender>
              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </DisclosurePanel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateInvestorStatusDialog;
