import React, { Fragment, useState } from 'react';
import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import { StageView } from '/../libs/shared-types/src/types/view/StageView';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, ClockIcon } from '@heroicons/react/20/solid';
import { cn } from '/src/util/cn';
import { getColorByIndex } from '/src/util/colorLookup';
import { formatMsToDays } from '/src/util/formatting/dates';
import { TableHeader } from '/src/interfaces/TableHeader';
import { SortOrder } from '/src/interfaces/Sortable';
import SortIcon from '/src/components/utility/SortIcon';

function getItemsCountByStage(
  stageRowItemsMap: Map<string, string[]>,
  stage: StageView,
) {
  return stageRowItemsMap.get(stage.name)?.length ?? 0;
}

interface TableWithStagesProps {
  rowComponent: (stage: StageView) => JSX.Element | JSX.Element[];
  boardStages: StageView[];
  headers: TableHeader<any>[];
  isSearching: boolean;
  onSort?: (key: string, order: SortOrder) => void;
  selectedStage: Stage | undefined;
  stageRowItemsMap: Map<string, string[]>;
  itemsPerStageCountMap: Map<string, number>;
  stageStats?: (stage: Stage) => JSX.Element;
}

function TableWithStages({
  boardStages,
  headers,
  isSearching,
  onSort,
  selectedStage,
  itemsPerStageCountMap,
  stageRowItemsMap,
  rowComponent,
  stageStats,
}: TableWithStagesProps): JSX.Element {
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

  const handleSort = (sortKey: string) => {
    if (!onSort) {
      return;
    }

    onSort(sortKey, sortOrder * -1);
    setSortedColumn(sortKey);
    setSortOrder((order) => order * -1);
  };

  const hasContent = Array.from(stageRowItemsMap.values()).some(
    (items) => items.length > 0,
  );

  return (
    <div className="mt-4 flex flex-col px-0.5">
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="border-b border-gray-200 bg-gray-50 p-0.5 shadow md:rounded-lg">
            <table className="min-w-full table-fixed select-none divide-y divide-gray-200">
              <thead className="w-full rounded-lg bg-gray-50">
                <tr className="sticky top-24 z-20 bg-gray-50 sm:top-32">
                  {headers.map((header) => (
                    <th
                      scope="col"
                      key={headers.indexOf(header)}
                      onClick={() =>
                        header.sortKey ? handleSort(header.sortKey) : null
                      }
                      className={cn(
                        'app-table-header-item group',
                        header.width ?? '',
                        header.sortKey ? 'cursor-pointer' : '',
                      )}
                    >
                      <div
                        className={cn(
                          header.sortKey && sortedColumn === header.sortKey
                            ? 'font-bold text-gray-700'
                            : '',
                          'relative inline-flex items-center',
                        )}
                      >
                        {header.element}
                        <span className="absolute -right-6 bottom-0">
                          {sortedColumn !== header.sortKey && (
                            <span
                              className={cn(
                                'ml-1 hidden rounded bg-gray-200 text-gray-500 group-hover:block',
                                header.sortKey ? 'group-hover:opacity-100' : '',
                              )}
                            >
                              <SortIcon
                                order={
                                  sortOrder === SortOrder.Asc
                                    ? SortOrder.Desc
                                    : SortOrder.Asc
                                }
                              />
                            </span>
                          )}
                          {header.sortKey &&
                            sortedColumn === header.sortKey && (
                              <span className="ml-1 rounded bg-gray-200 text-gray-700 group-hover:block">
                                <SortIcon order={sortOrder} />
                              </span>
                            )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {boardStages
                  .filter(
                    (stage) =>
                      stage._id === selectedStage?._id || !selectedStage,
                  )
                  .map((stage, index) => (
                    <Disclosure
                      defaultOpen={
                        selectedStage !== undefined ||
                        getItemsCountByStage(stageRowItemsMap, stage) > 0
                      }
                      // Key to force it to refresh when the items change stage
                      key={`${stage._id}-${Object.keys(stageRowItemsMap).length}-${getItemsCountByStage(stageRowItemsMap, stage)}`}
                    >
                      {({ open }) => (
                        <>
                          <tr>
                            <th
                              className="sticky top-32 z-10 sm:top-40"
                              colSpan={headers.length}
                              scope="colgroup"
                            >
                              <Disclosure.Button
                                className={cn(
                                  stage.borderColor
                                    ? stage.borderColor
                                    : getColorByIndex(index, boardStages.length)
                                        .borderColor,
                                  'flex w-full items-center rounded-sm border-l-8 bg-gray-50 py-2 text-left text-gray-800',
                                )}
                              >
                                <ChevronUpIcon
                                  className={`${
                                    !open ? 'rotate-180 transform' : ''
                                  } mx-2 h-6 w-6 transition`}
                                />
                                <span className="text-sm font-semibold">
                                  {stage.name}
                                </span>
                                <span
                                  className={cn(
                                    stage.bgColor
                                      ? stage.bgColor + ' ' + stage.textColor
                                      : getColorByIndex(
                                          index,
                                          boardStages.length,
                                        ).allColors,
                                    'ml-2 rounded-full px-1.5 text-xs font-medium',
                                  )}
                                >
                                  {getItemsCountByStage(
                                    stageRowItemsMap,
                                    stage,
                                  )}
                                  {isSearching && (
                                    <>/{itemsPerStageCountMap.get(stage._id)}</>
                                  )}
                                </span>
                                {stage.duration && (
                                  <div
                                    className="ml-4 inline-flex items-center text-sm"
                                    title="Stage duration"
                                  >
                                    <ClockIcon
                                      className="mr-1 size-4"
                                      aria-hidden="true"
                                    />
                                    <span>
                                      {formatMsToDays(stage.duration)}
                                    </span>
                                  </div>
                                )}
                                {stageStats && stageStats(stage)}
                              </Disclosure.Button>
                            </th>
                          </tr>
                          {getItemsCountByStage(stageRowItemsMap, stage) ===
                            0 &&
                            hasContent && (
                              <tr>
                                <td
                                  colSpan={headers.length}
                                  className="w-100 text-center text-sm text-gray-600"
                                >
                                  <Disclosure.Panel>
                                    <p className="my-4">
                                      No records at this stage
                                    </p>
                                  </Disclosure.Panel>
                                </td>
                              </tr>
                            )}
                          {rowComponent(stage)}
                        </>
                      )}
                    </Disclosure>
                  ))}
                {!hasContent && (
                  <tr>
                    <td
                      colSpan={headers.length}
                      className="w-100 text-center text-sm text-gray-600"
                    >
                      <p className="my-4">No results found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableWithStages;
