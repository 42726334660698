import React, { useState, useEffect, useContext } from 'react';
import { formatImageAddress } from '/src/util/formatting/strings';
import { cn } from '/src/util/cn';
import { LinkShareMetadata } from '/../libs/shared-types/src/types/view/LinkShareMetadata';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import confetto from 'confetto';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import {
  LocalStorageAccountData,
  localStorageGetAccountData,
} from '/src/middleware/LocalStorage';
import CompleteProfileSuccessMessage from '../../routes/common/CompleteProfileSuccessMessage';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import UserAvatarWithInitials from '../UserAvatarWithInitials';
import { getLinkShareMetadata } from '/src/services/LinkShareMetadata';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import SquaredLogo from '../SquaredLogo';

function CompleteProfileSuccess({
  shareToken,
  linkType,
}: {
  shareToken?: string;
  linkType?: LinkTypes;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [invitedBy, setInvitedBy] = useState<LinkShareMetadata>();
  const { setUnconfirmedAccountType } = useContext(AuthenticationContext);
  const [onboardingAccount, setOnboardingAccount] =
    useState<LocalStorageAccountData>();

  useEffect(() => {
    const accountData = localStorageGetAccountData();
    // This is the success for a new Investor account
    // Setting the unconfirmedAccountType explicitly, in case
    // the unconfirmed user is OLD and DOES NOT have an account type
    setUnconfirmedAccountType(AccountTypes.Investor);
    setOnboardingAccount(accountData);

    if (!shareToken || !linkType) {
      confetto();
      return;
    }

    // Fetch data about the account who invited
    const fetchLinkOriginator = async () => {
      setIsLoading(true);
      const user = await getLinkShareMetadata(shareToken, linkType);
      setInvitedBy(user);
    };

    fetchLinkOriginator()
      .catch((error) => Logger.error(error))
      .finally(() => {
        setIsLoading(false);
        confetto();
      });
  }, []);

  const image = (
    <>
      <div className="relative mb-8 flex">
        <div
          className={cn(
            'absolute -top-20 left-1/2 flex -translate-x-1/2 transform items-center justify-center shadow-xl',
            invitedBy?.startup && linkType === LinkTypes.Flowlink
              ? 'rounded-md'
              : 'rounded-full',
          )}
        >
          <div className="relative">
            {invitedBy?.investor && linkType === LinkTypes.Flowlink && (
              <>
                <UserAvatarWithInitials
                  containerStyles="h-20 w-20"
                  firstName={invitedBy.investor.firstName}
                  lastName={invitedBy.investor.lastName}
                  textStyles="text-3xl"
                  imgAlt="Investor Profile"
                  imgSrc={formatImageAddress(invitedBy.investor.profilePicKey)}
                />
                <span
                  className="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                />
              </>
            )}

            {invitedBy?.startup && linkType === LinkTypes.Flowlink && (
              <SquaredLogo
                containerClassName="size-20"
                src={formatImageAddress(invitedBy.startup.logoKey)}
                alt={`${invitedBy.startup.name} logo`}
              />
            )}

            {invitedBy?.investor &&
              linkType === LinkTypes.Dealshare &&
              invitedBy?.startup && (
                <div className="flex flex-row">
                  <UserAvatarWithInitials
                    containerStyles="h-20 w-20"
                    firstName={invitedBy.investor.firstName}
                    lastName={invitedBy.investor.lastName}
                    textStyles="text-3xl"
                    imgAlt="Investor Profile"
                    imgSrc={formatImageAddress(
                      invitedBy?.investor.profilePicKey,
                    )}
                  />
                  <SquaredLogo
                    containerClassName="size-20 my-1 mx-2"
                    src={formatImageAddress(invitedBy.startup.logoKey)}
                    alt={`${invitedBy.startup.name} logo`}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );

  if (isLoading) {
    return <PageLoadingSpinner message="Welcoming you to Flowlie... 🤝" />;
  }

  return (
    <section className="text-center">
      {image}

      {onboardingAccount && (
        <CompleteProfileSuccessMessage
          linkShareMetadata={invitedBy}
          onboardingAccount={onboardingAccount}
          linkType={linkType}
        />
      )}
    </section>
  );
}

export default CompleteProfileSuccess;
