import {
  FitScoreLabels,
  FitScoreReasonType,
} from '../../../types/model/FitScore';
import { InvestorDiscoveryView } from '../../../types/view/InvestorDiscoveryView';
import { StartupAccessManagerView } from '../../../types/view/StartupAccessManagerView';
import { ContactPreferences } from '../../ContactPreferences';
import { DealStatusTypes } from '../../DealStatusTypes';
import { InvestorDataType } from '../../InvestorDataType';
import InvestorTypes from '../../InvestorTypes';

export const EXAMPLE_INVESTOR = {
  _id: '569ed8269353e9f4c51617aa',
  accountTier: 'Lite', // Should be InvestorAccountTiers.Lite, but compiler breaks
  connections: [],
  invBusinessModels: ['B2C', 'B2B SMB', 'B2B Enterprise'],
  invGeographies: ['Geography Agnostic'],
  invImpacts: ['None'],
  invIndustries: ['Sector Agnostic'],
  invStages: ['Pre-Seed', 'Seed'],
  invTypes: ['Priced Round - Preferred', 'Priced Round - Common', 'SAFE'],
  policyAgreements: [],
  checkRangeMax: 300000,
  checkRangeMin: 50000,
  firm: 'Example Ventures',
  location: 'Miami, FL',
  phone: '+2345897222',
  profilePicKey: 'john_doe.jpg',
  role: 'Partner',
  type: InvestorTypes.VC, // Should be InvestorTypes.VC, but compiler breaks
  leadsRounds: 'Sometimes',
  taxState: 'Florida',
  isAccredited: true,
  firstName: 'John',
  lastName: 'Doe',
  email: 'john.doe@example.com',
  invitedBy: {
    accountId: '6106d6127fa1eb48aca1c26a',
    isInvestor: true,
  },
  creditsAvailable: 50,
  creditsTotal: 50,
  createdOn: new Date(1627838380389),
  flowlieToken: 'MOCK_TOKEN',
  __v: 112,
  linkedIn: 'https://www.linkedin.com',
  angelList: 'https://www.angellist.com',
  otherUrl: 'https://www.example.com',
  preferenceFollowUp: false,
  preferenceContact: [ContactPreferences.Email],
  invProductStage: 'Post-Revenue (Early Traction)',
  takesBoardSeats: 'Never',
  yearlyAvgInvCount: '11 to 20',
  expFunctional: [
    'Branding & Social Media',
    'Hardware Engineering',
    'Growth Hacking',
  ],
  expIndustry: [
    'Data Analytics & AI/ML',
    'Dating & Relationships',
    'Death-Tech',
  ],
  expInvestingYears: 10,
  pronouns: 'he/him',
  highestEducationLevel: "Master's degree",
  introBio: 'I am not real investor, just an example profile.',
  referredStartups: [],
  referredInvestors: [],
  medium: 'https://www.medium.com',
  nationalities: ['US'],
  professionalAccreditations: ['CPA', 'CIA'],
  substack: 'https://www.substack.com',
  twitter: 'https://www.twitter.com',
  universityAffiliations: ['University of Miami'],
  website: 'https://www.flowlie.com',
};

export const EXAMPLE_FIT_SCORE_TOUR = {
  score: 90,
  label: FitScoreLabels.VeryHigh,
  reasons: [
    {
      type: FitScoreReasonType.Good,
      text: 'invests in Fin-Tech',
    },
    {
      type: FitScoreReasonType.Good,
      text: 'invests primarily in Seed rounds and sometimes leads',
    },
    {
      type: FitScoreReasonType.Agnostic,
      text: 'invests anywhere across the globe',
    },
    {
      type: FitScoreReasonType.Bad,
      text: "doesn't prefer B2C models",
    },
  ],
};

export const EXAMPLE_INVESTOR_DISCOVERY_VIEW: InvestorDiscoveryView = {
  ...EXAMPLE_INVESTOR,
  name: 'John Doe',
  isOpenToColdInbound: true,
  investorDataType: InvestorDataType.Verified,
  isVerified: true,
  fitScore: EXAMPLE_FIT_SCORE_TOUR,
  isIncludedInInvestorDbForStartups: true,
  invImpacts: undefined,
  invTypes: undefined,
  updatedOn: new Date(),
};

export const EXAMPLE_INVESTOR_STARTUP_ACCESS_MANAGER_ENTRY: StartupAccessManagerView =
  {
    accountId: EXAMPLE_INVESTOR._id,
    picKey: EXAMPLE_INVESTOR.profilePicKey,
    name: 'John Doe',
    email: EXAMPLE_INVESTOR.email,
    firm: EXAMPLE_INVESTOR.firm,
    userType: 'Angel',
    role: EXAMPLE_INVESTOR.role,
    dealStatus: {
      status: DealStatusTypes.Active,
      statusChangedOn: new Date(),
    },
    fitScore: EXAMPLE_FIT_SCORE_TOUR,
    totalMillisecondsSpentOnDeal: 120000,
    totalViewCount: 7,
    lastViewedOn: new Date(),
  };
