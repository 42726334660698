import React, { useEffect, useRef, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { SortOrder } from '/src/interfaces/Sortable';
import { cn } from '/src/util/cn';
import { TableHeader } from '/src/interfaces/TableHeader';
import SortIcon from '/src/components/utility/SortIcon';

interface PaginatedTableProps {
  headers: TableHeader<any>[];
  rowComponents: JSX.Element[];
  rowsPerPage?: number;
  searchPlaceholder?: string;
  onSort?: (key: string, order: SortOrder) => void;
}

function PaginatedTable({
  headers,
  rowComponents,
  rowsPerPage = 10,
  onSort,
}: PaginatedTableProps): JSX.Element {
  const [page, setPage] = React.useState(1);
  const searchRef = useRef<HTMLInputElement>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);
  const [sortedColumn, setSortedColumn] = useState<string>();

  const nextPage = () => setPage(page + 1);
  const previousPage = () => setPage(page - 1);

  const handleSort = (sortKey: string) => {
    if (!onSort) {
      return;
    }

    onSort(sortKey, sortOrder * -1);
    setSortedColumn(sortKey);
    setSortOrder((order) => order * -1);
  };

  useEffect(() => {
    if (rowComponents && searchRef.current) {
      searchRef.current.value = '';
    }
  }, [rowComponents]);

  return (
    <>
      <div className="border-b border-gray-200 bg-gray-50 p-0.5 shadow dark:border-gray-800 dark:bg-gray-900 dark:shadow-gray-800 sm:rounded-lg">
        <table className="min-w-full select-none divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="w-full rounded-lg bg-gray-50 dark:bg-gray-900">
            <tr>
              {headers.map((header) => (
                <th
                  scope="col"
                  key={headers.indexOf(header)}
                  onClick={() =>
                    header.sortKey ? handleSort(header.sortKey) : null
                  }
                  className={cn(
                    'app-table-header-item group',
                    header.sortKey ? 'cursor-pointer' : '',
                  )}
                >
                  <div
                    className={cn(
                      header.sortKey && sortedColumn === header.sortKey
                        ? 'font-bold text-gray-700'
                        : '',
                      'relative inline-flex items-center',
                    )}
                  >
                    {header.element}
                    <span className="absolute -right-6 bottom-0">
                      {header.sortKey && sortedColumn !== header.sortKey && (
                        <span
                          className={cn(
                            'ml-1 hidden rounded bg-gray-200 text-gray-500 group-hover:block',
                            header.sortKey ? 'group-hover:opacity-100' : '',
                          )}
                        >
                          <SortIcon
                            order={
                              sortOrder === SortOrder.Asc
                                ? SortOrder.Desc
                                : SortOrder.Asc
                            }
                          />
                        </span>
                      )}
                      {header.sortKey && sortedColumn === header.sortKey && (
                        <span className="ml-1 rounded bg-gray-200 text-gray-700 group-hover:block">
                          <SortIcon order={sortOrder} />
                        </span>
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
            {rowComponents.length === 0 && (
              <tr>
                <td
                  colSpan={headers.length}
                  className="w-100 text-center text-sm text-gray-600 dark:text-gray-200"
                >
                  <p className="my-4">No results found</p>
                </td>
              </tr>
            )}
            {rowComponents
              .slice(
                rowsPerPage * (page - 1),
                Math.min(rowsPerPage * page, rowComponents.length),
              )
              .map((component: JSX.Element) => component)}
          </tbody>
        </table>
        <nav
          className="flex items-center justify-between border-t border-gray-200 bg-gray-50 pb-1 pl-3 pr-2 pt-2 dark:border-gray-800 dark:bg-gray-900"
          aria-label="Pagination"
        >
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700 dark:text-gray-400">
              <span className="font-medium">{rowComponents.length}</span>
              &nbsp;
              {`${rowComponents.length === 1 ? 'result' : 'results'},`}
              &nbsp;page&nbsp;
              <span className="font-medium">{page}</span>
            </p>
          </div>
          <div className="flex flex-1 justify-between sm:justify-end">
            <button
              onClick={previousPage}
              type="button"
              disabled={page === 1}
              className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
            <button
              onClick={nextPage}
              type="button"
              disabled={page * rowsPerPage >= rowComponents.length}
              className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </nav>
      </div>
    </>
  );
}

export default PaginatedTable;
