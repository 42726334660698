import React, { useContext, useState } from 'react';
import { Description, Label, Radio, RadioGroup } from '@headlessui/react';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import { buildLinkFromToken } from '/src/util/flowlink';

import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import API from '/src/middleware/API';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import Logger from '/src/services/logger';
import { SharedStateContext } from '../../../contexts/SharedStateContext';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { cn } from '/src/util/cn';
import { STARTUP_PROFILE_PREVIEW_ROUTE } from '/src/constants/Routes';
import { useNavigate } from 'react-router-dom';
import Alert from '/src/components/notifications/Alert';
import { ModalConfig } from '/src/interfaces/ModalConfig';
import ShareFlowLinkDialogContent from '../../investor/InvestorFlowLink/ShareFlowLinkDialogContent';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  SharedLinkSharingFlowlinkPrivacySetting,
  SharedLinkSharingResetFlowlink,
  StartupGet,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { CheckIcon } from '@heroicons/react/20/solid';
import CopyLinkInput from '/src/components/CopyLinkInput';
import ToggleSwitch from '/src/components/utility/ToggleSwitch';
import { PrivateStartup } from '/../libs/shared-types/src/types/view/startup/PrivateStartup';
import { StartupOnePagerFeatures } from '/../libs/shared-types/src/constants/StartupOnePagerFeatures';
import { FlowLink } from '../../../types/model/FlowLink';

const onePagerFeaturesSettings = [
  {
    feature: StartupOnePagerFeatures.DeckDownload,
    label: 'Allow investors to download your deck',
    secondaryLabel:
      'Viewers will be able to download the deck, and you will be able to track if they do',
  },
  {
    feature: StartupOnePagerFeatures.Round,
    label: 'Display round information',
    secondaryLabel:
      'Viewers will be able to see the information about your current round including the terms of the raise',
  },
  {
    feature: StartupOnePagerFeatures.DataRoom,
    label: 'Include password protected data room',
    secondaryLabel:
      'Viewers will be able to access the data room if they provide the password, and you will be able to track if they do',
  },
];

const privacySettings = [
  {
    boldName: 'Public:',
    name: 'Anyone with the link can view',
    description:
      'Your deck and company one-pager will be available to anyone who has your FlowLink',
    type: PublicProfilePrivacySetting.Public,
  },
  {
    boldName: 'Soft-Gated:',
    name: 'Require name and email to view',
    description:
      'Your deck and company one-pager will be soft-gated and require the investor to enter their name and email to view',
    type: PublicProfilePrivacySetting.RequireNameAndEmail,
  },
];

function getFlowlinkInstructionsText(accountType: string): JSX.Element {
  if (accountType === AccountTypes.Investor) {
    return <></>;
  }
  if (accountType === AccountTypes.Startup) {
    return (
      <p>
        Your deck, one-pager, and round information are private. They{' '}
        <b>will not be visible to anyone unless you send them your FlowLink</b>
        .
        <br />
        If your private company is based in the US,{' '}
        <b>you cannot share your FlowLink publicly on social media</b>, as that
        may constitute general solicitation, which is prohibited.
      </p>
    );
  }
  return <></>;
}

enum ModalActions {
  ShareWithFounders,
  ShareWithInvestors,
  ResetFlowLink,
}

function FlowlinkManager(): JSX.Element {
  const [startup, setStartup] = useState<PrivateStartup>();
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);

  const { linkShareToken, refreshUserMetadata, firstName, lastName } =
    useContext(AccountMetadataContext);
  const flowlink = buildLinkFromToken(linkShareToken ?? '');

  const navigate = useNavigate();

  const [modalConfig, setModalConfig] = useState<ModalConfig<ModalActions>>({
    isOpen: false,
  });
  const openModal = (dialog: ModalActions) =>
    setModalConfig({ isOpen: true, dialog });
  const closeModal = () => setModalConfig({ isOpen: false });

  function ModalContent(): JSX.Element {
    switch (modalConfig.dialog) {
      case ModalActions.ShareWithFounders:
        return (
          <ShareFlowLinkDialogContent
            shareToAccountType={AccountTypes.Startup}
          />
        );
      case ModalActions.ShareWithInvestors:
        return (
          <ShareFlowLinkDialogContent
            shareToAccountType={AccountTypes.Investor}
          />
        );
      case ModalActions.ResetFlowLink:
        return (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={resetFlowlink}
            title="Confirm Link Reset?"
            text="Any FlowLink you have previously sent will become inactive"
            primaryAction="Confirm"
            color="red"
          />
        );
      default:
        return <></>;
    }
  }

  async function resetFlowlink() {
    closeModal();
    try {
      await API.put(SharedLinkSharingResetFlowlink.buildEndpoint(), {});
      refreshUserMetadata();
    } catch (error) {
      Logger.error(error);
    }
  }

  async function fetchStartup() {
    if (localStorageGetAuthenticatedAccountType() === AccountTypes.Investor) {
      return;
    }

    try {
      const data = await API.get<PrivateStartup>(StartupGet.buildEndpoint());
      setStartup(data);
    } catch (error) {
      Logger.error(error);
    }
  }

  async function updatePrivacySetting(setting: PublicProfilePrivacySetting) {
    try {
      const updatedFlowLink = await API.post<FlowLink>(
        SharedLinkSharingFlowlinkPrivacySetting.buildEndpoint(),
        {
          privacySetting: setting,
        },
      );
      setStartup((prev) => {
        if (!prev) {
          return prev;
        }
        return {
          ...prev,
          flowLinks: [
            {
              ...prev.flowLinks[0],
              privacySetting: updatedFlowLink.privacySetting,
            },
          ],
        };
      });
    } catch (error) {
      Logger.error(error);
    }
  }

  async function updateOnePagerExclusions(
    feature: StartupOnePagerFeatures,
    isEnabled: boolean,
  ) {
    if (!startup) {
      return;
    }

    const currentExclusions = startup.flowLinks[0].excludedFromOnePager;

    // If the feature is being enabled, then it must be removed from the exclusions
    // If the feature is being disabled, then it must be added to the exclusions
    const newExclusions = isEnabled
      ? currentExclusions.filter((x) => x !== feature)
      : [...currentExclusions, feature];

    try {
      const updatedFlowLink = await API.post<FlowLink>(
        SharedLinkSharingFlowlinkPrivacySetting.buildEndpoint(),
        {
          excludedFromOnePager: newExclusions,
        },
      );
      setStartup((prev) => {
        if (!prev) {
          return prev;
        }
        return {
          ...prev,
          flowLinks: [
            {
              ...prev.flowLinks[0],
              excludedFromOnePager: updatedFlowLink.excludedFromOnePager,
            },
          ],
        };
      });
    } catch (error) {
      Logger.error(error);
    }
  }

  React.useEffect(() => {
    fetchStartup();
    setJoyrideActivationTourState({ ...joyrideActivationTourState, run: true });
  }, []);

  return (
    <>
      <section className="py-2">
        <div className="items-baseline 2xl:flex 2xl:w-full 2xl:space-x-8">
          <div className="joyride-flowLinkManager mb-4 bg-white p-0.5 shadow sm:rounded-md 2xl:mb-0 2xl:min-w-[700px]">
            <header className="rounded-t-md border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <div className="items-center justify-between sm:flex sm:flex-row">
                {localStorageGetAuthenticatedAccountType() ===
                  AccountTypes.Investor && (
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Your Secure Pitch & Connect Link
                    </h3>
                    <p className="text-sm font-medium leading-6 text-gray-500">
                      Add this to your email signature, investor website or use
                      as a link-in-bio
                    </p>
                  </div>
                )}

                {localStorageGetAuthenticatedAccountType() ===
                  AccountTypes.Startup && (
                  <>
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-gray-900">
                        Your Secure Deck & One-Pager Link
                      </h3>
                      <p className="text-sm font-medium leading-6 text-gray-500">
                        You can send this link to anyone, even if they
                        aren&apos;t on Flowlie yet.
                      </p>
                    </div>
                    <div className="my-4 flex flex-1 flex-col sm:my-0 sm:ml-4 sm:shrink-0 sm:items-end">
                      <button
                        type="button"
                        onClick={() => navigate(STARTUP_PROFILE_PREVIEW_ROUTE)}
                        className="app-button--neutral justify-center truncate"
                      >
                        Preview One-Pager
                      </button>
                    </div>
                  </>
                )}
              </div>
            </header>
            <section className="items-center p-5 sm:flex sm:flex-row">
              <CopyLinkInput
                link={flowlink}
                qrCodeConfig={{
                  title: `Scan to connect with ${firstName} ${lastName}`,
                  allowDownload: true,
                }}
              />

              <div className="my-4 flex flex-1 flex-col sm:my-0 sm:ml-4 sm:shrink-0 sm:items-end">
                <button
                  onClick={() => openModal(ModalActions.ResetFlowLink)}
                  type="button"
                  className="app-button--red justify-center truncate"
                >
                  Reset Link
                </button>
              </div>
            </section>

            <div className="max-w-full px-5 pb-5 text-base text-gray-500">
              {localStorageGetAuthenticatedAccountType() ===
                AccountTypes.Startup &&
                startup && (
                  <div className="flex flex-col space-y-2">
                    <Alert
                      alertType="Info"
                      canDismiss={false}
                      color="blue"
                      content={getFlowlinkInstructionsText(
                        localStorageGetAuthenticatedAccountType(),
                      )}
                      isShown
                      onClose={() => false}
                    />
                  </div>
                )}
            </div>
          </div>
          {localStorageGetAuthenticatedAccountType() === AccountTypes.Startup &&
            startup && (
              <div className="mx-1 my-4 bg-white p-0.5 shadow sm:rounded-md">
                <div className="bg-white sm:rounded-md">
                  <header className="rounded-t-md border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                    <h4 className="text-lg font-bold leading-6 text-gray-900">
                      Privacy Settings
                    </h4>
                    <p className="text-sm font-medium leading-6 text-gray-500">
                      Control how your one-pager is accessed
                    </p>
                  </header>
                  <RadioGroup
                    className="my-1 w-full"
                    value={privacySettings.find(
                      (setting) =>
                        setting.type === startup.flowLinks[0].privacySetting,
                    )}
                    onChange={async (setting) =>
                      await updatePrivacySetting(setting.type)
                    }
                  >
                    <Label className="sr-only">Privacy setting</Label>
                    <div>
                      {privacySettings.map((setting) => (
                        <Radio
                          key={setting.name}
                          value={setting}
                          className={({ checked }) =>
                            cn(
                              checked ? 'z-10 bg-gray-100' : '',
                              'relative my-1 flex cursor-pointer rounded-md p-4 transition-all hover:bg-gray-100 focus:outline-none',
                            )
                          }
                        >
                          {({ focus, checked }) => (
                            <>
                              <span
                                className={cn(
                                  checked
                                    ? 'border-transparent bg-blue-600'
                                    : 'border-gray-300 bg-white',
                                  focus
                                    ? 'ring-2 ring-blue-500 ring-offset-2'
                                    : '',
                                  'mt-0.5 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border',
                                )}
                                aria-hidden="true"
                              >
                                <CheckIcon
                                  className={cn(
                                    !checked ? 'invisible' : '',
                                    'h-4 w-4 text-white',
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                              <div className="ml-3 flex flex-col">
                                <Label
                                  as="span"
                                  className={cn(
                                    checked ? 'text-blue-700' : 'text-gray-900',
                                    'block text-sm font-medium',
                                  )}
                                >
                                  <span className="flex items-center space-x-2">
                                    <b>{setting.boldName}</b>&nbsp;
                                    {setting.name}
                                    {setting.type ===
                                      PublicProfilePrivacySetting.RequireNameAndEmail && (
                                      <span
                                        title="Most startups choose this option"
                                        className="inline-flex items-center rounded-full bg-blue-100 px-2 py-0.5 text-2xs font-medium text-blue-800"
                                      >
                                        Industry Standard
                                      </span>
                                    )}
                                  </span>
                                </Label>
                                <Description
                                  as="span"
                                  className={cn(
                                    checked ? 'text-gray-700' : 'text-gray-500',
                                    'block text-sm',
                                  )}
                                >
                                  {setting.description}
                                </Description>
                              </div>
                            </>
                          )}
                        </Radio>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              </div>
            )}
        </div>

        {localStorageGetAuthenticatedAccountType() === AccountTypes.Startup &&
          startup && (
            <section>
              <div className="mx-1 my-4 w-fit bg-white p-0.5 shadow sm:rounded-md">
                <div className="bg-white sm:rounded-md">
                  <header className="rounded-t-md border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      One-Pager Settings
                    </h3>
                    <h4 className="text-sm font-medium leading-6 text-gray-500">
                      Choose what to include in your one-pager
                    </h4>
                  </header>
                  <div className="space-y-6 px-4 py-5 sm:px-6">
                    {onePagerFeaturesSettings.map((setting) => (
                      <ToggleSwitch
                        key={setting.label}
                        showToggleIcon
                        toggleOn={async () =>
                          await updateOnePagerExclusions(setting.feature, true)
                        }
                        toggleOff={async () =>
                          await updateOnePagerExclusions(setting.feature, false)
                        }
                        name={setting.label}
                        label={setting.label}
                        secondaryLabel={setting.secondaryLabel}
                        initialState={
                          !startup.flowLinks[0].excludedFromOnePager.includes(
                            setting.feature,
                          )
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )}

        <ModalWrapper open={modalConfig.isOpen} onClose={() => closeModal()}>
          <ModalContent />
        </ModalWrapper>
      </section>
    </>
  );
}

export default FlowlinkManager;
