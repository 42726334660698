import React, { useState, MouseEvent, ReactNode } from 'react';
import { InvestorListItemView } from '../../../types/view/InvestorListView';
import { cn } from '/src/util/cn';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import { StartupRemoveInvestorFromInvestorList } from '/../libs/shared-types/src/constants/ApiRoutes';
import DropdownButton from '/src/components/DropdownButton';
import { Menu, MenuButton, MenuItem } from '@headlessui/react';
import {
  CurrencyDollarIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { UsersIcon } from '@heroicons/react/20/solid';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { StartupRelationship } from '../../../types/model/StartupRelationship';
import {
  TARGET_LIST_DETAILS_ACTION,
  TargetListActionProps,
} from '/src/routes/startup/InvestorListDetail/InvestorListDetailRoute';
import UpdateStartupRelationshipDialog from '/src/routes/startup/RoundManager/InvestorPipeline/UpdateStartupRelationshipDialog';
import { updateStartupRelationship } from '/src/services/startupService';

export interface TargetInvestorActionProps {
  investor: InvestorListItemView;
  listId: string;
  onAction: ({ type, payload }: TargetListActionProps) => void;
}

async function onRemoveInvestorFromList({
  listId,
  investorId,
}: {
  listId: string;
  investorId: string;
}) {
  let isSuccess = false;
  try {
    await API.put(StartupRemoveInvestorFromInvestorList.buildEndpoint(), {
      listId: listId,
      investorId: investorId,
    });
    isSuccess = true;
  } catch (error) {
    Logger.error(error);
  } finally {
    return { isSuccess };
  }
}

function InvestorCellTargetDetailAction({
  investor,
  listId,
  onAction,
}: TargetInvestorActionProps) {
  const [modalConfig, setModalConfig] = useState<{
    type: undefined | 'updateStartupRelationship';
    isOpen: boolean;
    investor: InvestorListItemView | undefined;
  }>({
    type: undefined,
    isOpen: false,
    investor: undefined,
  });

  function closeModal() {
    setModalConfig({ type: undefined, isOpen: false, investor: undefined });
  }

  const handleUpdateStartupRelationship = (investor: InvestorListItemView) =>
    setModalConfig({
      type: 'updateStartupRelationship',
      isOpen: true,
      investor,
    });

  return (
    <div
      className="app-table-row-text"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      aria-hidden="true"
    >
      <DropdownButton
        label={'Manage Investor'}
        button={
          <MenuButton
            className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={(e: any) => e.stopPropagation()}
          >
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </MenuButton>
        }
      >
        <MenuItem>
          {({ focus }) => (
            <button
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                handleUpdateStartupRelationship(investor);
              }}
              className={cn(
                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Update investor relationship"
            >
              <UsersIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              Update Relationship
            </button>
          )}
        </MenuItem>
        <MenuItem>
          {({ focus }) => (
            <button
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                onAction({
                  type: TARGET_LIST_DETAILS_ACTION.ADD_TO_PIPELINE,
                  payload: {
                    investorId: investor.investorId,
                    investorDataType: investor.investorDataType,
                  },
                });
              }}
              className={cn(
                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Add investor to Pipeline"
            >
              <CurrencyDollarIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              Add to Pipeline
            </button>
          )}
        </MenuItem>
        <MenuItem>
          {({ focus }) => (
            <button
              type="button"
              onClick={async (e: any) => {
                e.stopPropagation();
                const { isSuccess } = await onRemoveInvestorFromList({
                  listId,
                  investorId: investor.investorId,
                });
                if (isSuccess) {
                  onAction({
                    type: TARGET_LIST_DETAILS_ACTION.REFRESH,
                  });
                }
              }}
              className={cn(
                focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex w-full items-center px-4 py-2 text-sm',
              )}
              title="Remove investor from this List"
            >
              <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              Remove from List
            </button>
          )}
        </MenuItem>
      </DropdownButton>

      <ModalWrapper open={modalConfig.isOpen} onClose={() => null}>
        {modalConfig.type === 'updateStartupRelationship' &&
          modalConfig.investor && (
            <UpdateStartupRelationshipDialog
              connectionName={modalConfig.investor.name}
              connectionDataType={modalConfig.investor.investorDataType}
              relationship={modalConfig.investor.relationship}
              onCancel={closeModal}
              onSave={async (update: Partial<StartupRelationship>) => {
                if (!modalConfig.investor) {
                  return Promise.resolve();
                }

                const { isSuccess } = await updateStartupRelationship(
                  modalConfig.investor.investorId,
                  modalConfig.investor.investorDataType,
                  update,
                );
                closeModal();
                if (isSuccess) {
                  onAction({
                    type: TARGET_LIST_DETAILS_ACTION.REFRESH,
                  });
                }
              }}
            />
          )}
      </ModalWrapper>
    </div>
  );
}

export default InvestorCellTargetDetailAction;

export interface ActionUpdateInvRelationshipProps {
  focus: boolean;
  investor: InvestorListItemView;
  onAction: ({ type, payload }: TargetListActionProps) => void;
}

export function ActionUpdateInvRelationship({
  focus,
  investor,
  onAction,
}: ActionUpdateInvRelationshipProps) {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleOnUpdateRelationship = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          handleOnUpdateRelationship();
        }}
        className={cn(
          focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
          'group flex w-full items-center px-4 py-2 text-sm',
        )}
        title="Update investor relationship"
      >
        <UsersIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Update Relationship
      </button>

      <ModalWrapper open={isOpen} onClose={() => null}>
        {investor && (
          <UpdateStartupRelationshipDialog
            connectionName={investor.name}
            connectionDataType={investor.investorDataType}
            relationship={investor.relationship}
            onCancel={closeModal}
            onSave={async (update: Partial<StartupRelationship>) => {
              if (!investor) {
                return;
              }

              const { isSuccess } = await updateStartupRelationship(
                investor.investorId,
                investor.investorDataType,
                update,
              );

              if (isSuccess) {
                closeModal();
                onAction({
                  type: TARGET_LIST_DETAILS_ACTION.REFRESH,
                });
              }
            }}
          />
        )}
      </ModalWrapper>
    </>
  );
}

export interface ActionAddToPipelineProps {
  focus: boolean;
  investor: InvestorListItemView;
  onAction: ({ type, payload }: TargetListActionProps) => void;
}

export function ActionAddToPipeline({
  focus,
  investor,
  onAction,
}: ActionAddToPipelineProps) {
  return (
    <button
      type="button"
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onAction({
          type: TARGET_LIST_DETAILS_ACTION.ADD_TO_PIPELINE,
          payload: {
            investorId: investor.investorId,
            investorDataType: investor.investorDataType,
          },
        });
      }}
      className={cn(
        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        'group flex w-full items-center px-4 py-2 text-sm',
      )}
      title="Add investor to Pipeline"
    >
      <CurrencyDollarIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
      Add to Pipeline
    </button>
  );
}

export interface CellActionProps {
  focus: boolean;
  icon: ReactNode;
  title: string;
  onAction: () => void;
}

export function CellAction({ focus, title, icon, onAction }: CellActionProps) {
  return (
    <button
      type="button"
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onAction();
      }}
      className={cn(
        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        'group flex w-full items-center px-4 py-2 text-sm',
      )}
      title={title}
    >
      {icon}
      {title}
    </button>
  );
}

export interface CellMultiActionProps<T> {
  label: string;
  actions: Array<{ title: string; icon: ReactNode; action: T }>;
  onAction: (action: T) => void;
}

export function CellMultiAction<T>({
  label,
  actions,
  onAction,
}: CellMultiActionProps<T>) {
  return (
    <>
      <div className="app-table-row-text">
        <DropdownButton
          label={label}
          button={
            <MenuButton
              className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                e.stopPropagation()
              }
            >
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          }
        >
          {actions.map(({ title, icon, action }) => (
            <MenuItem key={title}>
              {({ focus }) => (
                <button
                  type="button"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onAction(action);
                  }}
                  className={cn(
                    focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm',
                  )}
                  title={title}
                >
                  {icon}
                  {title}
                </button>
              )}
            </MenuItem>
          ))}
        </DropdownButton>
      </div>
    </>
  );
}
