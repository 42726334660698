import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { toDashCase } from '/src/util/formatting/strings';
import TabsHeader from '/src/components/tabs/TabsHeader';
import { ComparablePersonView } from '../../../types/view/PersonView';
import { IntroPathPaginatedCollectionResponse } from '../../../types/view/IntroPathView';
import MyIntroPaths from './MyIntroPaths';
import useGetInvestorIntroPaths from '/src/hooks/useGetInvestorIntroPaths';

export const defaultSortKeyPersons = 'name' as keyof ComparablePersonView;

function ManageIntrosRoute(): JSX.Element {
  useState<IntroPathPaginatedCollectionResponse>();

  const { introPaths, isFirstLoading, isLoading, refetch } =
    useGetInvestorIntroPaths();

  const tabs = [
    {
      name: 'Intro Paths',
      stat: introPaths?.totalCount ?? 0,
    },
  ];

  return (
    <main>
      <header>
        <h2 className="page-title">Manage Intros</h2>
        <TabsHeader tabs={tabs} />
      </header>

      <section className="my-4">
        {isFirstLoading && (
          <PageLoadingSpinner message="Loading your intro paths... 🤝" />
        )}

        {!isFirstLoading && (
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <MyIntroPaths
                      apiResponse={introPaths}
                      isSearchLoading={isLoading}
                      refetch={refetch}
                    />
                  }
                />
              ))}
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        )}
      </section>
    </main>
  );
}

export default ManageIntrosRoute;
