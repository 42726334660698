import React, { ReactNode } from 'react';
import { BaseMapper } from '/src/util/mapper';
import TagCell from '/src/components/table/cells/TagCell';
import CellCheckSize from '/src/components/table/cells/InvestorCellCheckSize';
import CellFirmAndRole from '/src/components/table/cells/InvestorCellFirmRole';
import CellIntroduction from '/src/components/table/cells/InvestorCellName';
import CellEmail from '/src/components/table/cells/InvestorCellEmail';
import SocialHandles, { SocialHandleName } from '/src/components/SocialHandles';
import DateString from '/src/components/utility/DateString';
import CellImportedInvestorAction from '/src/components/table/cells/CellImportedInvestorAction';
import { INVESTOR_ACTION } from './InvestorMapper';
import FitLabelTagCell from '/src/components/table/cells/FitLabelTagCell';
import { InvestorFragmentView } from '../../../types/view/InvestorFragmentView';
import InvestorTypeTagCell from '/src/components/table/cells/InvestorTypeTagCell';

export type ImportedInvestorActionProps =
  | {
      type: INVESTOR_ACTION.REFRESH;
      payload?: undefined;
    }
  | {
      type: INVESTOR_ACTION.ADD_TO_TARGET_LIST;
      payload: InvestorFragmentView;
    };

export interface TargetInvestor extends Record<string, ReactNode> {
  id: string;
  name: ReactNode;
  type: ReactNode;
  firm: ReactNode;
  email: ReactNode;
  links: ReactNode;
  fitLabel: ReactNode;
  checkRangeMin: ReactNode;
  leadsRounds: ReactNode;
  createdOn: ReactNode;
  action: ReactNode;
}

export class ImportedInvestorMapper extends BaseMapper<
  InvestorFragmentView,
  TargetInvestor
> {
  private _onAction?: ({ type, payload }: ImportedInvestorActionProps) => void;
  constructor(
    onAction?: ({ type, payload }: ImportedInvestorActionProps) => void,
  ) {
    super();
    this._onAction = onAction;
  }

  mapTo(investor: InvestorFragmentView) {
    return {
      id: investor._id,
      name: (
        <CellIntroduction
          name={`${investor.firstName} ${investor.lastName}`}
          subtitle={investor.location}
          isConnectedOnLinkedIn={investor?.relationship?.isConnectedOnLinkedIn}
        />
      ),
      type: <InvestorTypeTagCell investorType={investor.type} />,
      firm: <CellFirmAndRole firm={investor.firm} role={investor.role} />,
      email: <CellEmail email={investor.email} />,
      links: (
        <SocialHandles
          socialUrls={[
            {
              name: SocialHandleName.LinkedIn,
              url: investor.linkedIn,
            },
            { name: SocialHandleName.Other, url: investor.website },
          ]}
        />
      ),
      fitLabel: <FitLabelTagCell fitLabel={investor.fitLabel} />,
      checkRangeMin: (
        <CellCheckSize
          checkRangeMax={investor.checkRangeMax}
          checkRangeMin={investor.checkRangeMin}
        />
      ),
      leadsRounds: <TagCell tag={investor.leadsRounds} type="comparison" />,
      createdOn: (
        <div className="app-table-row-text">
          <DateString date={investor.createdOn} tooltipPosition="bottom" />
        </div>
      ),
      action: this._onAction ? (
        <CellImportedInvestorAction
          investor={investor}
          onAction={this._onAction}
        />
      ) : (
        <></>
      ),
    };
  }
}
