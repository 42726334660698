import { TooltipSelectOption } from '../types/SelectOptions';

export enum PipelineInvestorSource {
  ColdOutbound = 'Cold Outbound',
  ColdInbound = 'Cold Inbound',
  WarmIntro = 'Warm Intro',
  ExistingRelationship = 'Existing Relationship',
}

export const PIPELINE_INVESTOR_SOURCE_OPTIONS: TooltipSelectOption[] = [
  {
    value: PipelineInvestorSource.ColdOutbound,
    label: PipelineInvestorSource.ColdOutbound,
    tooltipText: 'I reached out cold to this investor',
  },
  {
    value: PipelineInvestorSource.ColdInbound,
    label: PipelineInvestorSource.ColdInbound,
    tooltipText: 'This investor reached out to me cold',
  },
  {
    value: PipelineInvestorSource.WarmIntro,
    label: PipelineInvestorSource.WarmIntro,
    tooltipText: 'I got introduced to this investor by someone',
  },
  {
    value: PipelineInvestorSource.ExistingRelationship,
    label: PipelineInvestorSource.ExistingRelationship,
    tooltipText: 'I already knew this investor prior to the round',
  },
];
