import React, { createContext, useEffect, useState } from 'react';
import API from '../middleware/API';
import Logger from '../services/logger';
import { UserMetadata } from '/../libs/shared-types/src/types/view/UserMetadata';
import { localStorageGetAccountEmail } from '../middleware/LocalStorage';
import { updateHeapUserSubscriptionTier } from '../middleware/Heap';
import { PublicLogIn } from '/../libs/shared-types/src/constants/ApiRoutes';
import AuthService from '../services/AuthService';

interface AccountMetadataContextType extends Partial<UserMetadata> {
  refreshUserMetadata: () => void;
}

const AccountMetadataContext = createContext<AccountMetadataContextType>(
  {} as AccountMetadataContextType,
);

const AccountMetadataProvider = ({
  children,
}: {
  children: string | JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const [userMetadata, setUserMetdata] = useState<UserMetadata>();

  async function fetchAndSetUserMetadata() {
    const email = localStorageGetAccountEmail();
    if (!email) {
      // If no email is found, log the user out?
      AuthService.logout();
    }

    try {
      const user = await API.get<UserMetadata>(
        PublicLogIn.buildEndpoint(email),
      );
      updateHeapUserSubscriptionTier(user.subscriptionTier);
      setUserMetdata(user);
    } catch (error: any) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    fetchAndSetUserMetadata();
  }, []);

  return (
    <AccountMetadataContext.Provider
      value={{
        accountType: userMetadata?.accountType,
        companyName: userMetadata?.companyName,
        email: userMetadata?.email,
        firstName: userMetadata?.firstName,
        flowliePartner: userMetadata?.flowliePartner,
        id: userMetadata?.id,
        investorType: userMetadata?.investorType,
        lastName: userMetadata?.lastName,
        profilePicKey: userMetadata?.profilePicKey,
        token: userMetadata?.token,
        unconfirmedUser: userMetadata?.unconfirmedUser,
        subscriptionTier: userMetadata?.subscriptionTier,
        linkShareToken: userMetadata?.linkShareToken,
        refreshUserMetadata: fetchAndSetUserMetadata,
        subscriptionStatus: userMetadata?.subscriptionStatus,
        requiredPolicies: userMetadata?.requiredPolicies,
        hasUsedTrial: userMetadata?.hasUsedTrial,
      }}
    >
      {children}
    </AccountMetadataContext.Provider>
  );
};

export { AccountMetadataProvider, AccountMetadataContext };
