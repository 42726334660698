import React from 'react';
import {
  abbreviateNumber,
  getPluralSuffix,
  numberWithCommas,
} from '/src/util/formatting/numbers';
import { isRoundClosedAndPastGracePeriod, isRoundOpen } from '/src/util/rounds';
import { calculateAmountRemaining } from '/../libs/shared-types/src/util/RoundCalculations';
import { formatDaysUntil } from '/src/util/formatting/dates';
import DateString from '/src/components/utility/DateString';
import { getRoundInsightsTags } from '/src/components/RoundCard';
import ListSummaryCard from '/src/components/cards/ListSummaryCard';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { PublicStartup } from '/../libs/shared-types/src/types/view/startup/PublicStartup';
import { StartupOnePagerFeatures } from '/../libs/shared-types/src/constants/StartupOnePagerFeatures';

interface DealDetailRoundSummaryCardProps {
  currentRound: RoundView | undefined;
  startup: PublicStartup;
}

function DealDetailRoundSummaryCard({
  currentRound,
  startup,
}: DealDetailRoundSummaryCardProps): JSX.Element {
  if (
    startup.flowLink.excludedFromOnePager.includes(
      StartupOnePagerFeatures.Round,
    )
  ) {
    return <></>;
  }

  const conversionTermMonths =
    currentRound && currentRound.conversionTerm
      ? `${currentRound.conversionTerm} Month${getPluralSuffix(
          currentRound.conversionTerm,
        )}`
      : undefined;

  const amountRemaining = calculateAmountRemaining(
    currentRound?.raiseTarget || 0,
    currentRound?.raisedAmount || 0,
  );
  const roundSummaryCardData = {
    Round: currentRound?.roundDisplayName,
    Type: `${currentRound?.roundType} ${currentRound?.safeType ?? ''}`,
    Raising: `$ ${abbreviateNumber(currentRound?.raiseTarget)}`,
    [`${currentRound?.valuationCapType} Valuation`]: currentRound?.valuationCap
      ? `$ ${abbreviateNumber(currentRound?.valuationCap)}`
      : undefined,
    Discount:
      currentRound?.discount !== undefined
        ? `${currentRound?.discount} %`
        : undefined,
    Interest:
      currentRound?.interest !== undefined
        ? `${currentRound?.interest} %`
        : undefined,
    'Conversion Term': conversionTermMonths,
    'Price Per Share':
      currentRound?.pricePerShare !== undefined
        ? `$ ${numberWithCommas(currentRound?.pricePerShare, 4)}`
        : undefined,
    Remaining:
      amountRemaining > 0 ? `$ ${abbreviateNumber(amountRemaining)}` : `$ ${0}`,
    'Closing In':
      currentRound?.estimatedCloseDate && isRoundOpen(currentRound)
        ? formatDaysUntil(currentRound?.estimatedCloseDate)
        : undefined,
    // Passing an empty key because we want to only display the tags
    ' ':
      currentRound && getRoundInsightsTags(currentRound).length > 0
        ? getRoundInsightsTags(currentRound)
        : undefined,
  };

  return (
    <>
      <div>
        <ListSummaryCard
          headerTitle="Fundraising Round"
          dataObj={roundSummaryCardData}
          notificationObj={{
            showNotification:
              !currentRound || isRoundClosedAndPastGracePeriod(currentRound),
            notificationLabelText: 'Not currently fundraising',
          }}
          classElm={{
            title: 'flex text-lg leading-6 font-medium text-gray-900',
            headerContainer: `flex flex-wrap rounded-t-lg justify-between 
                       align-middle border-b shadow`,
            bodyContainer: 'rounded-b-lg shadow',
            keyElms: 'text-sm font-medium text-gray-500',
            valElm: 'text-sm font-medium space-x-2 space-y-2 text-gray-900  ',
            notificationContainer: `flex justify-center items-center pl-6 h-18
                       font-medium rounded-b-lg shadow`,
            notificationLabel: 'app-tag app-tag--red',
          }}
        >
          <>
            {currentRound && isRoundOpen(currentRound) && (
              <div className="flex items-center text-gray-500">
                <span className="mr-1 text-sm">Last updated</span>
                <b className="text-sm">
                  <DateString
                    date={currentRound.modifiedOn}
                    tooltipPosition="bottom"
                  />
                </b>
              </div>
            )}

            {currentRound && !isRoundOpen(currentRound) && (
              <div className="flex items-center text-gray-500">
                <span className="mr-1 text-sm">Last round closed</span>
                <b className="text-sm">
                  <DateString
                    date={currentRound.closedOn}
                    tooltipPosition="bottom"
                  />
                </b>
              </div>
            )}
          </>
        </ListSummaryCard>
      </div>
    </>
  );
}

export default DealDetailRoundSummaryCard;
