import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatImageAddress } from '/src/util/formatting/strings';
import { cn } from '/src/util/cn';
import { generateTag, generateTagCloud } from '/src/util/generateTagCloud';
import { InvestorConnectionView } from '/../libs/shared-types/src/types/view/APIResponse';
import CopyTextButton from '/src/components/CopyTextButton';
import { displayMoneyRange } from '/src/util/formatting/numbers';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import { INVESTOR_DETAIL } from '/src/constants/Routes';

interface ConnectionsTableRowProps {
  contact: InvestorConnectionView;
  className: string;
}

function ConnectionsTableRow({
  contact,
  className,
}: ConnectionsTableRowProps): JSX.Element {
  const navigate = useNavigate();
  const handleOnClick = React.useCallback(
    () => navigate(`${INVESTOR_DETAIL}/${contact.id}`),
    [history],
  );

  return (
    <tr
      onClick={handleOnClick}
      className={cn('cursor-pointer hover:bg-gray-100', className)}
    >
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <UserAvatarWithInitials
              containerStyles="h-10 w-10"
              firstName={contact.firstName}
              lastName={contact.lastName}
              textStyles="text-xl"
              imgAlt="Investor Profile"
              imgSrc={formatImageAddress(contact.profilePicKey)}
            />
          </div>
          <div className="ml-2">
            <div className="app-table-row-text--bold">{`${contact.firstName} ${contact.lastName}`}</div>
            <div className="text-sm text-gray-500">
              <a
                className="truncate hover:underline"
                href={`mailto:${contact.email}`}
                onClick={(e) => e.stopPropagation()}
              >
                {contact.email}
              </a>
              <CopyTextButton text={contact.email} className="absolute ml-1" />
            </div>
          </div>
        </div>
      </td>

      <td className="whitespace-nowrap px-4 py-4">
        {generateTag(contact.type, true)}
      </td>
      <td className="app-table-row-item">
        {contact.firm && contact.role ? (
          <>
            <div className="app-table-row-text">{contact.firm}</div>
            <div className="app-table-row-text--light">{contact.role}</div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{contact.location}</div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {generateTagCloud(contact.invStages)}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {displayMoneyRange(contact.checkRangeMin, contact.checkRangeMax)}
        </div>
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {generateTag(contact.leadsRounds)}
        </div>
      </td>
    </tr>
  );
}

export default ConnectionsTableRow;
