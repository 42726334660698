import React, { Fragment, useContext, useState } from 'react';
import { Link, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';

import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { cleanStartupData } from './StartupFormValuesCleaner';
import { convertDatePickerInputToUtc } from '/src/util/formatting/dates';
import { FormikStep, FormikStepper } from '../multiStepForm/FormikStepper';
import { Founder } from '/../libs/shared-types/src/types/model/Founder';
import {
  heapIdentify,
  updateHeapStartupUser,
  updateHeapUserSubscriptionTier,
} from '/src/middleware/Heap';
import { LinkTypes } from '/../libs/shared-types/src/constants/LinkshareTypes';
import {
  localStorageGetAccountEmail,
  localStorageSetAccountFirstLastName,
  localStorageSetAccountTypeAndAuthenticationToken,
} from '/src/middleware/LocalStorage';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import { Startup } from '/../libs/shared-types/src/types/model/Startup';
import { uploadPdfDeck, uploadFile } from '/src/util/FileHelpers';
import API from '/src/middleware/API';
import emptyStartup from './EmptyStartup';
import FounderFieldNames from '/src/enums/FounderFieldNames';
import Logger from '/src/services/logger';
import { removeUnsetOptionalValues } from '/src/util/forms';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import steps from './MultiStepFormSteps';
import CompleteProfileSuccess from './CompleteProfileSuccess';
import { formatImageAddress } from '/src/util/formatting/strings';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { NewAccountView } from '/../libs/shared-types/src/types/view/NewAccountView';
import { getUnconfirmedUserByEmail } from '/src/services/UnconfirmedUser';
import { UNCONFIRMED_USER_NOT_FOUND } from '/src/constants/ErrorMessages';
import UserAvatarWithInitials from '../UserAvatarWithInitials';
import { getLinkShareMetadata } from '/src/services/LinkShareMetadata';
import { parseFlowlieAuthToken } from '/src/services/jwt';
import { AuthenticationContext } from '/src/contexts/AuthenticationContext';
import { Invitation } from '/../libs/shared-types/src/types/model/UnconfirmedUser';
import { getOnboardingFlow } from '/../libs/shared-types/src/extensions/OnboardingFlowsExtensions';
import { Posthog } from '/src/middleware/Posthog';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { PUBLIC_NOT_FOUND_ROUTE } from '/src/constants/Routes';
import {
  CognitoCreateNewStartup,
  CognitoUploadPublic,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import Alert from '../notifications/Alert';
import SquaredLogo from '../SquaredLogo';
import { getUserIpAddress } from '/src/services/GetUserIpAddress';

const DECK_UPLOAD_ERROR = {
  key: 'deck_upload_error.pdf',
  pageCount: 0,
};
const DECK_UPLOAD_ERROR_FILE_NAME = 'deck_upload_error';

const InfoHeader = () => {
  const [textContent, setTextContent] = useState('');
  const [invitedByImages, setInvitedByImages] = useState<
    {
      firstName: string;
      lastName: string;
      imageKey: string;
      accountType: AccountTypes;
    }[]
  >([]);
  const { invitation } = useContext(AuthenticationContext);

  const fetchLinkData = async () => {
    if (!invitation) {
      setTextContent('Complete to get full access to Flowlie');
      return;
    }
    const user = await getLinkShareMetadata(
      invitation.shareToken,
      invitation.linkType,
    );
    if (!user) {
      return;
    }

    if (invitation.linkType === LinkTypes.Flowlink && user.investor) {
      setTextContent(
        `Submitting your startup for review to ${user.investor.firstName} ${
          user.investor.lastName
        } (${
          user.investor.type === InvestorTypes.Angel
            ? 'Angel'
            : user.investor.firm
        })`,
      );

      setInvitedByImages([
        {
          firstName: user.investor.firstName,
          lastName: user.investor.lastName,
          imageKey: user.investor.profilePicKey ?? '',
          accountType: AccountTypes.Investor,
        },
      ]);
    } else if (invitation.linkType === LinkTypes.Flowlink && user.startup) {
      setTextContent(
        `${user.startup.accountFirstName}, founder of ${user.startup.name}, is inviting you to join Flowlie`,
      );
      setInvitedByImages([
        {
          firstName: user.startup.accountFirstName,
          lastName: user.startup.accountFirstName,
          imageKey: user.startup.logoKey,
          accountType: AccountTypes.Startup,
        },
      ]);
    } else if (
      invitation.linkType === LinkTypes.Dealshare &&
      user.startup &&
      user.investor
    ) {
      setTextContent(
        `${user.investor?.firstName} ${user.investor?.lastName} is inviting you to join Flowlie`,
      );

      setInvitedByImages([
        {
          firstName: user.investor.firstName,
          lastName: user.investor.lastName,
          imageKey: user.investor.profilePicKey ?? '',
          accountType: AccountTypes.Investor,
        },
        {
          firstName: user.startup.accountFirstName,
          lastName: user.startup.accountFirstName,
          imageKey: user.startup.logoKey,
          accountType: AccountTypes.Startup,
        },
      ]);
    }
  };

  React.useEffect(() => {
    fetchLinkData();
  }, []);

  return (
    <div className="mb-10">
      <div className="flex flex-row items-center justify-center">
        {invitedByImages.map((image, index) => (
          <div key={image.imageKey + index}>
            {image.accountType === AccountTypes.Investor && (
              <UserAvatarWithInitials
                containerStyles="h-16 w-16 my-1 mx-2"
                firstName={image.firstName}
                lastName={image.lastName}
                textStyles="text-2xl"
                imgAlt={`Invited by ${image.firstName}}`}
                imgSrc={formatImageAddress(image.imageKey)}
              />
            )}
            {image.accountType === AccountTypes.Startup && (
              <SquaredLogo
                containerClassName="size-16 my-1 mx-2"
                alt={`Invited by ${image.firstName}}`}
                src={formatImageAddress(image.imageKey)}
              />
            )}
          </div>
        ))}
      </div>
      {textContent && (
        <p className="-mt-3 rounded-full bg-white px-6 py-3 text-sm tracking-wide text-gray-500 shadow-sm">
          {textContent}
        </p>
      )}
    </div>
  );
};

function StartupMultiStepForm(): JSX.Element {
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const { setToastConfiguration } = useContext(SharedStateContext);
  const { setInvitation } = useContext(AuthenticationContext);
  const [consumedInvitationData, setConsumedInvitationData] =
    useState<Invitation>();
  const navigate = useNavigate();

  async function createNewStartup(formValues: any) {
    const accountEmail = localStorageGetAccountEmail();
    const unconfirmedUser = await getUnconfirmedUserByEmail(accountEmail);

    if (!unconfirmedUser) {
      throw Error(UNCONFIRMED_USER_NOT_FOUND);
    }
    Logger.warn('unconfirmed user fetched');
    const updatedData = removeUnsetOptionalValues(formValues);

    const startupProfile: Startup = {
      ...updatedData,

      // Date picker inputs must be converted to a UTC date
      foundedOn: convertDatePickerInputToUtc(new Date(formValues.foundedOn)),

      accountFirstName: unconfirmedUser.firstName,
      accountLastName: unconfirmedUser.lastName,
      accountEmail: unconfirmedUser.email,
    };

    const founders = [
      {
        [FounderFieldNames.FirstName]: startupProfile.accountFirstName,
        [FounderFieldNames.LastName]: startupProfile.accountLastName,
        [FounderFieldNames.Email]: startupProfile.accountEmail,
        [FounderFieldNames.Role]: updatedData[StartupFieldNames.FounderRole],
        [FounderFieldNames.IsTechnical]:
          updatedData[StartupFieldNames.FounderIsTechnical],
        [FounderFieldNames.LinkedIn]:
          updatedData[StartupFieldNames.FounderLinkedIn],
      },
    ];
    // NOTE: Type casting is needed because Founder requires _id and createdOn, but those are set by DB
    startupProfile.founders = founders as Founder[];

    // remove nulls from the above call to remove options values
    Object.keys(startupProfile).forEach(
      (key) =>
        startupProfile[key as keyof Startup] === null &&
        delete startupProfile[key as keyof Startup],
    );

    localStorageSetAccountFirstLastName(
      startupProfile.accountFirstName,
      startupProfile.accountLastName,
    );

    const logoKey = await uploadFile(
      formValues.logo,
      CognitoUploadPublic.buildEndpoint(),
    );
    Logger.warn('logo uploaded');
    const uploadResult =
      (await uploadPdfDeck(formValues.deck)) ?? DECK_UPLOAD_ERROR;
    Logger.warn('deck uploaded');

    //
    // If the any file upload fails, set the file keys to a default value, so the user can still complete onboarding
    // - The logoKey will be set to empty string
    // - The deck.key, deck.fileName, deck.pageCount will be set to the DECK_UPLOAD_ERROR constants
    //
    startupProfile.logoKey = logoKey ?? '';
    startupProfile.decks = [
      {
        _id: '',
        changeReason: '',
        createdOn: new Date(),
        fileName:
          uploadResult.key === DECK_UPLOAD_ERROR.key
            ? DECK_UPLOAD_ERROR_FILE_NAME
            : formValues.deck.name,
        key: uploadResult.key,
        pageCount: uploadResult.pageCount,
        version: { major: 0, minor: 0 },
      },
    ];

    const newAccountResult = await API.post<NewAccountView<Startup>>(
      CognitoCreateNewStartup.buildEndpoint(),
      { startupProfile },
    );
    Logger.warn('new account data posted');
    if (!newAccountResult || !newAccountResult.flowlieAuthenticationToken) {
      throw Error('Startup profile could not be created');
    }

    const posthogClient = new Posthog();
    posthogClient.addUserProperties({
      invited_by_name: newAccountResult.invitation?.fullName,
      company_name: newAccountResult.profile.name,
      mongoid_onboarded: newAccountResult.profile._id,
      mongoid_unconfirmed: unconfirmedUser._id,
      type: AccountTypes.Startup,
      sub_type: AccountTypes.Startup,
      onboarding_flow: getOnboardingFlow(
        AccountTypes.Startup,
        newAccountResult.invitation?.accountType,
        newAccountResult.invitation?.linkType,
      ),
    });

    setInvitation(undefined);
    setConsumedInvitationData(newAccountResult.invitation);
    localStorageSetAccountTypeAndAuthenticationToken(
      AccountTypes.Startup,
      newAccountResult.flowlieAuthenticationToken,
    );

    if (newAccountResult.linkShareOutcome) {
      setToastConfiguration({
        message: newAccountResult.linkShareOutcome.message,
        isError: newAccountResult.linkShareOutcome.error,
      });
    }

    const parsedToken = parseFlowlieAuthToken(
      newAccountResult.flowlieAuthenticationToken,
    );
    heapIdentify(parsedToken.userInfo._id);
    updateHeapStartupUser(startupProfile);
    updateHeapUserSubscriptionTier(SubscriptionTiers.StartupFree);
    Logger.warn('completed new startup function');
  }

  async function onSubmit(
    values: any,
    { setSubmitting }: FormikHelpers<any>,
  ): Promise<void> {
    try {
      setSubmitting(true);
      const cleanValues = cleanStartupData(values);
      await createNewStartup(cleanValues);
      navigate('completed');
    } catch (formError: any) {
      setErrorMessage(formError.message);
      setIsError(true);
      Logger.error(formError);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Routes>
      <Route
        path=""
        element={
          <div className="flex min-h-screen flex-col items-center justify-center sm:bg-gray-100">
            <InfoHeader />
            <main className="min-w-min max-w-4xl rounded-lg bg-white px-3 py-12 sm:p-12 sm:shadow lg:w-2/3 xl:w-1/2">
              <FormikStepper
                initialValues={{ ...emptyStartup }}
                enableReinitialize
                onSubmit={onSubmit}
              >
                {steps.map((step) => (
                  <FormikStep
                    label={step.label}
                    key={step.label}
                    isOptional={step.isOptional}
                    validationSchema={step.fields
                      .map((field) => field.validation)
                      .reduce((merged, schema) => merged.concat(schema))}
                    customLayout={step.customLayout ?? ''}
                  >
                    {step.fields.map((field, index) => (
                      <Fragment key={index}>{field.fieldComponent}</Fragment>
                    ))}
                  </FormikStep>
                ))}
              </FormikStepper>
              {isError && (
                <section className="mt-8">
                  <Alert
                    alertType={'Warning'}
                    color={'yellow'}
                    content={
                      <div>
                        {
                          "Oops! There was an issue submitting your data. Let's retry... please click the 'Complete' button again."
                        }
                        <pre className="-mb-1 mt-2 text-2xs">
                          Error: {errorMessage}
                        </pre>
                      </div>
                    }
                    isShown={isError}
                    canDismiss={false}
                  />
                </section>
              )}
            </main>
          </div>
        }
      />
      <Route
        path="completed"
        element={
          <div className="flex min-h-screen items-center justify-center sm:bg-gray-100">
            <main className="min-w-min max-w-4xl rounded-lg bg-white px-3 py-12 sm:p-12 sm:shadow lg:w-2/3 xl:w-1/2">
              <CompleteProfileSuccess
                shareToken={consumedInvitationData?.shareToken}
                linkType={consumedInvitationData?.linkType}
              />
            </main>
          </div>
        }
      />
      <Route
        path="*"
        element={<Navigate to={PUBLIC_NOT_FOUND_ROUTE} replace />}
      />
    </Routes>
  );
}

export default StartupMultiStepForm;
